import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import { size, map, isEqual } from 'lodash';
import Spinner from 'reactstrap/lib/Spinner';

import { connect } from 'react-redux';
import classNames from 'classnames';

import Moment from 'react-moment';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Alert from 'reactstrap/lib/Alert';
import * as EmailValidator from 'email-validator';

import CustomModal from '../../Components/Components/Modal';

import { Firebase } from '../../../../../lib/firebase';

import MenuRow from '../../Components/Components/MenuRow';

import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';

import defaultImage from '../../../../../images/redesign/common/default_image.svg';
import coinImage from '../../../../../images/redesign/common/coin.svg';
import noFriendsLight from '../../../../../images/redesign/my-info/no-friends-light.svg';
import noFriendsDark from '../../../../../images/redesign/my-info/no-friends-dark.svg';
import ratingImage from '../../../../../images/redesign/common/rating.svg';
import draugiemLogoIcon from '../../../../../images/icons/draugiem-lv.svg';
import facebookLogoIcon from '../../../../../images/icons/social-facebook.svg';
import * as constants from '../../../../../constants/constants';

import {
  getFBFriends,
  getDraugiemFriends,
  sendMoney,
  inviteFriendEmail,
  getFriends,
  removeFriend,
} from '../../../../../actions/member';
import { errorMessages } from '../../../../../constants/messages';
import PlayerAvatar from '../../Components/Components/PlayerAvatar';
import ErrorNotification from '../../Components/Components/ErrorNotification';
import coinImg from '../../../../../images/redesign/common/coin.svg';

class Friends extends React.Component {
  static propTypes = {
    socProvider: PropTypes.string,
    //  role: PropTypes.string,
    friends: PropTypes.shape(),
    achievementsNotification: PropTypes.bool,
    t: PropTypes.func.isRequired,
    SendMoneyToFriend: PropTypes.func.isRequired,
    fetchFBFriends: PropTypes.func.isRequired,
    fetchDraugiemFriends: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    screenMode: PropTypes.string,
    inviteFriendEmailFunc: PropTypes.func.isRequired,
    fetchFriends: PropTypes.func.isRequired,
    removeAFriend: PropTypes.func.isRequired,
  }

  static defaultProps = {
    socProvider: '',
    //  role: '',
    friends: {},
    achievementsNotification: false,
    isLoading: false,
    screenMode: constants.SCREEN_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      amount: 0,
      inviteFriendModalOpen: false,
      inviteEmail: '',
      alertOpen: false,
      alertType: '',
      message: '',
      devMode: false,
      friendId: '',
      inviteStatus: false,
    };
  }

  componentWillMount() {
    const {
      fetchFBFriends, fetchDraugiemFriends, socProvider, fetchFriends,
    } = this.props;
    const { hostname } = window.location;

    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }

    this.setState({ devMode });

    if (socProvider === 'facebook') {
      Firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          window.FB.getLoginStatus((response) => {
            if (response.status === 'connected') {
              const { accessToken } = response.authResponse;

              window.FB.api(
                `/${user.providerData[0].uid}/friends`,
                'GET',
                { access_token: accessToken },
                (resp) => {
                  //  this.setState({ friends: resp.data });

                  console.log(resp);
                  if (resp && resp.data) {
                    fetchFriends(resp);
                  }
                },
              );
            }
          });
        } else {
          //  console.log('no user');
        }
      });
    } else if (socProvider === 'draugiem') {

      fetchFriends(devMode);
    } else {
      fetchFriends();
    }
  }

  // componentDidUpdate(prevProps) {
  //   const { friends, fetchFriends } = this.props;
  //   if (!isEqual(prevProps.friends, friends)) {
  //     console.log("difffriend")
  //     fetchFriends();
  //   }
  // }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (event.target.name === 'amount') {
      if (value.toString().length > 3) return;
      
      this.setState({
        [event.target.name]: value
          .replace(/[^0-9]/g, '')  // Remove non-numeric characters
          .replace(/^0+/, '')  // Remove leading zeros
      });
    } else {
      this.setState({
        [event.target.name]: value,
      });
    }
  }

  inviteFriend = () => {
    const { socProvider } = this.props;

    if (socProvider === 'facebook') {
      window.FB.ui({
        method: 'apprequests',
        message: 'Come Play Zole',
      });
    } else {
      // Draugiem invite
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      alertOpen: false,
      message: '',
      alertType: '',
      amount: 0,
    }));
  }

  openModal = (id) => {
    console.log(id);
    this.setState(prevState => ({
      modal: !prevState.modal,
      friendId: id,
    }));
  }

  sendMoney = () => {
    const { SendMoneyToFriend } = this.props;

    const { friendId, amount } = this.state;

    if (!friendId) {
      return this.setState({
        alertOpen: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: 'noSelectedFriend'
      })
    }
    if (amount === 0) {
      return this.setState({
        alertOpen: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: 'noAmount'
      })
    }
    if (amount > 100) {
      return this.setState({
        alertOpen: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: 'sentPerDayMoney',
        amount: 0,
      });
    }
    if (friendId && amount) {
      SendMoneyToFriend(friendId, amount).then(res => {
        if (res && res.status === constants.FROUNT_SIDE_STATUS.success) {
          this.setState({
            alertOpen: true,
            alertType: constants.FROUNT_SIDE_STATUS.success,
            message: 'sentMoney',
            amount: 0,
          })
        } else {
          if (res.message === errorMessages.sentPerDayMoneyLimit) {
            this.setState({
              alertOpen: true,
              alertType: constants.FROUNT_SIDE_STATUS.danger,
              message: errorMessages.sentPerDayMoneyLimit,
              restMoney: res.restMoney || 0,
              amount: 0,
            });
          } else {
            this.setState({
              alertOpen: true,
              alertType: constants.FROUNT_SIDE_STATUS.danger,
              message: 'failedSentMoney',
              amount: 0,
            });
          }
        }
      });
      // this.setState(prevState => ({
      //   modal: !prevState.modal,
      // }));
    }
  }

  renderRating = (t, rating) => (
    <div className="my-info-ratings-step-wrapper" style={{ justifyContent: 'flex-start' }}>
      {!rating || rating < 10 ? (<div className="my-info-ratings-step my-info-ratings-step-weak my-info-ratings-step-active">{t('myInfo.weak')}</div>) : (null)}
      {(rating >= 10 && rating < 20) ? (<div className="my-info-ratings-step my-info-ratings-step-beginner my-info-ratings-step-active">{t('myInfo.beginner')}</div>) : (null)}
      {(rating >= 20 && rating < 30) ? (<div className="my-info-ratings-step my-info-ratings-step-untrained my-info-ratings-step-active">{t('myInfo.untrained')}</div>) : (null)}
      {(rating >= 30 && rating < 40) ? (<div className="my-info-ratings-step my-info-ratings-step-medium my-info-ratings-step-active">{t('myInfo.medium')}</div>) : (null)}
      {(rating >= 40 && rating < 50) ? (<div className="my-info-ratings-step my-info-ratings-step-good my-info-ratings-step-active">{t('myInfo.good')}</div>) : (null)}
      {(rating >= 50 && rating < 60) ? (<div className="my-info-ratings-step my-info-ratings-step-great my-info-ratings-step-active">{t('myInfo.great')}</div>) : (null)}
      {(rating >= 60 && rating < 70) ? (<div className="my-info-ratings-step my-info-ratings-step-fan my-info-ratings-step-active">{t('myInfo.fan')}</div>) : (null)}
      {rating >= 70 ? (<div className="my-info-ratings-step my-info-ratings-step-pro my-info-ratings-step-active">{t('myInfo.pro')}</div>) : (null)}
    </div>
  );

  toggleInviteFriend = () => {
    const { inviteFriendEmail } = this.state;

    this.setState(previous => ({
      inviteFriendModalOpen: !previous.inviteFriendModalOpen,
      alertOpen: false,
      alertType: '',
      message: '',
      inviteEmail: '',
    }));
  }

  handleInviteFriendEmail = () => {
    const { inviteFriendEmailFunc } = this.props;
    const { inviteEmail, inviteStatus } = this.state;
    console.log(inviteStatus, "checkInviteStatus")
    if (!inviteEmail || !EmailValidator.validate(inviteEmail)) {
      return this.setState({
        alertOpen: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: errorMessages.wrongEmail
      });
    }
    if (inviteStatus) {
      return this.setState({
        alertOpen: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: errorMessages.pendingInviteEmail
      });
    }
    inviteFriendEmailFunc(inviteEmail).then(res => {
      if (res && res.status === constants.FROUNT_SIDE_STATUS.success) {
        this.setState({
          alertOpen: true,
          alertType: constants.FROUNT_SIDE_STATUS.success,
          message: 'verifiedEmailSent',
          inviteStatus: true,
        });
        this.intervalID = setInterval(() => {
          this.setState({
            inviteStatus: false,
          });
        }, 60000);
      } else {
        this.setState({
          alertOpen: true,
          alertType: res.status,
          message: res.message
        })
      }
    });
  }

  handleRemoveFriend = (selectId) => {
    const { removeAFriend } = this.props;
    removeAFriend(selectId);
  }

  handleKeyPress = (event) => {
    const keyCode = event.target.keyCode || event.which;

    // Allow navigation keys and control keys
    if (
      !(
        (keyCode >= 48 && keyCode <= 57) || // Numbers
        (keyCode >= 96 && keyCode <= 105) || // Numpad numbers
        keyCode === 8 || // Backspace
        keyCode === 9 || // Tab
        keyCode === 13 || // Enter
        keyCode === 27 || // Escape
        keyCode === 37 || // Left arrow
        keyCode === 39 || // Right arrow
        keyCode === 46 // Delete
      )
    ) {
      // Prevent default action for non-numeric keys
      event.preventDefault();
    }
  };


  render() {
    const {
      t, isLoading, changeTab, socProvider, achievementsNotification, friends, screenMode
    } = this.props;
    const {
      modal,
      amount,
      inviteEmail,
      inviteFriendModalOpen,
      alertOpen,
      alertType,
      message,
      devMode,
      restMoney,
    } = this.state;
    console.log(friends, "checkFriends")
    return (
      <>
        <Helmet>
          <title>
            Zole - {t('route.friends')}
          </title>
        </Helmet>
        <div className="layout-body">
          <div className="layout-body-top">
            <Row className="layout-subheader">
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.myInfo)}>
                  {t('common:myInfo.myInfo')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text my-info-friends-button-1 layout-subheader-link-text-active friends-menu" onClick={() => changeTab(constants.MENU_NAVIGATION.friends)}>
                  {t('common:myInfo.friends')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.ignoredUsers)}>
                  {t('common:myInfo.ignoredPlayers')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className={classNames("layout-subheader-link-text")} onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}>
                  {t('common:myInfo.achievements')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text " onClick={() => changeTab(constants.MENU_NAVIGATION.gameHistory)}>
                  {t('common:myInfo.results')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text " onClick={() => changeTab(constants.MENU_NAVIGATION.weeklyStatus)}>
                  {t('common:myInfo.weeklyStatus')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.gameLogs)}>
                  {t('common:myInfo.gameLogs')}
                </Button>
              </div>
            </Row>
          </div>
          <div className="layout-body-main layout-body-main-friends">

            <Row className="layout-body-main-table-header m-0 px-3" style={{width: 'calc(100% - 4px)'}}>
              <div style={{width: '18%'}}>
                {t('common.name')}
              </div>
              <div style={{width: '10%'}}>
                {t('common.level')}
              </div>
              <div style={{width: '8%'}}>
                {t('common.ratings')}
              </div>
              <div style={{width: '12%'}}>
                {t('common.balance')}
              </div>
              <div style={{width: '8%'}}>
                {t('common.points')}
              </div>
              <div style={{width: '14%'}} className="text-center">
                {t('common.playingSince')}
              </div>
              <div style={{width: '15%'}} className="text-center">
                {t('friends.sendMoney')}
              </div>
              <div style={{width: '15%'}} className="text-center">
                {t('friends.unFriend')}
              </div>
            </Row>
            <div
              className="layout-body-main-scrollarea"
              contentClassName="layout-body-main-scrollarea-body"
              show
              rightOffset={0}
              topOffset={30}
              bottomOffset={0}
            >
              {
                isLoading ? (
                  <div className="top-table-spinner-loading-section d-flex align-items-center">
                    <Spinner color="light" style={{ width: '5rem', height: '5rem' }} />
                  </div>
                ) : (
                  friends && size(friends) > 0 ? (
                    map(friends, (item, index) => (
                      // <MenuRow key={key}>
                      <Row className="m-0 px-3" key={index} style={{width: '100%'}}>
                        <div style={{width: '18%'}} className="menu-table-row-text">
                          <div className="menu-table-row-player network-logo">
                            <PlayerAvatar screenMode={screenMode} photo={item.photo} frameStyle={{marginRight: 8}} isOnline={item.online} showOnlineBage />
                            {/* <div className="menu-table-row-player-image-frame">
                              <Media className={`menu-table-row-player-image ${item.photo ? '' : 'menu-table-row-player-image-default'}`} src={item.photo || defaultImage} />
                              {item.online ? <div className="online-bage" /> : <div className="offline-bage" />}
                            </div> */}
                            <div className="menu-table-row-player-text" style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '234px' }}>
                              {item.name}
                            </div>
                            <Media
                              src={item?.socProvider === 'draugiem' ? draugiemLogoIcon : item?.socProvider === 'facebook' ? facebookLogoIcon : ''}
                              alt=""
                              className="network-logo-icon"
                            />
                          </div>
                        </div>
                        <div style={{width: '10%'}} className="menu-table-row-text">
                          <div className="menu-table-row-player-icon-wrapper" style={{ marginTop: '10px' }}>
                            {this.renderRating(t, item.lvl || 1600)}
                          </div>
                        </div>
                        <div style={{width: '8%'}} className="menu-table-row-text p-0">
                          <div className="menu-table-row-player-icon-wrapper-center text-left" style={{ marginTop: '10px' }}>
                            <Media src={ratingImage} className="menu-table-row-player-icon" />
                            <div className="menu-table-row-player-icon-text">
                              {item ? (item.rating || 1600) : '1600'}
                            </div>
                          </div>
                        </div>
                        <div style={{width: '12%'}} className="menu-table-row-text p-0">
                          <div className="menu-table-row-player-icon-wrapper-center text-left" style={{ marginTop: '10px' }}>
                            <Media src={coinImage} className="menu-table-row-player-icon" />
                            <div className="menu-table-row-player-icon-text">
                              {item ? (item.bal || 1600) : '1600'}
                            </div>
                          </div>
                        </div>
                        <div style={{width: '8%'}} className="menu-table-row-text">
                          <div className="menu-table-row-player-icon-wrapper-center text-left" style={{ marginTop: '10px' }}>
                            <div className="menu-table-row-player-icon-text">
                              {item.totalPnts}
                            </div>
                          </div>
                        </div>
                        <div style={{width: '14%', marginTop: '10px'}} className="menu-table-row-text text-center">
                          <Moment format="DD-MM-YYYY" locale="lv">
                            {item.dateCreated}
                          </Moment>
                        </div>
                        <div  style={{width: '15%'}} className="menu-table-row-text">
                          {
                            devMode && (
                              <Col sm="2" className="friends-table-row-col max-width-unset p-0 text-center">
                                <Button color="link" className="menu-table-row-default-button" onClick={() => this.openModal(item.uid)} >
                                  {t('friends.send')}
                                </Button>
                              </Col>
                            )
                          }
                        </div>
                        <div style={{width: '15%'}} className='menu-table-row-text'>
                          <Col sm="2" className="friends-table-row-col max-width-unset p-0 text-center">
                            <Button color="link" className="menu-table-row-default-button" onClick={() => this.handleRemoveFriend(item.uid)} >
                              {t('common:top.removeFriend')}
                            </Button>
                          </Col>
                        </div>
                      </Row>
                      // </MenuRow>
                    ))
                  ) : (
                    <div className="top-table-spinner-loading-section d-flex align-items-center flex-column layout-body-main-friends-no-friends">
                      <Media
                        src={(screenMode === (constants.SCREEN_MODE.light)) ? noFriendsLight : (screenMode === constants.SCREEN_MODE.dark || screenMode === constants.SCREEN_MODE.normal)
                          ? noFriendsDark : null}
                        className="no-friends"
                      />
                      {t('home.noFriend')}
                      {(friends && size(friends) === 0 && ['facebook', 'password', 'google', 'inbox'].includes(socProvider)) && (
                        <Button color="link" className="layout-default-button layout-float-right btn btn-link mb-0 mt-1" onClick={socProvider === 'facebook' ? this.toggleInviteFriend : this.inviteFriend}>
                          {t('sendMoney.inviteFriend')}
                        </Button>
                      )}
                    </div>
                  )
                )}
            </div>
            {/* <div className="menu-table-row-filler-out" /> */}

            {/* {socProvider === 'facebook' && (
              <Row>
                <Col xs="12" className='d-flex' style={friends && size(friends) > 0 ? { justifyContent: 'flex-start' } : { justifyContent: 'center' }}>
                  <Button color="link" className="layout-default-button layout-float-right btn btn-link" onClick={this.inviteFriend}>
                    {t('sendMoney.inviteFriend')}
                  </Button>
                </Col>
              </Row>
            )}
            {(socProvider === 'password' || socProvider === 'google' || socProvider === 'inbox') && (
              <Row>
                <Col xs="12" className='d-flex' style={friends && size(friends) > 0 ? { justifyContent: 'flex-start' } : { justifyContent: 'center' }}>
                  <Button color="link" className="layout-default-button layout-float-right btn btn-link" onClick={this.toggleInviteFriend}>
                    {t('sendMoney.inviteFriend')}
                  </Button>
                </Col>
              </Row>
            )} */}
            {(friends && size(friends) > 0 && ['facebook', 'password', 'google', 'inbox'].includes(socProvider)) && (
              <Row>
                <Col xs="12" className="d-flex">
                  <Button color="link" className="layout-default-button layout-float-right btn btn-link" onClick={socProvider === 'facebook' ? this.toggleInviteFriend : this.inviteFriend}>
                    {t('sendMoney.inviteFriend')}
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </div>

        <CustomModal
          isOpen={modal}
          toggle={this.toggle}
          size="md"
          title={t('sendMoney.sendMoneyFriend')}
          footer={(
            <>
              <Button color="link" className="btn notification-footer-button" onClick={this.sendMoney}>{t('common.send')}</Button>
              <Button color="link" className="btn notification-footer-button" onClick={this.toggle}>{t('common.cancel')}</Button>
            </>
          )}
          body={(
            <> 
              <Fragment>
                <ErrorNotification
                  isVisible={alertOpen}
                  // text={t(`sendMoney.${message}`)}
                  text={
                    <>
                      {message === errorMessages.sentPerDayMoney ? (
                        <div className='d-flex flex-row'>
                          <span>
                            {t('sendMoney.sentPerDayMoney')}
                          </span>
                          <Media
                            src={coinImg}
                            width={18}
                            className='ml-1 mr-1'
                          />
                          <span>
                            {t('sendMoney.perFriend')}
                          </span>
                        </div>
                      ) : message === errorMessages.sentPerDayMoneyLimit ? (
                        <div className='d-flex flex-column'>
                          <div className='d-flex flex-row'>
                            <span>
                              {t('sendMoney.sentPerDayMoney')}
                            </span>
                            <Media
                              src={coinImg}
                              width={18}
                              className='ml-1 mr-1'
                            />
                            <span>
                              {t('sendMoney.perDayFriend')}
                            </span>
                          </div>
                          <div>
                            {t(`sendMoney.sentPerDayMoneyLimit`, { restMoney })}
                          </div>
                        </div>
                      ) : (
                        t(`sendMoney.${message}`)
                      )}
                    </>
                  }
                  isSuccess={alertType === constants.FROUNT_SIDE_STATUS.success}
                />
                {/*  <Alert color={alertType} isOpen={alertOpen}>
                  <h5 className="alert-heading alert-heading-message">{alertType === constants.FROUNT_SIDE_STATUS.success ? t('support.success') : t('support.appearError')}</h5>
                  {message === errorMessages.sentPerDayMoney ? (
                    <div className='d-flex flex-row alert-heading alert-heading-body'>
                      <span>
                        {t('sendMoney.sentPerDayMoney')}
                      </span>
                      <Media
                        src={coinImg}
                        width={18}
                        className='ml-1 mr-1'
                      />s
                      <span>
                        {t('sendMoney.perFriend')}
                      </span>
                    </div>
                  ) : message === errorMessages.sentPerDayMoneyLimit ? (
                    <div className='d-flex flex-column alert-heading alert-heading-body'>
                      <div className='d-flex flex-row'>
                        <span>
                          {t('sendMoney.sentPerDayMoney')}
                        </span>
                        <Media
                          src={coinImg}
                          width={18}
                          className='ml-1 mr-1'
                        />
                        <span>
                          {t('sendMoney.perDayFriend')}
                        </span>
                      </div>
                      <div>
                        {t(`sendMoney.sentPerDayMoneyLimit`, {restMoney})}
                      </div>
                    </div>
                  ) : (
                    <p className="alert-heading alert-heading-body">{t(`sendMoney.${message}`)}</p>
                  )}
                </Alert> */}
              </Fragment>
              <Form className="friends-modal-body-form">
                <FormGroup>
                  <Label for="amount">{t('common:sendMoney.enterText')}</Label>
                  <Input type="text" max={100} min={0} name="amount" id="amount" value={amount} defaultValue={0} onChange={this.handleChange} />
                </FormGroup>
              </Form>
            </>
          )}
        />

        <CustomModal
          isOpen={inviteFriendModalOpen}
          toggle={this.toggleInviteFriend}
          size="md"
          title={t('sendMoney.inviteFriend')}
          footer={(
            <>
              <Button color="link" className="btn notification-footer-button" onClick={this.handleInviteFriendEmail}>{t('common.send')}</Button>
              <Button color="link" className="btn notification-footer-button" onClick={this.toggleInviteFriend}>{t('common.cancel')}</Button>
            </>
          )}
          body={(
            <>
              <Fragment>
                <ErrorNotification isVisible={alertOpen} text={t(`member.${message}`)} isSuccess={alertType === constants.FROUNT_SIDE_STATUS.success}/>
              </Fragment>
              <Form className="friends-modal-body-form">
                <FormGroup>
                  <Label for="amount">{t('home.email')}</Label>
                  <Input type="text" name="inviteEmail" id="inviteEmail" value={inviteEmail} placeholder={t('home.email')} onChange={this.handleChange} />
                </FormGroup>
              </Form>
            </>
          )}
        />
      </>
    );
  }
}


const mapStateToProps = state => ({
  friends: state.member.friends || {},
  fbFriends: state.member.fbFriends || {},
  socProvider: state.member.socProvider || null,
  role: state.member.role || null,
  isLoading: state.member.isLoading || false,
});

const mapDispatchToProps = {
  fetchFBFriends: getFBFriends,
  fetchDraugiemFriends: getDraugiemFriends,
  SendMoneyToFriend: sendMoney,
  inviteFriendEmailFunc: inviteFriendEmail,
  fetchFriends: getFriends,
  removeAFriend: removeFriend
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Friends));
