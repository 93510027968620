import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReactGA from 'react-ga';

import {
  isMobileSafari,
} from 'react-device-detect';

import { gsap } from 'gsap';
import isEqual from 'react-fast-compare';

import { withTranslation } from 'react-i18next';

// import ReactGA from "react-ga";
import Row from 'reactstrap/lib/Row';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import classNames from 'classnames';
import PopoverComponent from '../UI/PopoverComponent';

import { AmplitudeService } from '../../services';

import tabulaColapse from '../../../images/Game/tabula_colapse.svg';
import tabulaColapsed from '../../../images/Game/tabula_colapsed.svg';

import Notification from '../Notification';
import ContactSupport from '../ContactSupport';
import Chat from '../../../containers/Game/Chat';
import SendGift from './SendGift';
import Players from './Players';
import ScoreTable from '../../../containers/Game/ScoreTable';
import GameStats from './GameStats';
import PlayerCards from './PlayerCards';
import CardsOnTable from './CardsOnTable';
import PreviousRound from './PreviousRound';
import EndResultModal from './EndResultModal/EndResultModal';

import Clock from '../UI/Clock';

import StaticElements from './StaticElements';

import AutomatedOverlay from './AutomatedOverlay';

import LastRoundModal from './Modals/LastRound';
import QuitRoundModal from './Modals/QuitRound';
import BlockUserModal from './Modals/BlockUser';
import TestingModal from './Modals/TestingModal';

// import SoundButton from '../UI/SoundButton';

import UserSettings from '../Menu/UserSettings';

import openFullscreen from '../../../images/icons/openFullscreenW.png';
import closeFullscreen from '../../../images/icons/closeFullscreenW.png';

import closeImg from '../../../images/icons/close.png';
import gearIcon from '../../../images/icons/gear.svg';
import * as constants from '../../../constants/constants';
// import { i } from 'mathjs';

import defaultFont from '../../../images/redesign/top-row/defaultFont.png';
import largeFont from '../../../images/redesign/top-row/largeFont.png';
import xLargeFont from '../../../images/redesign/top-row/xLargeFont.png';
// import maxFont from '../../../images/redesign/top-row/maxFont.png';
import dealCardSound from '../../../sounds/dealing_card.mp3';
import { setRoomEnded, setTournamentRoomEnded } from '../../../actions/room';
import { filter } from 'lodash';
import { Link, withRouter } from 'react-router-dom';

import { returnToAdminReturnToken } from '../../../actions/users';
import debuggerImg from '../../../images/debugIco.svg';
import { FormGroup, Input, Label } from 'reactstrap';
import { map } from 'lodash';


//let counter = 0;

class Zole extends Component {
  static propTypes = {
    cards: PropTypes.arrayOf(PropTypes.string),
    //  currentTable: PropTypes.arrayOf(PropTypes.shape()),
    globalParams: PropTypes.shape(),
    //  players: PropTypes.shape(),
    //  nextTimeStamp: PropTypes.number,
    //  points: PropTypes.shape(),
    member: PropTypes.shape(),
    //  gifts: PropTypes.shape(),
    //  roomGifts: PropTypes.shape(),
    //  emotions: PropTypes.shape(),

    //  supportChat: PropTypes.shape(),
    supportChatStatus: PropTypes.shape(),
    //  previousRound: PropTypes.shape(),
    errorNotification: PropTypes.string,
    closeReason: PropTypes.shape(),
    notificationSettings: PropTypes.shape(),
    gameSettings: PropTypes.shape(),
    showEndResultModal: PropTypes.bool,
    currentTableLoaded: PropTypes.bool,
    automated: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    isFullscreenEnabled: PropTypes.bool,
    isWebVersion: PropTypes.bool,
    setShowEndResultModal: PropTypes.func.isRequired,
    user: PropTypes.string,
    //  largePlayer: PropTypes.string,
    myPos: PropTypes.string,
    cardsOnTable: PropTypes.arrayOf(PropTypes.string),
    //  currentTurn: PropTypes.string,
    //  sittingOut: PropTypes.string,
    currentType: PropTypes.string,
    chooseGameType: PropTypes.func.isRequired,
    playCard: PropTypes.func.isRequired,
    endRoom: PropTypes.func.isRequired,
    roomId: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    setLastRound: PropTypes.func.isRequired,
    lastRound: PropTypes.bool,
    leaveRoom: PropTypes.func.isRequired,
    quitRound: PropTypes.func.isRequired,
    closeResultNotif: PropTypes.func.isRequired,
    resetCloseErrorSubmit: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    //  buyMoney: PropTypes.func.isRequired,
    sendSupportMessage: PropTypes.func.isRequired,
    setSupportAsRead: PropTypes.func.isRequired,
    closeErrorSubmit: PropTypes.bool,
    quitRoundPressed: PropTypes.bool,
    //  setNextDealCards: PropTypes.func.isRequired,
    //  disableTimer: PropTypes.func.isRequired,
    sendGift: PropTypes.func.isRequired,
    //  setUserBal: PropTypes.func.isRequired,
    //  setUserTournamentBal: PropTypes.func.isRequired,
    blockUser: PropTypes.func.isRequired,
    unBlockUser: PropTypes.func.isRequired,
    resetErrorNotification: PropTypes.func.isRequired,
    closeLevelNotification: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    error: PropTypes.string,
    userSettings: PropTypes.shape().isRequired,
    //  fetchRoomDataFallback: PropTypes.func.isRequired,
    //  getOffset: PropTypes.func.isRequired,
    toggleFullscreen: PropTypes.func.isRequired,
    fetchGifts: PropTypes.func.isRequired,
    toggleScoreTable: PropTypes.func.isRequired,
    fetchCardsOnTable: PropTypes.func.isRequired,
    toggleNewDesign: PropTypes.func.isRequired,
    cardsOnLoading: PropTypes.bool,
    toggleCardsOnLoading: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    toggleFont: PropTypes.func.isRequired,
    setRoomEndedFunction: PropTypes.func.isRequired,
    setTournamentRoomEndedFunction: PropTypes.func.isRequired,
    maxGames: PropTypes.number,
    designPlatform: PropTypes.shape(),
  }

  static defaultProps = {
    user: '',
    //  largePlayer: null,
    myPos: null,
    errorNotification: '',
    closeReason: {},
    notificationSettings: {},
    gameSettings: {},
    //  players: {},
    cards: [],
    //  currentTable: [],
    //  points: {},
    member: {},
    //  nextTimeStamp: 0,
    //  currentTurn: '',
    //  sittingOut: '',
    currentType: '',
    error: null,
    globalParams: {},
    closeErrorSubmit: false,
    quitRoundPressed: false,
    //  gifts: {},
    //  roomGifts: {},

    //  supportChat: null,
    supportChatStatus: null,
    //  previousRound: {},
    showEndResultModal: false,
    currentTableLoaded: false,
    automated: false,
    isFullscreen: false,
    isFullscreenEnabled: false,
    isWebVersion: false,
    cardsOnTable: [],
    cardsOnLoading: true,
    fontMode: constants.FONT_MODE.normal,
    lastRound: false,
    maxGames: null,
  };

  constructor(props) {
    super(props);

    let devMode = false;

    try {
      const { hostname } = window.location;
      if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
        devMode = true;
      }
    } catch (err) { }

    this.state = {
      openModal: false,
      modalType: '',
      //  notificationPlayer: '',
      supportModalOpen: false,
      lastRoundModal: false,
      quitRoundModal: false,
      //  testingModal: false,
      giftsModal: false,
      blockUserModal: false,
      blockUserSelectedUid: '',
      blockUserSelectedName: '',
      //  adminError: '',
      //  newBal: '',
      initialSelected: '',
      cards: [],
      selectedCard: null,
      doubleClickSelectedCard: null,
      tableIsInProgress: false,
      cardPlayClicked: false,
      //  closeResultModalCalled: false,
      leavePenalty: null,
      //  debugId: '',
      //  debugValue: '',
      chooseTypeClicked: false,
      popOverOpen: false,
      popOverTitle: '',
      popOverDescription: '',
      targetName: '',
      endResultWasClosed: false,
      devMode,
      openHolidayDebugger: false,
      selectedTestHoliday: [constants.HOLIDAY_TYPES_INGAME[0]],
      holidayTest: false,
      showCreatedRoomMessage: false,
    };

    this.timerCreatedRoom = null;
    //  this.chooseType = this.chooseType.bind(this);
    //  this.openModal = this.openModal.bind(this);
    //  this.closeModal = this.closeModal.bind(this);
    //  this.closeResultModal = this.closeResultModal.bind(this);
    //  this.endRoom = this.endRoom.bind(this);
    this.leaveRoom = this.leaveRoom.bind(this);
    //  this.exitRoomNotification = this.exitRoomNotification.bind(this);
    //  this.lastRound = this.lastRound.bind(this);
    //  this.playCard = this.playCard.bind(this);
    //  this.removeSelectedCard = this.removeSelectedCard.bind(this);
    //  this.tableInProgress = this.tableInProgress.bind(this);
    this.gameTypeHolderRef = React.createRef();
    this.dealCardAudio = new Audio(dealCardSound);
  }

  componentDidMount() {
    const {
      globalParams,
      member,
      closeReason,
      currentType,
      notificationSettings,
    //  cards,
    } = this.props;

    try {
      if (!localStorage.getItem('initialLevel') && member.lvl) { //x
        localStorage.setItem('initialLevel', member.lvl); //x
      }
    } catch (err) {
      console.log(err);
    }

    const { modalType } = this.state;

    if (closeReason && Object.keys(closeReason).length !== 0) {
      if (closeReason.reason) {
        this.setState({
          openModal: true,
          modalType: closeReason.reason,
        //  notificationPlayer: closeReason.playerName || '',
        });
      }
    } else {
      if (globalParams) {
        const { gameResult } = globalParams;
        const { gameResultModalShown } = this.state;

        console.log('componentDidMount gameResult', gameResult, gameResultModalShown);

        if (gameResult && !gameResultModalShown) {
          /* this.setState({
            openGameResultModal: true,
            gameResultModalShown: true,
            selectedCard: null,
          }); */
          if (notificationSettings && notificationSettings.showAfterRound === false) {
            this.setState({
              gameResultModalShown: true,
              selectedCard: null,
              doubleClickSelectedCard: null,
            });

            this.closeModal();
            this.closeResultModal();
          } else {
            console.log('set openGameResultModal');
            this.setState({
              openGameResultModal: true,
              gameResultModalShown: true,
              selectedCard: null,
              doubleClickSelectedCard: null,
            });
          }
        } else if (modalType === 'gameResult' && !gameResult) {
          this.setState({
            openGameResultModal: false,
            gameResultModalShown: false,
          });
        }
      }

      if (member.level < 10 && globalParams && globalParams.proRoom) {
        this.setState({
          openModal: true,
          modalType: 'proRoom',
        });
      } else if (modalType === 'proRoom' && member.level >= 10) {
        this.setState({
          openModal: false,
          modalType: '',
        });
      }
    }

    if (currentType) {
      this.animateGameTypeFromCenter();
    }

  //  if (cards) {
  //    this.setState({ cards: [...cards] });
  //  }
  }

  componentWillReceiveProps(nextProps) {
    const {
      globalParams, closeReason, quitRoundPressed, notificationSettings,
    } = nextProps;

    const newMember = nextProps.member;
    const newShowEndResultModal = nextProps.showEndResultModal;

    const { showEndResultModal } = this.props;
    const { modalType, openModal, quitRound } = this.state;

    console.log('closeReason', closeReason);

    if (!(closeReason && Object.keys(closeReason).length !== 0)) {
      console.log('notificationSettings', notificationSettings, newShowEndResultModal, showEndResultModal);

      //const endResultWasClosed2 = (!globalParams.closeReason && notificationSettings && notificationSettings.showAfterRound === false)
      //  || (globalParams.closeReason && notificationSettings && notificationSettings.showAtRoomEnd === false) || (!newShowEndResultModal && showEndResultModal);

      const endResultWasClosed = (globalParams.closeReason && notificationSettings && notificationSettings.showAtRoomEnd === false) || (!newShowEndResultModal && showEndResultModal);

      //const endResultWasClosed3 = (!newShowEndResultModal && showEndResultModal);

      if (endResultWasClosed) {
        //this.setState({ endResultWasClosed: true });
      }

      // try {
      //   if (newMember && newMember.lvlUpNotification) {
      //     localStorage.setItem('newLevel', newMember.lvl);
      //   }

      //   const { gameResultModalShown, openGameResultModal } = this.state;

      //   if (localStorage.getItem('newLevel') && globalParams.closeReason && !openGameResultModal && gameResultModalShown && newMember.lvlUpNotification) {
      //     this.setState({
      //       openModal: true,
      //       modalType: 'levelUp',
      //       newLevel: parseInt(localStorage.getItem('newLevel'), 10),
      //       oldLevel: parseInt(localStorage.getItem('initialLevel'), 10),
      //     });
      //   }
      // } catch (err) {
      //   console.log(err);
      // }

      if (globalParams) {
        const { gameResult } = globalParams;
        const { gameResultModalShown } = this.state;

        console.log('gameResult', gameResult);

        if (gameResult && !gameResultModalShown) {
          //if ((!globalParams.closeReason && notificationSettings && notificationSettings.showAfterRound === false)
          //   || (globalParams.closeReason && notificationSettings && notificationSettings.showAtRoomEnd === false)) {
          if (notificationSettings && notificationSettings.showAfterRound === false) {
            this.setState({
              gameResultModalShown: true,
              selectedCard: null,
              doubleClickSelectedCard: null,
              endResultWasClosed,
            });

            this.closeModal();
            this.closeResultModal();
          } else {
            console.log('set openGameResultModal');

            this.setState({
              openGameResultModal: true,
              gameResultModalShown: true,
              selectedCard: null,
              doubleClickSelectedCard: null,
              endResultWasClosed,
            });
          }
        } else if (!gameResult) {
          const closeReason2 = globalParams.closeReason;

          if (!showEndResultModal && closeReason2 && closeReason2 === 'lastRound') {
            const { setShowEndResultModal } = this.props;
            console.log('call showEndResultModal');
            setShowEndResultModal();

            this.setState({
              openGameResultModal: false,
              gameResultModalShown: false,
              endResultWasClosed,
            });
          } else {
            const { openGameResultModal } = this.state;
            if (openGameResultModal || gameResultModalShown) {
              this.setState({
                openGameResultModal: false,
                gameResultModalShown: false,
                endResultWasClosed,
              });
            }
          }
        } else {
          this.setState({
            endResultWasClosed,
          });
        }
      }

      if (!quitRoundPressed && quitRound) {
        this.setState({ quitRound: false });
      }

      // if (
      //   newMember
      //   && !newMember.lvlUpNotification
      //   && openModal
      //   && modalType === 'levelUp'
      // ) {
      //   this.UpdateModal(false, '');
      // }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      errorNotification,
      currentType,
      cards,
      error,
      globalParams,
      roomId,
      fetchCardsOnTable,
      toggleCardsOnLoading,
    } = this.props;
    const {
      openModal, modalType, selectedCard, cards: stateCards, endResultWasClosed, leaveRoomCalled, openGameResultModal, // lastRoundPlayerName,
    } = this.state;

    const prevGlobalParams = prevProps.globalParams;
    if (
      globalParams.gameState === 'choose' && globalParams.party === 1 && globalParams.currentHand === 1 &&
      (prevGlobalParams.gameState !== globalParams.gameState || prevGlobalParams.party !== globalParams.party || prevGlobalParams.currentHand !== globalParams.currentHand)
    ) {
      this.setState({ showCreatedRoomMessage: true });

      if (this.timerCreatedRoom) {
        clearTimeout(this.timerCreatedRoom);
      }

      this.timerCreatedRoom = setTimeout(() => {
        this.setState({ showCreatedRoomMessage: false });
      }, 3000);
    }

    console.log('test to call leaveRoom test', endResultWasClosed, openGameResultModal, leaveRoomCalled);
    if (globalParams.closeReason && globalParams.roomClosed && endResultWasClosed && !openGameResultModal && !openModal && !leaveRoomCalled) {
      console.log('call leaveRoom test', endResultWasClosed, openGameResultModal);
      this.leaveRoom();
    }

    const oldGameState = prevProps.globalParams.gameState;

    /*  if (!lastRoundPlayerName && globalParams.lastRoundPlayer) {
    const { players } = this.props;

    if (players) {
      let playerName;

      if (players.player1 && players.player1.uid === globalParams.lastRoundPlayer) {
        playerName = players.player1.name;
      } else if (players.player2 && players.player2.uid === globalParams.lastRoundPlayer) {
        playerName = players.player2.name;
      } else if (players.player3 && players.player3.uid === globalParams.lastRoundPlayer) {
        playerName = players.player3.name;
      } else if (players.player4 && players.player4.uid === globalParams.lastRoundPlayer) {
        playerName = players.player4.name;
      }

      if (playerName) {
        this.setState({ lastRoundPlayerName: playerName });
      }
    }
  }  */

    if (errorNotification) {
      if (errorNotification === 'low balance') {
        if (!(openModal && modalType === 'lowBalanceGift')) {
          this.UpdateModal(true, 'lowBalanceGift');
        }
      }
    }

    if (oldGameState !== globalParams.gameState && globalParams.gameState === constants.GMAE_STATE.burry) {
      this.animateGameTypeFromCenter();
      fetchCardsOnTable(roomId);
    }

    if (cards) {
      if (!selectedCard && !isEqual(stateCards, cards)) {
      //  console.log('setcards 1', { cards });
        this.setState({ cards: [...cards] });
      } if (selectedCard && !isEqual(stateCards, cards)) {
      //  console.log('setcards 2', { cards });
        setTimeout(() => {
          this.setState({ cards: [...cards] });
        }, 270);
      } else if (!isEqual(stateCards, cards) && (error || (globalParams && globalParams.gameResult))) {
      //  console.log('setcards 3', { cards });
        this.setState({ cards: [...cards], selectedCard: null });
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
    clearTimeout(this.closeReasonTimeoutID);
  }

  tableInProgress = (val) => {
    this.setState({ tableIsInProgress: val });
  }

  animateGameTypeFromCenter = () => {
    /* var startPos = {
      left : window.innerWidth / 2,
      top : window.innerHeight / 2 - 300/2
    }; */

    // var endPos = this.gameTypeHolderRef.current.getBoundingClientRect();

    gsap.to(this.gameTypeHolderRef.current, { /* scale: 0.3, x: startPos.left-endPos.left, y: startPos.top-endPos.top */x: 0, y: -100, duration: 0 });
    gsap.to(this.gameTypeHolderRef.current, {
      x: 0, y: 0, scale: 1, duration: 1.5,
    }, { ease: 'elastic' });
  }

  removeSelectedCard = () => {
    const { cards } = this.props;
    this.setState({ cards, selectedCard: null });
  }

  UpdateModal = (openModal, modalType) => {
    const { resetErrorNotification } = this.props;
    resetErrorNotification();
    this.setState({
      openModal,
      modalType,
    });
  };

  scrollToBottom = () => {
    if (this.pointsScrollbar) {
      this.timeoutID = setTimeout(() => {
        if (this.pointsScrollbar) {
          this.pointsScrollbar.scrollBottom();
        }
      }, 200);
    }
  };

  /*  handleChange = event => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  };

  setNextDealCards = () => {
    const { roomId, setNextDealCards } = this.props;
    const { nextDealCards1, nextDealCards2, nextDealCards3 } = this.state;
    const strippedCards1 = nextDealCards1.replace(' ', '');
    const strippedCards2 = nextDealCards2.replace(' ', '');
    const strippedCards3 = nextDealCards3.replace(' ', '');

    const cardsSplit1 = strippedCards1.split(',');
    const cardsSplit2 = strippedCards2.split(',');
    const cardsSplit3 = strippedCards3.split(',');

    if (
      cardsSplit1 &&
      cardsSplit2 &&
      cardsSplit3 &&
      cardsSplit1.length === 8 &&
      cardsSplit2.length === 8 &&
      cardsSplit3.length === 8
    ) {
      const newCards = cardsSplit1.concat(cardsSplit2, cardsSplit3);

      setNextDealCards(roomId, newCards);
      this.setState({ adminError: '' });
    } else {
      this.setState({ adminError: 'Katram spēlētājam vajag 8 kārtis' });
    }
  };

  setUserBal = () => {
    const {
      setUserBal,
    //  players,
      member,
      roomId,
      setUserTournamentBal,
      globalParams,
      myPos,
    } = this.props;

    const { tournamentRoom, tournamentId } = globalParams;

    const { newBal } = this.state;

    const { uid } = member;
  //  const { playerList } = players;

  //  const playerPos = playerList[uid];

    if (myPos && uid && newBal && roomId) {
      if (tournamentRoom && tournamentId) {
        setUserTournamentBal(roomId, tournamentId, uid, myPos, newBal);
      } else {
        setUserBal(roomId, uid, myPos, newBal);
      }
    }
  }; */

  toggleScoreTable = () => {
    const { playButtonSound, toggleScoreTable, member } = this.props;
    const { uid } = member;

    playButtonSound();
    toggleScoreTable(uid);
  };

  quitRound = () => {
    const { quitRound, playButtonSound, notificationSettings } = this.props;

    if (notificationSettings && notificationSettings.quitRound === false) {
      quitRound();
      playButtonSound();
      this.setState({ quitRound: true });
    } else {
    //  quitRound();
    //  playButtonSound();
      this.setState({ quitRoundModal: true });
    }

  //  quitRound();
  //  playButtonSound();
  //  this.setState({ quitRound: true });
  };

  approveQuitRound = () => {
    const { quitRound, playButtonSound } = this.props;

    quitRound();
    playButtonSound();
    this.setState({ quitRound: true, quitRoundModal: false });
  }

  closeQuitRoundModal = () => {
    this.setState({ quitRoundModal: false });
  }

  chooseType = (selectedType) => {
    const { chooseGameType, playButtonSound, roomId, fetchCardsOnTable } = this.props;
    this.setState({ chooseTypeClicked: true });

    chooseGameType({ selectedType });
    playButtonSound();
    fetchCardsOnTable(roomId);
      // fetchCardsOnTable(roomId);
    setTimeout(() => {
      this.setState({ chooseTypeClicked: false });
    }, 1100);
  };

  openModal = () => {
    this.setState({ openModal: true });
  };

  closeModal = () => {
    const { playButtonSound } = this.props;
    playButtonSound();

    this.setState({
      openModal: false,
      modalType: '',
    });
  };

  toggleSupport = () => {
    const { setSupportAsRead, supportChatStatus, playButtonSound } = this.props;
    const { supportModalOpen } = this.state;

    if (!supportModalOpen && supportChatStatus && !supportChatStatus.read) {
      setSupportAsRead();
    }

    playButtonSound();
    this.setState({ supportModalOpen: !supportModalOpen });
  };

  toggleLastRound = () => {
    const { globalParams, playButtonSound, notificationSettings } = this.props;
    if (globalParams) {
      const { lastRound } = globalParams;
      if (!lastRound) {
        playButtonSound();
        if (notificationSettings && notificationSettings.lastRound === false) {
          this.lastRound();
        } else {
          this.setState(prevState => ({
            lastRoundModal: !prevState.lastRoundModal,
          }));
        }
      }
    }
  };

  closeLastRound = () => {
    const { playButtonSound } = this.props;
    playButtonSound();

    this.setState({
      lastRoundModal: false,
    });
  };

  toggleBlockUser = (id, name) => {
    const { playButtonSound, notificationSettings } = this.props;
    playButtonSound();

    if (notificationSettings && notificationSettings.ignorePlayer === false) {
    //  this.setState(prevState => ({
      //  blockUserModal: !prevState.blockUserModal,
      //  blockUserSelectedUid: id,
      //  blockUserSelectedName: name,
    //  }));

      this.blockUserInstant(id, name);
    } else {
      this.setState(prevState => ({
        blockUserModal: !prevState.blockUserModal,
        blockUserSelectedUid: id,
        blockUserSelectedName: name,
      }));
    }
  };

  blockUserInstant = (id, name) => {
    const { blockUser } = this.props;

    if (id && name) {
      blockUser(id, name);

      this.setState({
        blockUserModal: false,
        blockUserSelectedUid: null,
        blockUserSelectedName: null,
      });
    }
  };

  blockUser = () => {
    const { blockUser, playButtonSound } = this.props;
    const { blockUserSelectedUid, blockUserSelectedName } = this.state;

    if (blockUserSelectedUid && blockUserSelectedName) {
      blockUser(blockUserSelectedUid, blockUserSelectedName);

      playButtonSound();

      this.setState({
        blockUserModal: false,
        blockUserSelectedUid: null,
        blockUserSelectedName: null,
      });
    }
  };

  /*  toggleTestModal = () => {
    this.setState(prevState => ({
      testingModal: !prevState.testingModal,
      adminError: '',
    }));
  }; */

  toggleGiftsModal = (initialSelected, forceState) => {
    const { playButtonSound, fetchGifts } = this.props;
    const { giftsModal } = this.state;
    if (!giftsModal) {
      fetchGifts();
      playButtonSound();
    }

    this.setState(prevState => ({
      giftsModal: forceState !== undefined ? forceState : (!prevState.giftsModal),
      initialSelected,
    }));
  };

  closeResultModal = () => {
    const { closeResultNotif, globalParams, playButtonSound, notificationSettings } = this.props;
    //  const { gameResultModalShown, closeResultModalCalled } = this.state;

    //  if (
    //    gameResultModalShown &&
    //    globalParams &&
    //    globalParams.gameState === 'results' &&
    //    !closeResultModalCalled
    //  ) {
    console.log('closeResultModal', globalParams.closeReason);

    if (!globalParams.closeReason) {
      playButtonSound();
    }

    closeResultNotif();

    if (globalParams.closeReason) {
      console.log('call showEndResultModal');
      const { setShowEndResultModal } = this.props;
      setShowEndResultModal();

      /* if (notificationSettings && notificationSettings.showAtRoomEnd === false) {
        console.log('call leave room if dont show endresultmodal');
      } else {
        console.log('call to show endresultmodal');
      } */
    }

    this.setState({
      openGameResultModal: false,
      quitRound: false,
      selectedCard: null,
      doubleClickSelectedCard: null,
    });
    //  } else if () {

  //  }
  }

  /*  endRoom() {
    const { endRoom, globalParams } = this.props;
    if (!globalParams.roomClosed) {
      endRoom();
    }
  } */

  leaveRoom = () => {
    const { leaveRoom, setRoomEndedFunction, setTournamentRoomEndedFunction, globalParams } = this.props;

    console.log('leaveRoom');
    try {
      localStorage.removeItem('initialLevel'); //x
      localStorage.removeItem('newLevel'); //x
    } catch (err) {
      console.log(err);
    }

    this.setState({ leaveRoomCalled: true });

    setRoomEndedFunction(true);
    if (globalParams.tournamentRoom) {
      setTournamentRoomEndedFunction(true);
    }
    leaveRoom();
  }

  exitRoomNotification = () => {
    const {
      playButtonSound, globalParams, notificationSettings, leaveRoom,
    } = this.props;
    if (((globalParams && !globalParams.roomClosed) || !globalParams)) {
      if (notificationSettings && notificationSettings.leaveRoom === false) {
        leaveRoom();
      } else {
        playButtonSound();

        leaveRoom(true);

        const {
          minGames, bet, rPlayed, fourPRoom,
        } = globalParams;

        let betValue;
        if (bet === '1:1') {
          betValue = 1;
        } else if (bet === '1:5') {
          betValue = 5;
        } else if (bet === '1:10') {
          betValue = 10;
        } else if (bet === '1:25') {
          betValue = 25;
        } else if (bet === '1:50') {
          betValue = 50;
        } else if (bet === '1:100') {
          betValue = 100;
        } else if (bet === '1:250') {
          betValue = 250;
        } else if (bet === '1:500') {
          betValue = 500;
        } else if (bet === '1:1000') {
          betValue = 1000;
        } else if (bet === '1:5000') {
          betValue = 5000;
        } else if (bet === '1:10000') {
          betValue = 10000;
        }

        let leavePenalty = fourPRoom ? betValue * 24 : betValue * 16;

        if (minGames && (rPlayed || rPlayed === 0) && minGames > rPlayed && betValue) {
        //  leavePenalty = leavePenalty + (3 * betValue) + ((minGames - rPlayed) * 10);

          leavePenalty += ((minGames - rPlayed) * 10 * betValue);
        //  ((minGames - rPlayed) * 10 * betValue)
        }

        this.setState({
          openModal: true,
          modalType: 'leaveRoom',
          leavePenalty,
        });
      }
    } else {
      leaveRoom();
    }
  }

  lastRound = () => {
    const { setLastRound, playButtonSound } = this.props;
    const { lastRoundClicked } = this.state;

    if (!lastRoundClicked) {
      this.setState({
        lastRoundModal: false,
        lastRoundClicked: true,
      });

      playButtonSound();
      setLastRound();

      setTimeout(() => {
        this.setState({ lastRoundClicked: false });
      }, 1000);
    }
  }

  playCardWrapper = (e, card) => {
    const { gameSettings } = this.props;
    const { cardPlayClicked } = this.state;

    if (!card.allowed) {
      AmplitudeService.log(AmplitudeService.PLAY_CARD_ERROR, {
        error: 'Card not allowed.',
      });

      this.setState({ cardPlayClicked: false });
      return null;
    }

    if (cardPlayClicked) {
      setTimeout(() => {
        this.setState({ cardPlayClicked: false });
      }, 100);

      return null;
    }

    //  if (card.allowed) {
    if (gameSettings && gameSettings.doubleClickPlay) {
      const { doubleClickSelectedCard } = this.state;

      if (doubleClickSelectedCard && doubleClickSelectedCard.card === card.card) {
        this.playCard(e, card);
      } else {
        this.setState({ doubleClickSelectedCard: card });
      }
    } else {
      this.playCard(e, card);
    }
  /*  } else {
      AmplitudeService.log(AmplitudeService.PLAY_CARD_ERROR, {
        error: 'Card not allowed.',
      });

      this.setState({ cardPlayClicked: false });
    } */
  }

  playCard = (e, card) => {
    const {
      playCard,
      globalParams,
      myPos,
    } = this.props;

    const { tableIsInProgress } = this.state;

    if (myPos) {
      this.setState({ cardPlayClicked: true });

      if (globalParams && globalParams.gameState !== 'burry' && globalParams.gameState !== 'choose') {
        this.setState({ selectedCard: card.card });
      }

      if (globalParams && globalParams.gameState === 'burry') {
        playCard({ selectedCard: card.card, myPos }).then(() => {
          AmplitudeService.log(AmplitudeService.PLAY_CARD_FB_TRIGGERED);
          this.setState({ cardPlayClicked: false });
        }).catch((err) => {
          AmplitudeService.log(AmplitudeService.PLAY_CARD_ERROR, {
            error: err,
          });

          this.setState({ cardPlayClicked: false });
        });
      } else if (!tableIsInProgress) {
        playCard({ selectedCard: card.card, myPos }).then(() => {
          AmplitudeService.log(AmplitudeService.PLAY_CARD_FB_TRIGGERED);
          this.setState({ cardPlayClicked: false });
        }).catch((err) => {
          AmplitudeService.log(AmplitudeService.PLAY_CARD_ERROR, {
            error: err,
          });

          this.setState({ cardPlayClicked: false });
        });
      } else {
        setTimeout(() => {
          playCard({ selectedCard: card.card, myPos }).then(() => {
            AmplitudeService.log(AmplitudeService.PLAY_CARD_FB_TRIGGERED);
            this.setState({ cardPlayClicked: false });
          }).catch((err) => {
            AmplitudeService.log(AmplitudeService.PLAY_CARD_ERROR, {
              error: err,
            });

            this.setState({ cardPlayClicked: false });
          });
        }, 100);
      }
    } else {
      playCard({ selectedCard: card.card, init: false, myPos: '' });
      AmplitudeService.log(AmplitudeService.PLAY_CARD_FB_TRIGGERED);

      setTimeout(() => {
        this.setState({ cardPlayClicked: false });
      }, 800);

      if (globalParams && globalParams.gameState !== 'burry' && globalParams.gameState !== 'choose') {
        this.setState({ selectedCard: card.card });
      }
    }
  }

  playCardOld = (e, card) => {
    const {
      playCard,
      globalParams,
      myPos,
    } = this.props;

    const { tableIsInProgress, cardPlayClicked } = this.state;

    if (!cardPlayClicked && myPos) {
      this.setState({ cardPlayClicked: true });

      //  if (card.allowed) {
      if (globalParams && globalParams.gameState !== 'burry' && globalParams.gameState !== 'choose') {
        this.setState({ selectedCard: card.card });
      }

      if (globalParams && globalParams.gameState === 'burry') {
        playCard({ selectedCard: card.card, myPos }).then(() => {
          AmplitudeService.log(AmplitudeService.PLAY_CARD_FB_TRIGGERED);
          this.setState({ cardPlayClicked: false });
        }).catch((err) => {
          AmplitudeService.log(AmplitudeService.PLAY_CARD_ERROR, {
            error: err,
          });

          this.setState({ cardPlayClicked: false });
        });
      } else if (!tableIsInProgress) {
        playCard({ selectedCard: card.card, myPos }).then(() => {
          AmplitudeService.log(AmplitudeService.PLAY_CARD_FB_TRIGGERED);
          this.setState({ cardPlayClicked: false });
        }).catch((err) => {
          AmplitudeService.log(AmplitudeService.PLAY_CARD_ERROR, {
            error: err,
          });

          this.setState({ cardPlayClicked: false });
        });
      } else {
        setTimeout(() => {
          playCard({ selectedCard: card.card, myPos }).then(() => {
            AmplitudeService.log(AmplitudeService.PLAY_CARD_FB_TRIGGERED);
            this.setState({ cardPlayClicked: false });
          }).catch((err) => {
            AmplitudeService.log(AmplitudeService.PLAY_CARD_ERROR, {
              error: err,
            });

            this.setState({ cardPlayClicked: false });
          });
        }, 100);
      }
      //  }
    /*  else {
        AmplitudeService.log(AmplitudeService.PLAY_CARD_ERROR, {
          error: 'Card not allowed.',
        });

        this.setState({ cardPlayClicked: false });
      } */
    } else if (!cardPlayClicked && card.allowed) {
      playCard({ selectedCard: card.card, init: false, myPos: '' });
      AmplitudeService.log(AmplitudeService.PLAY_CARD_FB_TRIGGERED);

      setTimeout(() => {
        this.setState({ cardPlayClicked: false });
      }, 800);

      if (globalParams && globalParams.gameState !== 'burry' && globalParams.gameState !== 'choose') {
        this.setState({ selectedCard: card.card });
      }
    }
  /*   else {
      AmplitudeService.log(AmplitudeService.PLAY_CARD_ERROR, {
        error: 'Card not allowed.',
      });

      setTimeout(() => {
        this.setState({ cardPlayClicked: false });
      }, 100);
    } */
  }

  showUserSettingsModal = () => {
    const { showUserSettingsModal } = this.state;

    if (!showUserSettingsModal) {
      ReactGA.event({
        category: 'Menu',
        action: 'Open Settings',
      });
    }

    this.setState(prevState => ({ showUserSettingsModal: !prevState.showUserSettingsModal }));
  }

  toggleFontFunc = () => {
    const { toggleFont, fontMode } = this.props;

    if (fontMode === constants.FONT_MODE.normal) {
      toggleFont(constants.FONT_MODE.large);
    } else if (fontMode === constants.FONT_MODE.large) {
      toggleFont(constants.FONT_MODE.xLarge);
    } else {
      toggleFont(constants.FONT_MODE.normal);
    }
  }

  onHover = (title, description, target) => {
    this.setState({
      popOverOpen: true,
    });
    if (title && description && target) {
      this.setState({
        popOverTitle: title,
        popOverDescription: description,
        targetName: target,
      })
    }
  }

  onHoverLeave = () => {
    this.setState({
      popOverOpen: false,
      targetName: '',
      // popOverTitle: '',
      // popOverDescription: '',
    });
  }

  returnToAdmin = () => {
    const { member, returnToAdmin, adminReturnToken, history, toggleNewDesign, toggleScreenMode, i18n } = this.props;

    console.log('call returnToAdmin', adminReturnToken);

    if (adminReturnToken) {
      returnToAdmin(adminReturnToken, toggleNewDesign, toggleScreenMode, i18n, member.uid).then((res) => {
        console.log('returnToAdmin res', res);

        if (res === 'success') {
          history.push('/admin');
        }
      })
    }
  }

  toggleHolidayDebuggerModal = () => {
    const { openHolidayDebugger } = this.setState;
    if (openHolidayDebugger) {
      this.setState({
        selectedTestHoliday: null,
      });
    }

    this.setState(prevState => ({
      openHolidayDebugger: !prevState.openHolidayDebugger,
    }));
  };

  handleSelectTestHoliday = (e) => {
    this.setState({
      selectedTestHoliday: filter(constants.HOLIDAY_TYPES_INGAME, item => item.id === e.target.value),
    });
  }

  setHolidayTestModal = () => {
    const { selectedTestHoliday } = this.state;

    if (selectedTestHoliday[0].type === 'triggerModal' && selectedTestHoliday[0].value === 'changeLog') {
      this.setState({
        openChangeLogVersionModal: true,
      })
    } else {
      this.setState({
        holidayTest: true,
      });
    }
    this.setState({
      openHolidayDebugger: false,
    });
  };

  cancelHolidayTest = () => {
    this.setState({
      holidayTest: false,
      selectedTestHoliday: [constants.HOLIDAY_TYPES_INGAME[0]],
    });
  };

  render() {
    const {
      member,
      user,
      currentType,
      endRoom,
      t,
      globalParams,
      roomId,
      myPos,
      resetCloseErrorSubmit,
      closeErrorSubmit,
      showNotification,
      sendSupportMessage,
      setSupportAsRead,
      sendGift,
      unBlockUser,
      closeLevelNotification,
      //  supportChat,
      supportChatStatus,
      showEndResultModal,
      setShowEndResultModal,
      userSettings,
      soundOn,
      playButtonSound,
      currentTableLoaded,
      automated,
      isFullscreen,
      isFullscreenEnabled,
      isWebVersion,
      notificationSettings,
      toggleFullscreen,
      cardsOnTable,
      toggleNewDesign,
      cardsOnLoading,
      fontMode,
      lastRound,
      maxGames,
      adminReturnToken,
    //  cards,
      designPlatform,
    } = this.props;

    const {
      chooseTypeClicked,
      openModal,
      modalType,
      newLevel,
      oldLevel,
      openGameResultModal,
      quitRound,
      gameResultModalShown,
      supportModalOpen,
      lastRoundModal,
      quitRoundModal,
      //  testingModal,
      giftsModal,
      blockUserModal,
      blockUserSelectedName,
      //  adminError,
      initialSelected,
      selectedCard,
      doubleClickSelectedCard,
      cards,
      tableIsInProgress,
      leavePenalty,
      cardPlayClicked,
      showUserSettingsModal,
      popOverDescription,
      popOverTitle,
      targetName,
      popOverOpen,
      endResultWasClosed,
      devMode,
      openHolidayDebugger,
      selectedTestHoliday,
      holidayTest,
      showCreatedRoomMessage,
    } = this.state;
    // console.log('this.scriptRef', this.scriptRef);
    return (
      <div className={(globalParams && globalParams.fourPRoom) ? 'four-player-table' : 'three-player-table'}>
        {
          member && (member.role === 'admin' || member.role === 'tester') && (
            <Media
              src={debuggerImg}
              width={32}
              height={32}
              alt='Holiday Debugger'
              onClick={() => this.toggleHolidayDebuggerModal()}
              className='debugger-holiday'
              style={{zIndex: 1}}
            />
          )
        }
        {!(isFullscreen || isWebVersion) ? (
          <div className="game-background" />
        ) : (null)}

        <div className="game-logo-wrapper">
          <a className="game-logo" target="_blank" rel="noopener noreferrer" href="https://spelezoli.lv/" alt="ZOLE" />
        </div>
        <div className="game-logo-domain-header">
          {constants.DOMAIN_NAME}
        </div>
        <div className="user-settings-wrapper  user-settings-wrapper-game">
          {!isMobileSafari ? (
            <div className="fullscreen-button fullscreen-button-game">
              {isFullscreenEnabled ? (
                <Media className="fullscreen-button-image" id='game_room_full_screen_button' onMouseEnter={() => this.onHover(constants.FULLSCREENTOOLTIPTITLE, constants.EXITFULLSCREENTOOLTIPDESCRIPTION, constants.FULLSCREENTOOLTIPTARGET)} onMouseLeave={this.onHoverLeave} src={closeFullscreen} onClick={toggleFullscreen} />
              ) : (
                <Media className="fullscreen-button-image" id='game_room_full_screen_button' onMouseEnter={() => this.onHover(constants.FULLSCREENTOOLTIPTITLE, constants.FULLSCREENTOOLTIPDESCRIPTION, constants.FULLSCREENTOOLTIPTARGET)} onMouseLeave={this.onHoverLeave} src={openFullscreen} onClick={toggleFullscreen} />
              )}
            </div>
          ) : (null)}
          <Media src={fontMode === constants.FONT_MODE.xLarge ? xLargeFont : fontMode === constants.FONT_MODE.large ? largeFont : defaultFont} className="user-settings-wrapper-font-mode cursor-pointer" id="game_room_font_resize_button" alt="x" onClick={this.toggleFontFunc} onMouseEnter={() => this.onHover(constants.FONTRESIZETOOLTIPTITLE, constants.FONTRESIZETOOLTIPDESCRIPTION, constants.FONTRESIZETOOLTIPTARGET)} onMouseLeave={this.onHoverLeave} />

          <img className="user-settings-button user-settings-button-game" id="game_room_menu_settings_button" src={gearIcon} alt={t('menu.settings')} onClick={this.showUserSettingsModal} onMouseEnter={() => this.onHover(constants.SETTINGSTOOLTIPTITLE, constants.SETTINGSTOOLTIPDESCRIPTION, constants.SETTINGSTOOLTIPTARGET)} onMouseLeave={this.onHoverLeave} />
        </div>

        {(member && adminReturnToken) && (
          <Button
            className="btn holiday-debugger-button"
            onClick={() => this.returnToAdmin()}
            style={{ zIndex: 999 }}
          >
            Return To Admin
          </Button>
        )}

        {((member && member.role === 'admin') || (designPlatform && designPlatform?.new && ((designPlatform?.devMode && devMode) || !designPlatform?.devMode))) && (
          <div className="logo-toggle-new-design-header">
            <Button
              className="btn holiday-debugger-button"
              onClick={() => { toggleNewDesign(true); }}
              style={{ zIndex: 999 }}
            >
              {t('common:home.newDesign')}
            </Button>
          </div>
        )}

        {showUserSettingsModal ? (
          <Modal container={'div > div'} isOpen={showUserSettingsModal} toggle={this.showUserSettingsModal} size="lg" className={`old-modal root-font-${fontMode}`} style={{maxWidth: 734}}>
            <ModalHeader
              toggle={this.showUserSettingsModal}
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.showUserSettingsModal} />
              }
            >
              {t('common:menu.settings')}
            </ModalHeader>
            <ModalBody>
              <UserSettings fontMode={fontMode} toggleNewDesign={toggleNewDesign} showUserSettingsModal={this.showUserSettingsModal} />
            </ModalBody>
            {/* <ModalFooter>
              <Button className="modal-footer-button" color="secondary" onClick={this.showUserSettingsModal}>
                {t('common:common.save')}
              </Button>
            </ModalFooter> */}
          </Modal>
        ) : (null)}

        {showCreatedRoomMessage &&
          <div className="room-created-text">{t('creatingNewRoom')}</div>
        }

        {(globalParams && !globalParams.roomClosed && !tableIsInProgress && (!cardsOnTable || !cardsOnTable.length) && globalParams.gameState === 'results' && !openGameResultModal && gameResultModalShown) ? (
          <div className="dealingNewCardsText">
            {t('dealingNewCards')}
          </div>
        ) : (null)}

        {(!showEndResultModal && !(user && globalParams && globalParams.fourthPlayerMissed && globalParams.fourthPlayerMissed === user) && automated) && (
          <AutomatedOverlay roomId={roomId} automated={automated} t={t} fontMode={fontMode}/>
        )}

        <Row>
          {/* Players */}
          <Players
            soundOn={soundOn}
            myPos={myPos}
            gameT={t}
            roomId={roomId}
            endRoom={endRoom}
            gameResultModalShown={gameResultModalShown}
            quitRound={quitRound}
            quitRoundFunction={this.quitRound}
            toggleGiftsModal={this.toggleGiftsModal}
            unBlockUser={unBlockUser}
            toggleBlockUser={this.toggleBlockUser}
            closeResultModal={this.closeResultModal}
            toggleLastRound={this.toggleLastRound}
            showEndResultModal={showEndResultModal}
            fontMode={fontMode}
            isFullscreen={isFullscreen}
            selectedTestHoliday={selectedTestHoliday}
            holidayTest={holidayTest}
          />

          {/* Player cards */}
          <PlayerCards
            cards={cards}
            playCard={this.playCardWrapper}
            selectedCard={selectedCard}
            doubleClickSelectedCard={doubleClickSelectedCard}
            tableIsInProgress={tableIsInProgress}
            cardPlayClicked={cardPlayClicked}
            soundOn={soundOn || false}
            isFullscreen={isFullscreen}
            isWebVersion={isWebVersion}
            fourPRoom={!!((globalParams && globalParams.fourPRoom))}
            cardsOnTable={cardsOnTable}
            currentType={currentType}
            cardsOnLoading={cardsOnLoading}
          />

          {/* Choosing game type */}
          {globalParams
          && globalParams.gameState === 'choose'
          && globalParams.talking
          && user && globalParams.talking === user ? (
            <div className="selection">
              <Row>
                <Button
                  color="danger"
                  className="selection-take"
                  disabled={chooseTypeClicked}
                  onClick={() => this.chooseType('zole')}
                >
                  {t('zole')}
                </Button>
              </Row>
              {globalParams.smallGame && (
                <Row>
                  <Button
                    color="danger"
                    className="selection-take"
                    disabled={chooseTypeClicked}
                    onClick={() => this.chooseType('maza')}
                  >
                    {t('smallZole')}
                  </Button>
                </Row>
              )}
              <Row>
                <Button
                  color="success"
                  className="selection-take"
                  disabled={chooseTypeClicked}
                  onClick={() => this.chooseType('parasta')}
                >
                  {t('takeTable')}
                </Button>
              </Row>
              <Row>
                <Button
                  color="warning"
                  className="selection-pass"
                  disabled={chooseTypeClicked}
                  onClick={() => this.chooseType('garam')}
                >
                  {t('pass')}
                </Button>
              </Row>
            </div>
            ) : null}

          <div ref={this.gameTypeHolderRef} className="current-game-type">
            {globalParams && currentType && (globalParams.gameState === 'play'
              || globalParams.gameState === 'burry' || globalParams.gameState === 'results') && (
              <>
                  {currentType === 'parasta' && (
                    <div className="current-game-type-text">{t('regular')}</div>
                  )}
                  {currentType === 'zole' && (
                    <div className="current-game-type-text">{t('zole')}</div>
                  )}
                  {currentType === 'maza' && (
                    <div className="current-game-type-text">{t('smallZole')}</div>
                  )}
                  {currentType === 'galdins' && (
                    <div className="current-game-type-text">{t('table')}</div>
                  )}
              </>
            )}
          </div>

          <PreviousRound
            playButtonSound={playButtonSound}
            roomId={roomId}
            fontMode={fontMode}
          />

          {/* Cards on table */}
          {currentTableLoaded && (
            <CardsOnTable
              removeSelectedCard={this.removeSelectedCard}
              tableInProgress={this.tableInProgress}
              myPos={myPos}
              soundOn={soundOn}
              isFullscreen={isFullscreen}
              isWebVersion={isWebVersion}
            />
          )}

          {userSettings.scoreTableOpen || userSettings.scoreTableOpen === undefined ? (
            <div className={classNames("score-table-toggle-open", {
              "score-table-toggle-open-tournament": globalParams.tournamentRoom,
              [`score-table-toggle-open-${fontMode}`]: maxGames && globalParams.tournamentRoom
            })}>
              <Media
                onClick={this.toggleScoreTable}
                className="score-table-toggle-image"
                src={tabulaColapse}
                alt="Aizvērt"
              />
            </div>
          ) : (
            <div className="score-table-toggle-closed">
              <Media
                onClick={this.toggleScoreTable}
                className="score-table-toggle-image"
                src={tabulaColapsed}
                alt="Atvērt"
              />
            </div>
          )}

          <>
            <GameStats
              t={t}
              scoreTableOpen={userSettings.scoreTableOpen ? true : userSettings.scoreTableOpen === undefined}
              isFullscreen={isFullscreen || isWebVersion}
              fontMode={fontMode}
            />
          </>

          {/* Score table */}
          {roomId && user && (
            <ScoreTable
              roomId={roomId}
              isFullscreen={isFullscreen || isWebVersion}
              scoreTableOpen={userSettings.scoreTableOpen ? true : userSettings.scoreTableOpen === undefined}
            />
          )}

          {((showEndResultModal || (user && globalParams && globalParams.fourthPlayerMissed && globalParams.fourthPlayerMissed === user)) && !endResultWasClosed) ? (
            <EndResultModal
              roomId={roomId}
              leaveRoom={this.leaveRoom}
              gameT={t}
              playButtonSound={playButtonSound}
              closeReason={globalParams.closeReason}
              bet={globalParams.bet}
              minGames={globalParams.minGames || 0}
              rPlayed={globalParams.rPlayed || 0}
              tournamentRoom={globalParams.tournamentRoom || false}
              tournamentRound={globalParams.tournamentRound || null}
              fourthPlayerMissed={!!((user && globalParams && globalParams.fourthPlayerMissed && globalParams.fourthPlayerMissed === user))}
              member={member}
              notificationSettings={notificationSettings}
              fontMode={fontMode}
              fourPRoom={globalParams.fourPRoom}
              setShowEndResultModal={setShowEndResultModal}
            />
          ) : (null)}

          <StaticElements
            exitRoomNotification={this.exitRoomNotification}
            selectedTestHoliday={selectedTestHoliday}
            holidayTest={holidayTest}
          />

          <Notification
            memberUid={user}
            openModal={openModal}
            soundOn={soundOn}
            modalType={modalType}
            leavePenalty={leavePenalty}
            closeModal={this.closeModal}
            closeResultModal={this.closeResultModal}
            gameResult={globalParams.gameResult}
            minGames={globalParams.minGames}
            rPlayed={globalParams.rPlayed}
            leaveRoom={this.leaveRoom}
            newLevel={newLevel}
            oldLevel={oldLevel}
            openGameResultModal={openGameResultModal}
            closeLevelNotification={closeLevelNotification}
            playButtonSound={playButtonSound}
            setShowEndResultModal={setShowEndResultModal}
            fontMode={fontMode}
            lastRound={lastRound}
            endResultWasClosed={endResultWasClosed}
            toggleGiftsModal={this.toggleGiftsModal}
          /> 

          {roomId && user && (
            <Chat
              toggleSupport={this.toggleSupport}
              roomId={roomId}
              uid={user}
              supportChatStatus={supportChatStatus}
              playButtonSound={playButtonSound}
              fontMode={fontMode}
            />
          )}

          <SendGift
            sendGift={sendGift}
            modalOpen={giftsModal}
            toggle={this.toggleGiftsModal}
            roomId={roomId}
            initialSelected={initialSelected}
            playButtonSound={playButtonSound}
            notificationSettings={notificationSettings}
            isFullscreen={isFullscreen || isWebVersion}
            fontMode={fontMode}
          />

          <ContactSupport
            modalOpen={supportModalOpen}
            toggle={this.toggleSupport}
          //  uid={user}
          //  name={member.name}
          //  supportChat={supportChat}
          //  supportChatStatus={supportChatStatus}
            resetClose={resetCloseErrorSubmit}
            closeErrorSubmit={closeErrorSubmit}
            showNotification={showNotification}
            sendSupportMessage={sendSupportMessage}
            setSupportAsRead={setSupportAsRead}
            playButtonSound={playButtonSound}
            fontMode={fontMode}
          />
        </Row>

        <BlockUserModal
          t={t}
          blockUserModal={blockUserModal}
          blockUserSelectedName={blockUserSelectedName}
          toggleBlockUser={this.toggleBlockUser}
          blockUserFunction={this.blockUser}
          fontMode={fontMode}
        />

        <LastRoundModal
          t={t}
          lastRoundModal={lastRoundModal}
          closeLastRound={this.closeLastRound}
          lastRoundFunction={this.lastRound}
          fontMode={fontMode}
        />

        <QuitRoundModal
          t={t}
          quitRoundModal={quitRoundModal}
          closeQuitRoundModal={this.closeQuitRoundModal}
          approveQuitRound={this.approveQuitRound}
          fontMode={fontMode}
        />

        <TestingModal
          roomId={roomId}
          fontMode={fontMode}
        />

        <Clock offset={member.offset} />

        <PopoverComponent gamePopOver popOverOpen={popOverOpen} onHover={() => this.onHover} onHoverLeave={() => this.onHoverLeave} fontMode={fontMode} targetName={targetName} popOverTitle={popOverTitle} popOverDescription={popOverDescription} />
        <Modal container={'div > div'} isOpen={openHolidayDebugger} size="lg" toggle={() => this.toggleHolidayDebuggerModal()} className={`old-modal root-font-${fontMode}`} >
          <ModalHeader
            toggle={() => this.toggleHolidayDebuggerModal('')}
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.toggleHolidayDebuggerModal()} />
            }
          >
            Debugger
          </ModalHeader>
          <ModalBody className="text-center">
            <FormGroup>
              <Label>Debugger Type</Label>
              <Input type="select" name="selectedTestHoliday" className="open-page-select mt-4" value={selectedTestHoliday.id} disabled={/*loading*/false} onChange={this.handleSelectTestHoliday}>
                {
                  map(constants.HOLIDAY_TYPES_INGAME, item => (
                    <option value={item.id} className="background-222">{item.label}</option>
                  ))
                }
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button className="payment-type-continue-button mb-3" color="secondary" onClick={this.setHolidayTestModal}>
              Ok
            </Button>
            <Button className="payment-type-continue-button mb-3" color="secondary" onClick={() => this.toggleHolidayDebuggerModal()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  maxGames: state.game.globalParams.tournamentRoom ? state.game.globalParams.maxGames : null,
  adminReturnToken: state.users.adminReturnToken || null,
});

const mapDispatchToProps = {
  setRoomEndedFunction: setRoomEnded,
  setTournamentRoomEndedFunction: setTournamentRoomEnded,
  returnToAdmin: returnToAdminReturnToken,
};

// export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['game', 'common'])(Zole));
export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(
    withTranslation(['game', 'common'])(Zole)
  )
);
