import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { size } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import FileUploadImage from './components/FileUploadImage';
import FileUploadPrizeImage from './components/FileUploadPrizeImage';

import moment from 'moment';
import Moment from 'react-moment';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

import closeImg from '../../../images/icons/close.png';

import {
  TOURNAMENT_OPTION_IMAGES,
} from '../../../constants/constants'

import {
  answerSupportMessage,
  cancelUserMessages,
  getUserMessages,
  supportMessageFileUpload,
} from '../../../actions/admin';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';
import ScrollArea from 'react-scrollbar';

import Message from './AdminSupportMessage';


moment.locale('lv');
momentLocalizer();

class AllBans extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    allTournaments: PropTypes.shape({}),
    tournamentPlayers: PropTypes.arrayOf(PropTypes.shape({})),
    tournamentPlayerHistory: PropTypes.shape({}),
    tournamentResults: PropTypes.arrayOf(PropTypes.shape({})),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    editTournament: PropTypes.func.isRequired,
    addTournament: PropTypes.func.isRequired,
    deleteTournament: PropTypes.func.isRequired,
    fetchTournamentPlayers: PropTypes.func.isRequired,
    fetchTournamentResults: PropTypes.func.isRequired,
    fetchTournamentPlayerHistory: PropTypes.func.isRequired,
    forceCloseTournament: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    supportAMessageFileUpload : PropTypes.func.isRequired,
    chatMessages: PropTypes.shape({}),
    fetchUserMessages: PropTypes.func.isRequired,

    cancelActiveUserMessages: PropTypes.func.isRequired,
    answerSupportMsg: PropTypes.func.isRequired,
    setSupportMessageAsResponded: PropTypes.func.isRequired,
  }

  static defaultProps = {
    member: {},
    allTournaments: {},
    tournamentPlayers: [],
    tournamentResults: [],
    tournamentPlayerHistory: {},
    chatMessages: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 50,
      tournamentToEdit: '',
      openModal: false,
      deleteModal: false,
      deleteModalSelectedTournament: '',
      openPlayersModal: false,
      name: '',
      bet: '1:10',
      entryFee: null,
      playerPerRoomLimit: null,
      bonus: 0,
      //  startDate: moment(),
      startDate: new Date(),
      bannerStartDate: new Date(),
      bannerEndDate: new Date(),
      winnerPercent: null,
      parasta: true,
      G: false,
      atra: false,
      lightning: false,
      unlimited: false,
      pro: false,
      maza: false,
      maxPlayers: null,
      rounds: null,
      roundLength: null,
      newRoundPause: null,
      playersModalId: '',
      registerTime: null,
      registerDateTime: new Date(),
      openPlayerHistoryModal: false,
      password: null,
      privateTournament: false,
      devTournament: false,

      sponsorPreview: null,
      sponsorImageUrl: '',
      sponsorShowCrop: false,
      sponsorUploadFile: null,

      prizePreview: null,
      prizeImageUrl: '',
      prizeShowCrop: false,
      prizeUploadFile: false,

      bannerPrizePreview: null,//"aaaa"
      bannerPrizeImageUrl: '',
      bannerPrizeShowCrop: false,
      bannerPrizeUploadFile: false,

      loading: false,
      playerHistoryId: null,

      openChatModal: false,
      openChatModalName: '',
      openChatModalUid: '',

      inputMessage: '',
      ableToSend: true,
      bannerPrizeChecked: false,
      bannerShowChecked: false,


    };

    this.handleClick = this.handleClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.editTournament = this.editTournament.bind(this);
    this.addTournament = this.addTournament.bind(this);

    this.sponsorHiddenFileInput = React.createRef(null);
    this.sponsorHandleEditPhoto = this.sponsorHandleEditPhoto.bind(this);
    this.sponsorOnCrop = this.sponsorOnCrop.bind(this);

    this.prizeHiddenFileInput = React.createRef(null);
    this.prizeHandleEditPhoto = this.prizeHandleEditPhoto.bind(this);
    this.prizeOnCrop = this.prizeOnCrop.bind(this);

    this.bannerPrizeHiddenFileInput = React.createRef(null);
    this.bannerPrizeHandleEditPhoto = this.bannerPrizeHandleEditPhoto.bind(this);
    this.bannerPrizeOnCrop = this.bannerPrizeOnCrop.bind(this);

    this.hiddenFileInput = React.createRef(null);

  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  ChangeStartDate = (date) => {
    const { registerDateTime } = this.state;
    this.setState({
      startDate: date,
      registerTime: Math.floor((date - registerDateTime) / 1000 / 60),
    });
  }

  ChangeBannerStartDate = (date) => {
    this.setState({
      bannerStartDate: date,
    });
  }
  ChangeBannerEndDate = (date) => {
    this.setState({
      bannerEndDate: date,
    });
  }

  changeRegisterDateTime = (date) => {
    const { startDate } = this.state;
    this.setState({
      registerDateTime: date,
      registerTime: Math.floor((startDate - date) / 1000 / 60),
      //"aaaa when adding tournament set correct register time value OR when changing registerDateTime and startDate"
    });
  }

  openModal = (id) => {
    const { allTournaments } = this.props;
    const tournament = allTournaments[id];

    let parasta;
    let G;
    if (tournament.gameType === 'G') {
      parasta = false;
      G = true;
    } else {
      parasta = true;
      G = false;
    }

    this.setState({
      tournamentToEdit: id,
      openModal: true,
      openAddModal: false,
      deleteModal: false,
      openPlayersModal: false,
      name: tournament.name,
      bet: tournament.bet,
      entryFee: tournament.entryFee,
      playerPerRoomLimit: tournament.playerPerRoomLimit || null,
      bonus: tournament.bonus || 0,
      //  startTime: new Date(tournament.startTime),
      startDate: new Date(tournament.startDate),
      bannerStartDate: new Date(/*tournament.bannerStartDate*/),//"aaaa check"
      bannerEndDate: new Date(/*tournament.bannerEndDate*/),
      registerTime: tournament.registerTime,
      registerDateTime: new Date(new Date(tournament.startDate).getTime() - tournament.registerTime * 60000),
      winnerPercent: tournament.winnerPercent,
      parasta,
      G,
      atra: !!tournament.fastGame,
      lightning: !!tournament.lightningGame,
      unlimited: !!tournament.unlimitedGame,
      pro: !!tournament.proGame,
      maza: !!tournament.smallGame,
      maxPlayers: tournament.maxPlayers || null,
      rounds: tournament.rounds || null,
      roundLength: tournament.roundLength || null,
      newRoundPause: tournament.newRoundPause || null,
      privateTournament: tournament.privateTournament || null,
      devTournament: tournament.devTournament || null,
      password: tournament.password || null,
      sponsorImageUrl: tournament.sponsorImg || '',
      sponsorPreview: null,
      prizeImageUrl: tournament.prizeImg || '',
      prizePreview: null,

      bannerPrizeImageUrl: tournament.prizeImg || '',
      bannerPrizeChecked: tournament.prizeImg ? true : false,
      bannerPrizePreview: null,
    });
  }

  openAddModal = () => {
    this.setState({
      openModal: false,
      openAddModal: true,
      deleteModal: false,
      openPlayersModal: false,
      name: '',
      bet: '1:10',
      entryFee: '',
      playerPerRoomLimit: null,
      bonus: 0,
      //  startTime: new Date(),
      startDate: new Date(),
      bannerStartDate: new Date(),
      bannerEndDate: new Date(),
      registerTime: 15,
      registerDateTime: new Date(new Date().getTime() - 15 * 60 * 1000),
      winnerPercent: null,
      parasta: true,
      G: false,
      atra: false,
      lightning: false,
      unlimited: false,
      pro: false,
      maza: false,
      maxPlayers: null,
      rounds: 4,
      roundLength: 12,
      newRoundPause: 2,
      privateTournament: false,
      devTournament: false,
      password: null,
      sponsorImageUrl: '',
      sponsorUploadFile: null,
      sponsorPreview: null,
      prizeImageUrl: '',
      prizePreview: null,
      prizeUploadFile: null,

      bannerPrizeImageUrl: '',
      bannerPrizePreview: null,
      bannerPrizeUploadFile: null,
    });
  }

  openPlayersModal = (id, tournamentState) => {
    const { fetchTournamentPlayers } = this.props;

    fetchTournamentPlayers(id, tournamentState);
    this.setState({
      openModal: false,
      openAddModal: false,
      openPlayersModal: true,
      openResultsModal: false,
      playersModalId: id,
    });
  }

  openPlayerHistoryModal = (tournamentId, playerId) => {
    console.log('openPlayerHistoryModal');
    const { fetchTournamentPlayerHistory } = this.props;
    //  const { playersModalId } = this.state;

    fetchTournamentPlayerHistory(tournamentId, playerId);
    this.setState({
      openPlayerHistoryModal: true,
      playerHistoryId: playerId,
    //  openModal: false,
    //  openAddModal: false,
    //  openPlayersModal: true,
    //  openResultsModal: false,
    //  playersModalId: id,
    });
  }


  openResultsModal = (id) => {
    const { fetchTournamentResults } = this.props;

    fetchTournamentResults(id);
    this.setState({
      openModal: false,
      openAddModal: false,
      openPlayersModal: false,
      openResultsModal: true,
    });
  }

  togglePlayers = () => {
    this.setState(prevState => ({
      openPlayersModal: !prevState.openPlayersModal,
      playersModalId: '',
    }));
  }

  togglePlayerHistory = () => {
    this.setState(prevState => ({
      openPlayerHistoryModal: !prevState.openPlayerHistoryModal,
    }));
  }

  toggleResults = () => {
    this.setState(prevState => ({
      openResultsModal: !prevState.openResultsModal,
    }));
  }

  toggleDeleteModal = (id) => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
      forceCloseModal: false,
      deleteModalSelectedTournament: id,
    }));
  }

  deleteTournament = () => {
    const { deleteTournament } = this.props;
    const { deleteModalSelectedTournament } = this.state;

    if (deleteModalSelectedTournament) {
      deleteTournament(deleteModalSelectedTournament);

      this.setState({
        deleteModal: false,
        deleteModalSelectedTournament: '',
      });
    }
  }

  toggleForceCloseTournament = (id) => {
    this.setState(prevState => ({
      forceCloseModal: !prevState.forceCloseModal,
      deleteModal: false,
      forceCloseModalSelectedTournament: id,
    }));
  }

  forceCloseTournament = () => {
    const { forceCloseTournament } = this.props;
    const { forceCloseModalSelectedTournament } = this.state;

    if (forceCloseModalSelectedTournament) {
      forceCloseTournament(forceCloseModalSelectedTournament);

      this.setState({
        forceCloseModal: false,
        forceCloseModalSelectedTournament: '',
      });
    }
  }

  editTournament = () => {
    const { editTournament, allTournaments } = this.props;
    const {
      tournamentToEdit,
      sponsorImageUrl,
      prizeImageUrl,
      name,
      titleLV,
      titleEN,
      titleRU,
      entryFee,
      bonus,
      startDate,
      registerTime,
      winnerPercent,
      parasta,
      G,
      atra,
      lightning,
      unlimited,
      pro,
      maxPlayers,
      maza,
      rounds, roundLength,
      newRoundPause,
      bet,
      password,
      privateTournament,
      devTournament,
      playerPerRoomLimit,
      bannerPrizeChecked,
      bannerPrizeImageUrl,
      bannerShowChecked,
      bannerStartDate,
      bannerEndDate, 
    } = this.state;

    const tournament = allTournaments[tournamentToEdit];

    const prevState = tournament.currentState || null;

    console.log("editTournament", tournamentToEdit, sponsorImageUrl, prizeImageUrl, name, titleLV, titleEN, titleRU, entryFee, bonus, startDate, registerTime, winnerPercent, parasta, G, atra, lightning, unlimited, pro, maxPlayers, maza, rounds, roundLength, newRoundPause, bet, password, privateTournament, devTournament, playerPerRoomLimit, bannerPrizeChecked, bannerPrizeImageUrl, bannerShowChecked, bannerStartDate, bannerEndDate);

    editTournament({
      data: {
        tournamentToEdit, sponsorImageUrl, prizeImageUrl, titleLV, titleEN, titleRU, entryFee, bonus, startDate, registerTime, winnerPercent, parasta, G, atra, lightning, unlimited, pro, maxPlayers, maza, rounds, roundLength, newRoundPause, bet, password, privateTournament, devTournament, playerPerRoomLimit, bannerPrizeChecked, bannerPrizeImageUrl, bannerShowChecked, bannerStartDate, bannerEndDate, 
      },
      oldData: tournament,
    }).then((res) => {
      console.log('editTournament res', res);
      this.setState({ openAddModal: false });
    }).catch((err) => {
      console.log('editTournament err', err);
    });

  //  this.setState({ openModal: false });
  }


  // addTournament() {
  //   const { addTournament } = this.props;
  //   const {
  //     tournamentToEdit, sponsorImageUrl, prizeImageUrl, name, entryFee, bonus, startDate, registerTime, winnerPercent, parasta, G, atra, lightning, unlimited, pro, maxPlayers, maza, rounds, roundLength, newRoundPause, bet, password, privateTournament, devTournament,
  //   } = this.state;

  //   addTournament({
  //     tournamentToEdit, sponsorImageUrl, prizeImageUrl, name, entryFee, bonus, startDate, registerTime, winnerPercent, parasta, G, atra, lightning, unlimited, pro, maxPlayers, maza, rounds, roundLength, newRoundPause, bet, password, privateTournament, devTournament,
  //   }).then((res) => {
  //     console.log('addTournament res', res);
  //     this.setState({ openAddModal: false });
  //   }).catch((err) => {
  //     console.log('addTournament err', err);
  //   });

  // //  this.setState({ openAddModal: false });
  // }

  async addTournament() {
    try {
      const { addTournament } = this.props;
      const {
        tournamentToEdit,
        sponsorImageUrl,
        prizeImageUrl,
        name,
        titleLV,
        titleEN,
        titleRU,
        entryFee,
        bonus,
        startDate,
        registerTime,
        winnerPercent,
        parasta,
        G,
        atra,
        lightning,
        unlimited,
        pro,
        maxPlayers,
        maza,
        rounds, roundLength,
        newRoundPause,
        bet,
        password,
        privateTournament,
        devTournament,
        playerPerRoomLimit,
        bannerPrizeChecked,
        bannerPrizeImageUrl,
        bannerShowChecked,
        bannerStartDate,
        bannerEndDate, 
      } = this.state;

      console.log("addTournament", tournamentToEdit, sponsorImageUrl, prizeImageUrl, name, titleLV, titleEN, titleRU, entryFee, bonus, startDate, registerTime, winnerPercent, parasta, G, atra, lightning, unlimited, pro, maxPlayers, maza, rounds, roundLength, newRoundPause, bet, password, privateTournament, devTournament, playerPerRoomLimit, bannerPrizeChecked, bannerPrizeImageUrl, bannerShowChecked, bannerStartDate, bannerEndDate);
  
      const res = await addTournament({
        tournamentToEdit, sponsorImageUrl, prizeImageUrl, titleLV, titleEN, titleRU, entryFee, bonus, startDate, registerTime, winnerPercent, parasta, G, atra, lightning, unlimited, pro, maxPlayers, maza, rounds, roundLength, newRoundPause, bet, password, privateTournament, devTournament, playerPerRoomLimit, bannerPrizeChecked, bannerPrizeImageUrl, bannerShowChecked, bannerStartDate, bannerEndDate, 
      });
      this.setState({ openAddModal: false });

      console.log('addTournament res', res);
    } catch (err) {
      console.log('addTournament err', err);
    }
  }

  toggleAdd = () => {
    this.setState(prevState => ({
      openAddModal: !prevState.openAddModal,
    }));
  }

  toggle = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }

  toggleResultRound = (index) => {
    this.setState({ openResultsRound: index });
  }

  toggleGameType = (type) => {
    if (type === 'parasta') {
      this.setState(prevState => ({ parasta: !prevState.parasta, G: prevState.parasta }));
    } else if (type === 'G') {
      this.setState(prevState => ({ parasta: prevState.G, G: !prevState.G }));
    } else {
      if (type === 'lightning') {
        this.setState(prevState => ({
          [type]: !prevState[type],
          atra: false,
        }));
      } else if (type === 'atra') {
        this.setState(prevState => ({
          [type]: !prevState[type],
          lightning: false,
        }));
      } else if (type === 'unlimited') {
        this.setState(prevState => ({
          [type]: !prevState[type],
          unlimited: false,
        }));
      } else {
        this.setState(prevState => ({ [type]: !prevState[type] }));
      }
    }
  }

  togglePlayerPerRoomLimit = (type) => {
    const { playerPerRoomLimit } = this.state;

    if (playerPerRoomLimit === type) {
      this.setState({ playerPerRoomLimit: null });
    } else {
      this.setState({ playerPerRoomLimit: type });
    }
  }

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  selectBet = (e) => {
    this.setState({ bet: e.target.value });
  }

  sponsorHandleEditPhoto = (value) => {
    this.setState({
      sponsorShowCrop: !value
    })
  }

  sponsorOnCrop = (sponsorPreview) => {
    this.setState({ sponsorPreview });
  }

  prizeHandleEditPhoto = (value) => {
    console.log(value);
    this.setState({ prizeShowCrop: !value });
  }

  prizeOnCrop = (prizePreview) => {
    this.setState({ prizePreview });
  }

  bannerPrizeHandleEditPhoto = (value) => {//"aaaa unused yet"
    console.log(value);
    this.setState({ bannerPrizeShowCrop: !value });
  }

  bannerPrizeOnCrop = (bannerPrizePreview) => {//"aaaa unused yet"
    this.setState({ bannerPrizePreview });
  }

  sponsorHandleImageClick = () => {
    this.sponsorHiddenFileInput.current.click();
  };

  prizeHandleImageClick = () => {
    this.prizeHiddenFileInput.current.click();
  };

  bannerPrizeHandleImageClick = () => {
    this.bannerPrizeHiddenFileInput.current.click();
  };

  handleImageChange = (e, imageType) => {
    e.preventDefault();

    try {
      const { showNotification, t } = this.props;
      const typeList = 'image/jpg,image/png,image/jpeg, image/pjp, image/pjpeg, image/jfif';
      const reader = new FileReader();
      const file = e.target.files[0];

      if (!typeList.includes(file.type) || file.type === '') {
        showNotification('Error!', "Unsupported file!", 'danger');
        return e.target.value === '';
      }

      if (file.size / 1024 > 3072) {
        showNotification('Warning', "You can't upload more than 3MB", 'warning');
        return e.target.value === '';
      }

      if (imageType === TOURNAMENT_OPTION_IMAGES.sponsor) {
        reader.onloadend = () => {
          this.setState({
            sponsorPreview: reader.result,
            sponsorUploadFile: file,
          });
        };
      } else if (imageType === TOURNAMENT_OPTION_IMAGES.prize) {
        reader.onloadend = () => {
          this.setState({
            prizePreview: reader.result,
            prizeUploadFile: file,
          });
        };
      } else {
        reader.onloadend = () => {
          this.setState({
            bannerPrizePreview: reader.result,
            bannerPrizeUploadFile: file,
          });
        };
      }
      
      reader.readAsDataURL(file);
    } catch (err) {
      const { showNotification, t } = this.props;
      showNotification('Error!', err.message, 'danger');

      return e.target.value === '';
    }
  }

  uploadEventFile = (imageType) => {
    const { supportAMessageFileUpload } = this.props;
    const { sponsorUploadFile, prizeUploadFile, bannerPrizeUploadFile } = this.state;


    console.log('uploadEventFile test 111');

    this.setState({ ableToSave: true });
    console.log(sponsorUploadFile, "sponsorImageUrl===")
    if (imageType === TOURNAMENT_OPTION_IMAGES.sponsor) {

      if (sponsorUploadFile !== null) {
        supportAMessageFileUpload(sponsorUploadFile).then((data) => {
          console.log('value@@@@@@@@@@@@@@@@', data);
          this.setState({
            sponsorImageUrl: data.url,
            ableToSave: false,
          });
        }).catch((e) => {
          this.setState({
            sponsorPreview: null,
            ableToSave: false,
          });
        });
      }
    } else if (imageType === TOURNAMENT_OPTION_IMAGES.prize) {
      if (prizeUploadFile !== null) {
        supportAMessageFileUpload(prizeUploadFile).then((data) => {
          console.log('value@@@@@@@@@@@@@@@@', data);
          this.setState({
            prizeImageUrl: data.url,
            ableToSave: false,
          });
        }).catch((e) => {
          this.setState({
            prizePreview: null,
            ableToSave: false,
          });
        });
      }
    } else {
      console.log('test 222');
      if (bannerPrizeUploadFile !== null) {
        supportAMessageFileUpload(bannerPrizeUploadFile).then((data) => {
          console.log('supportAMessageFileUpload res', data.url);
          this.setState({
            bannerPrizeImageUrl: data.url,
            ableToSave: false,
          });
        }).catch((e) => {
          this.setState({
            bannerPrizePreview: null,
            ableToSave: false,
          });
        });
      }
    }
  }

  handlePreviewPhoto = (previewImg, imageType) => {
    console.log("previewImage");
    if (imageType === TOURNAMENT_OPTION_IMAGES.sponsor) {
      this.setState({
        sponsorPreview: previewImg,
      });
    } else if (imageType === TOURNAMENT_OPTION_IMAGES.prize) {
      this.setState({
        prizePreview: previewImg,
      });
    } else {
      this.setState({
        bannerPrizePreview: previewImg,
      });
    }
  }

  handleReSetImage = (imageType) => {
    if (imageType === TOURNAMENT_OPTION_IMAGES.sponsor) {
      this.setState({
        sponsorPreview: '',
        sponsorImageUrl: '',
      });
    } else if (imageType === TOURNAMENT_OPTION_IMAGES.prize) {
      this.setState({
        prizePreview: '',
        prizeImageUrl: '',
      });
    } else {
      this.setState({
        bannerPrizePreview: '',
        bannerPrizeImageUrl: '',
      });
    }
  }

  handleReSetPreviewImage = (imageType) => {
    if (imageType === TOURNAMENT_OPTION_IMAGES.sponsor) {
      this.setState({
        sponsorPreview: null,
      });
    } else if (imageType === TOURNAMENT_OPTION_IMAGES.prize) {
      this.setState({
        prizePreview: null,
      });
    } else {
      this.setState({
        bannerPrizePreview: null,
      });
    }
  }

  toggleBannerPrizeChecked = () => {
    this.setState({bannerPrizeChecked: !this.state.bannerPrizeChecked});
  }

  toggleBannerShowChecked = () => {
    this.setState({bannerShowChecked: !this.state.bannerShowChecked});
  }

  openChatModalFunc = (uid, name) => {
    const { fetchUserMessages } = this.props;

    fetchUserMessages(uid)//.then(() => {
      //  this.scrollToBottom();
      //});

    this.setState({
      openChatModal: true,
      openChatModalName: name,
      openChatModalUid: uid,
      fileName: '',
      fileUploadUrl: '',
      //  uid,
    });
  }

  chooseFile = (e) => {
    e.preventDefault();

    const { showNotification, t } = this.props;
    const target = e.target.files[0];
    const typeList = '.jpg,.jpeg,.png,.pdf,.msword,.doc,.rtf,.txt,ain';
    if (!target || (target && target.length === 0)) return;
    if (!typeList.includes((target.type).substring((target.type).length - 3)) || target.type == '') { return showNotification('Error!', "Unsupported file!", 'danger'); }
    if (target.size / 1024 > 1024) {
      showNotification('Warning', "Can't upload more than 1.5 MB", 'warning');
      this.setState({
        fileUploadUrl: '',
        fileName: '',
      });
    } else {
      this.setState({
        ableToSend: false,
      });
      const { supportAMessageFileUpload } = this.props;
      supportAMessageFileUpload(target).then((data) => {
        console.log('setState');
        this.setState({
          fileUploadUrl: data.url,
          fileName: target.name,
          ableToSend: true,
        });
        showNotification('Success', "File successfully attached!", 'success');
      });
    }
  }

  _handleFileClick = () => {
    this.hiddenFileInput.current.click();
  };
  
  submitMessage = () => {
    const { answerSupportMsg } = this.props;
    const {
      inputMessage, openChatModalUid, fileUploadUrl, fileName,
    } = this.state;
    if (inputMessage != '' || fileUploadUrl != '') {
      answerSupportMsg({
        uid: openChatModalUid, message: inputMessage, fileUploadUrl, fileName,
      }).then(() => {
        //  this.scrollToBottom();
        this.setState({ inputMessage: '', fileUploadUrl: '', fileName: '' });
      });
    }
  }

  toggleChat = () => {
    const { openChatModal, openChatModalUid } = this.state;

    if (openChatModal) {
      const { cancelActiveUserMessages } = this.props;

      cancelActiveUserMessages(openChatModalUid);
    }

    console.log('setState');
    this.setState(prevState => ({
      openChatModal: !prevState.openChatModal,
      openChatModalUid: '',
    }));
  }

  setSupportMessageAsResponded = () => {
    const { setSupportMessageAsResponded } = this.props;
    const { openChatModalUid } = this.state;

    setSupportMessageAsResponded({ uid: openChatModalUid }).then(() => {
      this.setState({
        openChatModal: false,
        //  uid: '',
      });
    });
  }

  handleChangeChat = (event) => {
    if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

      console.log('setState');
      this.setState({
        [event.target.name]: value,
      });
    }
  }

  handleEnter = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.submitMessage();
    }
  }

  table = () => {
    const {
      t,
      allTournaments,
    } = this.props;
    const {
      currentPage,
      pageSize,
    } = this.state;

    const pagesCount = Math.ceil(Object.keys(allTournaments).length / pageSize);
    return (
      <Fragment>
        {allTournaments && Object.keys(allTournaments)
          .reverse()
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((key, index) => (
            <Fragment key={key}>
              <tr key={key} className={`allTournaments-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allTournaments-table-col text-left">
                  {allTournaments[key].name}
                </td>
                <td className="allTournaments-table-col">
                  {allTournaments[key].registeredPlayers}
                </td>
                <td className="allTournaments-table-col">
                  {allTournaments[key].totalBank + (allTournaments[key].bonus ? parseInt(allTournaments[key].bonus, 10) : 0)}
                </td>
                <td className="allTournaments-table-col" style={{width: 50}}>
                  {allTournaments[key].currentRound}
                </td>
                <td className="allTournaments-table-col">
                  <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                    {allTournaments[key].startDate}
                  </Moment>
                </td>
                <td className="allTournaments-table-col">
                  {allTournaments[key].currentState}
                </td>
                <td className="allTournaments-table-col">
                  <Button className="allTournaments-table-col-button" color="primary" onClick={() => this.openModal(key)}>
                    Edit
                  </Button>
                </td>
                <td className="allTournaments-table-col">
                  <Button className="allTournaments-table-col-button" color="primary" onClick={() => this.openResultsModal(key)}>
                    Results
                  </Button>
                </td>
                <td className="allTournaments-table-col">
                  <Button className="allTournaments-table-col-button" color="primary" onClick={() => this.openPlayersModal(key, allTournaments[key].currentState)}>
                    Players
                  </Button>
                </td>
                <td className="allTournaments-table-col">
                  {key}
                </td>
                <td className="allTournaments-table-col">
                  {allTournaments[key].bet}
                </td>
                <td className="allTournaments-table-col">
                  {allTournaments[key].entryFee}
                </td>
                <td className="allTournaments-table-col">
                  {allTournaments[key].bonus}
                </td>
                <td className="allTournaments-table-col">
                  {allTournaments[key].newRoundPause}
                </td>
                <td className="allTournaments-table-col">
                  {allTournaments[key].rounds}
                </td>
                <td className="allTournaments-table-col" style={{width: 50}}>
                  {allTournaments[key].roundLength}
                </td>
                <td className="allTournaments-table-col">
                  {allTournaments[key].lightningGame && 'lightning'}
                  {allTournaments[key].fastGame && 'fast'}
                  {allTournaments[key].unlimitedGame && 'unlimited'}
                  {!allTournaments[key].unlimitedGame && !allTournaments[key].lightningGame && !allTournaments[key].fastGame && 'slow'}
                </td>
                <td className="allTournaments-table-col">
                  {allTournaments[key].gameType === 'P' && (allTournaments[key].smallGame ? 'PM' : 'P')}
                  {allTournaments[key].gameType === 'G' && (allTournaments[key].smallGame ? 'MG' : 'G')}
                </td>
                <td className="allTournaments-table-col" style={{width: 80}}>
                  {allTournaments[key].registerTime}
                </td>
                <td className="allTournaments-table-col" style={{width: 60}}>
                  {allTournaments[key].maxPlayers}
                </td>
                {allTournaments[key].currentState !== 'closed' ? (
                  <td className="allTournaments-table-col">
                    <Button className="allTournaments-table-col-button" color="danger" onClick={() => this.toggleForceCloseTournament(key)}>
                      Close
                    </Button>
                  </td>
                ) : (
                  <td className="allTournaments-table-col">
                    <Button className="allTournaments-table-col-button" color="danger" onClick={() => this.toggleDeleteModal(key)}>
                      Delete
                    </Button>
                  </td>
                )}
              </tr>
            </Fragment>
          ))}
        {Object.keys(allTournaments).length > pageSize && (
        <div className="pagination-wrapper">
          <Pagination aria-label="Page navigation example">
            <PaginationItem disabled={currentPage === 0}>
              <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                {`‹‹‹`}
              </PaginationLink>
            </PaginationItem>

            <PaginationItem disabled={currentPage <= 0}>
              <PaginationLink
                onClick={e => this.handleClick(e, currentPage - 1)}
                previous
                href="#"
                style={{ color: '#000' }}
              />
            </PaginationItem>

            {[...Array(pagesCount)].map((page, i) => {
              if (i > currentPage - 3 && i < currentPage + 3) {
                return (
                  <PaginationItem active={i === currentPage} key={page}>
                    <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#" >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              }

              return null;
            })}

            <PaginationItem disabled={currentPage >= pagesCount - 1}>
              <PaginationLink
                onClick={e => this.handleClick(e, currentPage + 1)}
                next
                href="#"
                style={{ color: '#000' }}
              />
            </PaginationItem>

            <PaginationItem disabled={currentPage >= pagesCount - 1}>
              <PaginationLink onClick={e => this.handleClick(e, pagesCount - 1)} href="#" style={{ color: '#000' }}>
                {`›››`}
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
        )}
      </Fragment>
    );
  }
  render() {
    const {
      t, tournamentPlayers, tournamentPlayersArr, tournamentResults, tournamentPlayerHistory, chatMessages,
    } = this.props;

    const {
      deleteModal,
      forceCloseModal,
      name,
      titleLV,
      titleEN,
      titleRU,
      bet,
      entryFee,
      playerPerRoomLimit,
      bonus,
      //  startTime,
      //  endTime,
      startDate,
      bannerStartDate,
      bannerEndDate,
      //  endDate,
      registerTime,
      registerDateTime,
      winnerPercent,
      //  minPlayers,
      openModal,
      openAddModal,
      parasta,
      G,
      atra,
      lightning,
      unlimited,
      pro,
      maza,
      privateTournament,
      devTournament,
      sponsorImg,
      prizeImg,
      password,
      maxPlayers,
      newRoundPause,
      rounds,
      roundLength,
      openPlayersModal,
      playersModalId,
      openResultsModal,
      openResultsRound,
      openPlayerHistoryModal,

      sponsorPreview,
      sponsorImageUrl,
      sponsorShowCrop,

      prizePreview,
      prizeImageUrl,
      prizeShowCrop,

      bannerPrizePreview,
      bannerPrizeImageUrl,
      bannerPrizeShowCrop,

      loading,
      ableToSave,
      playerHistoryId,
      fileName,
      ableToSend,
      inputMessage,
      openChatModal,
      openChatModalUid,
      openChatModalName,
      bannerPrizeChecked,
      bannerShowChecked,
    } = this.state;

    console.log('bannerPrizeImageUrl', bannerPrizeImageUrl);

    const numbersOnly = (e) => {
      if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'Delete')) { e.preventDefault(); }
    }

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-all-tournaments">
          <PopoverBody className="popover-body">
            A list of all created tournament and their details. An admin can create a new one or edit/close an ongoing tournament. 
            <br /><br />
            Note: please be careful with editing an active tournament as it can cause problems for the tournament rooms in the backend.
          </PopoverBody>
        </UncontrolledPopover>
        <div style={{ marginTop: 100, color: '#fff' }}>
          <Row>
            <Col md="6">
              <h2>
                Tournaments
                <Button className="admin-help-button" id="admin-help-button-all-tournaments">
                  ?
                </Button>
              </h2>
            </Col>
            <Col md="6">
              <Button onClick={() => this.openAddModal()}>
                Add Tournament
              </Button>
            </Col>
          </Row>
          <table style={{ width: '100%', fontSize: 12, color: '#fff'/*, tableLayout: 'fixed'*/ }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>
                  Title
                </th>
                <th style={{ textAlign: 'center' }}>
                  Participants
                </th>
                <th style={{ textAlign: 'center' }}>
                  Total Bank
                </th>
                <th style={{ textAlign: 'center', width: 50}}>
                  Current Round
                </th>
                <th style={{ textAlign: 'center' }}>
                  Start
                </th>
                <th style={{ textAlign: 'center' }}>
                  Status
                </th>
                <th style={{ textAlign: 'center' }}>
                  Edit
                </th>
                <th style={{ textAlign: 'center' }}>
                  Results
                </th>
                <th style={{ textAlign: 'center' }}>
                  Players
                </th>
                <th style={{ textAlign: 'center' }}>
                  Id
                </th>

                <th style={{ textAlign: 'center' }}>
                  Bet
                </th>
                <th style={{ textAlign: 'center' }}>
                  Fee
                </th>
                <th style={{ textAlign: 'center' }}>
                  Bonus
                </th>
                <th style={{ textAlign: 'center' }}>
                  Pause
                </th>
                <th style={{ textAlign: 'center' }}>
                  Rounds
                </th>
                <th style={{ textAlign: 'center', wdith: 50}}>
                  Round length
                </th>
                <th style={{ textAlign: 'center' }}>
                  Speed
                </th>
                <th style={{ textAlign: 'center' }}>
                  Type
                </th>
                <th style={{ textAlign: 'center', width: 80 }}>
                  Registration time
                </th>
                <th style={{ textAlign: 'center', width: 60}}>
                  Max players
                </th>

                <th style={{ textAlign: 'center' }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>
        </div>

        {/* Delete tournament modal */}
        <Modal container={'div > div'} isOpen={deleteModal} toggle={() => this.toggleDeleteModal('')} className="notification">
          <ModalHeader
            toggle={() => this.toggleDeleteModal('')}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.toggleDeleteModal('')} />
            }
          />
          <ModalBody className="notification-body">
            Do you really want to delete the tournament?
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="btn notification-footer-button" onClick={this.deleteTournament}>
              Yes
            </Button>
            <Button type="button" className="btn notification-footer-button" onClick={() => this.toggleDeleteModal('')}>
              No
            </Button>
          </ModalFooter>
        </Modal>

        {/* Force close modal */}
        <Modal container={'div > div'} isOpen={forceCloseModal} toggle={() => this.toggleForceCloseTournament('')} className="notification">
          <ModalHeader
            toggle={() => this.toggleForceCloseTournament('')}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.toggleForceCloseTournament('')} />
            }
          />
          <ModalBody className="notification-body">
            Do you really want to close an active tournament?
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="btn notification-footer-button" onClick={this.forceCloseTournament}>
              Yes
            </Button>
            <Button type="button" className="btn notification-footer-button" onClick={() => this.toggleForceCloseTournament('')}>
              No
            </Button>
          </ModalFooter>
        </Modal>

        {/* Edit tournament modal */}
        <Modal container={'div > div'} className="admin-modal" isOpen={openModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Edit
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="titleLV">
                  Title (LV)
                </Label>
                <Input
                  type="text"
                  name="titleLV"
                  id="titleLV"
                  value={titleLV}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="titleEN">
                  Title (EN)
                </Label>
                <Input
                  type="text"
                  name="titleEN"
                  id="titleEN"
                  value={titleEN}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="titleRU">
                  Title (RU)
                </Label>
                <Input
                  type="text"
                  name="titleRU"
                  id="titleRU"
                  value={titleRU}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="bet">
                  Bet
                </Label>
                <Input type="select" className="game-bet-select" value={bet} onChange={this.selectBet}>
                  {/* <option style={{ backgroundColor: '#222' }}>1:1</option>
                  <option style={{ backgroundColor: '#222' }}>1:5</option> */}
                  <option style={{ backgroundColor: '#222' }}>1:10</option>
                  <option style={{ backgroundColor: '#222' }}>1:25</option>
                  <option style={{ backgroundColor: '#222' }}>1:50</option>
                  <option style={{ backgroundColor: '#222' }}>1:100</option>
                  <option style={{ backgroundColor: '#222' }}>1:250</option>
                  <option style={{ backgroundColor: '#222' }}>1:500</option>
                  <option style={{ backgroundColor: '#222' }}>1:1000</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="entryFee">
                  Entry Fee
                </Label>
                <Input
                  type="number"
                  name="entryFee"
                  id="entryFee"
                  value={entryFee}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <FormGroup>
                <Label for="bonus">
                  Bonus
                </Label>
                <Input
                  type="number"
                  name="bonus"
                  id="bonus"
                  value={bonus}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <FormGroup>
                <Label for="newRoundPause">
                  Pause between rounds (min: 1 minute, max: 10 minutes)
                </Label>
                <Input
                  type="number"
                  name="newRoundPause"
                  id="newRoundPause"
                  value={newRoundPause}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <FormGroup>
                <Label for="rounds">
                  Rounds per Tournament (min: 4, max: 10)
                </Label>
                <Input
                  type="number"
                  name="rounds"
                  id="rounds"
                  value={rounds}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <FormGroup>
                <Label for="roundLength">
                  Round Length (12, 24 or 36 games)
                </Label>
                <Input
                  type="number"
                  name="roundLength"
                  id="roundLength"
                  value={roundLength}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <Row>
                <Col sm="3">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.togglePlayerPerRoomLimit(3)} checked={playerPerRoomLimit === 3} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Only 3 player rooms
                    </div>
                  </Label>
                </Col>
                <Col sm="3">
                  <Label className="game-type-input" style={{ marginLeft: 10 }}>
                    <Input type="checkbox" onClick={() => this.togglePlayerPerRoomLimit(4)} checked={playerPerRoomLimit === 4} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Only 4 player rooms
                    </div>
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('parasta')} checked={parasta} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      P
                    </div>
                  </Label>
                </Col>
                <Col sm="6">
                  <Label className="game-type-input" style={{ marginLeft: 10 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('G')} checked={G} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      G
                    </div>
                  </Label>
                </Col>
                <Col sm="6">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('atra')} checked={atra} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Fast
                    </div>
                  </Label>
                </Col>
                <Col sm="6">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('lightning')} checked={lightning} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Lightning
                    </div>
                  </Label>
                </Col>
                <Col sm="6">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('unlimited')} checked={unlimited} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Unlimited
                    </div>
                  </Label>
                </Col>
                
                <Col sm="6">
                  <Label className="game-type-input" style={{ marginLeft: 10 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('pro')} checked={pro} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Pro
                    </div>
                  </Label>
                </Col>
                <Col sm="6">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('maza')} checked={maza} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Small
                    </div>
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('privateTournament')} checked={privateTournament} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Private
                    </div>
                  </Label>
                </Col>
                {privateTournament ? (
                  <Col sm="9">
                    <Label for="registerTime">
                      Password
                    </Label>
                    <Input
                      type="string"
                      name="password"
                      id="password"
                      value={password}
                      onChange={this.handleChange}
                    />
                  </Col>
                ) : (null)}
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('devTournament')} checked={devTournament} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      DEV ONLY
                    </div>
                  </Label>
                </Col>
              </Row>
              <FormGroup>
                <Label for="startDate">
                  Start Time
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                    //  time={false}
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.ChangeStartDate}
                      value={startDate}
                      defaultValue={new Date()}
                    />
                  </Col>
                  {/*  <Col md="6">
                    <DateTimePicker
                      date={false}
                      timeFormat="HH:mm"
                      culture="lv"
                      onChange={this.ChangeStartTime}
                      value={startTime}
                      defaultValue={new Date()}
                    />
                  </Col>  */}
                </Row>
              </FormGroup>
              {/* <FormGroup>
                <Label for="registerTime">
                  Registration time (min)
                </Label>
                <Input
                  type="number"
                  name="registerTime"
                  id="registerTime"
                  value={registerTime}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup> */}
              <FormGroup>
                <Label for="registerTime">
                  Registration time
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.changeRegisterDateTime}
                      value={registerDateTime}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Label for="maxPlayers">
                  Max Number of Players per Tournament
                </Label>
                <Input
                  type="number"
                  name="maxPlayers"
                  id="maxPlayers"
                  value={maxPlayers}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <FormGroup>
                <Label for="winnerPercent">
                  Winning Percent
                </Label>
                <Input
                  type="number"
                  name="winnerPercent"
                  id="winnerPercent"
                  value={winnerPercent}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <FormGroup className="tournaments-image-option">
                <FileUploadImage 
                  preview={sponsorPreview}
                  imageUrl={sponsorImageUrl}
                  showCrop={sponsorShowCrop}
                  hiddenFileInput={this.sponsorHiddenFileInput}
                  handleImageClick={this.sponsorHandleImageClick}
                  handleImageChange={this.handleImageChange}
                  handleEditPhoto={this.sponsorHandleEditPhoto}
                  uploadEventFile={this.uploadEventFile}
                  imageType={TOURNAMENT_OPTION_IMAGES.sponsor}
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                />
                <FileUploadImage 
                  preview={prizePreview}
                  imageUrl={prizeImageUrl}
                  showCrop={prizeShowCrop}
                  hiddenFileInput={this.prizeHiddenFileInput}
                  handleImageClick={this.prizeHandleImageClick}
                  handleImageChange={this.handleImageChange}
                  handleEditPhoto={this.prizeHandleEditPhoto}
                  uploadEventFile={this.uploadEventFile}
                  imageType={TOURNAMENT_OPTION_IMAGES.prize}
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                />
              </FormGroup>
              {/**"aaaaa" */}


              <FormGroup className="tournaments-image-option">
                <div style={{ width: '50%', display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
                  <Label>
                    <Input type="checkbox" onClick={() => this.toggleBannerPrizeChecked()} checked={bannerPrizeChecked} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Prize
                    </div>
                  </Label>
                </div>
                <FileUploadImage
                  preview={bannerPrizePreview}
                  imageUrl={bannerPrizeImageUrl}
                  showCrop={bannerPrizeShowCrop}
                  hiddenFileInput={this.bannerPrizeHiddenFileInput}
                  handleImageClick={this.bannerPrizeHandleImageClick}
                  handleImageChange={this.handleImageChange}
                  handleEditPhoto={this.bannerPrizeHandleEditPhoto}
                  uploadEventFile={this.uploadEventFile}
                  imageType={TOURNAMENT_OPTION_IMAGES.banner}//"aaaa changed"
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                  disabled={!bannerPrizeChecked}
                />
              </FormGroup>

              {/* <FormGroup>
                <Label style={{marginRight: 20}}>
                  <Input type="checkbox" onClick={() => this.toggleGameType('G')} checked={G} readOnly />
                  <span className="checkmark" />
                  <div className="game-type-text">
                    Show Banner
                  </div>
                </Label>
              </FormGroup> */}

              <FormGroup>
                <Label style={{ marginLeft: 20, display: 'block' }}>
                  <Input type="checkbox" onClick={() => this.toggleBannerShowChecked()} checked={bannerShowChecked} readOnly />
                  <span className="checkmark" />
                  <div className="game-type-text">
                    Show banner
                  </div>
                </Label>


                <Label for="startDate">
                  Banner Start Time
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.ChangeBannerStartDate}
                      value={bannerStartDate}
                      defaultValue={new Date()}
                      disabled={!bannerShowChecked}
                    />
                  </Col>
                </Row>
                <Label for="startDate">
                  Banner End Time
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.ChangeBannerEndDate}
                      value={bannerEndDate}
                      defaultValue={new Date()}
                      disabled={!bannerShowChecked}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            {
              ableToSave ? (
                <Button color="primary" onClick={this.editTournament} disabled>Save</Button>
              ) : (
                <Button color="primary" onClick={this.editTournament}>Save</Button>
              )
            }
            {' '}
            <Button color="secondary" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>

        {/* Add tournament modal */}
        <Modal container={'div > div'} className="admin-modal" isOpen={openAddModal} toggle={this.toggleAdd}>
          <ModalHeader toggle={this.toggleAdd}>
            Add Tournament
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="titleLV">
                  Title (LV)
                </Label>
                <Input
                  type="text"
                  name="titleLV"
                  id="titleLV"
                  value={titleLV}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="titleEN">
                  Title (EN)
                </Label>
                <Input
                  type="text"
                  name="titleEN"
                  id="titleEN"
                  value={titleEN}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="titleRU">
                  Title (RU)
                </Label>
                <Input
                  type="text"
                  name="titleRU"
                  id="titleRU"
                  value={titleRU}
                  onChange={this.handleChange}
                />
              </FormGroup>
              
              <FormGroup>
                <Label for="bet">
                  Bet
                </Label>
                <Input type="select" className="game-bet-select" value={bet} onChange={this.selectBet}>
                  {/* <option style={{ backgroundColor: '#222' }}>1:1</option>
                  <option style={{ backgroundColor: '#222' }}>1:5</option> */}
                  <option style={{ backgroundColor: '#222' }}>1:10</option>
                  <option style={{ backgroundColor: '#222' }}>1:25</option>
                  <option style={{ backgroundColor: '#222' }}>1:50</option>
                  <option style={{ backgroundColor: '#222' }}>1:100</option>
                  <option style={{ backgroundColor: '#222' }}>1:250</option>
                  <option style={{ backgroundColor: '#222' }}>1:500</option>
                  <option style={{ backgroundColor: '#222' }}>1:1000</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="entryFee">
                  Entry Fee
                </Label>
                <Input
                  type="number"
                  name="entryFee"
                  id="entryFee"
                  value={entryFee}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <FormGroup>
                <Label for="bonus">
                  Bonus
                </Label>
                <Input
                  type="number"
                  name="bonus"
                  id="bonus"
                  value={bonus}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <FormGroup>
                <Label for="newRoundPause">
                  Pause between rounds (min: 1 minute, max: 10 minutes)
                </Label>
                <Input
                  type="number"
                  name="newRoundPause"
                  id="newRoundPause"
                  value={newRoundPause}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <FormGroup>
                <Label for="rounds">
                  Rounds per Tournament (min: 4, max: 10)
                </Label>
                <Input
                  type="number"
                  name="rounds"
                  id="rounds"
                  value={rounds}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <FormGroup>
                <Label for="roundLength">
                  Round Length (12, 24 or 36 games)
                </Label>
                <Input
                  type="number"
                  name="roundLength"
                  id="roundLength"
                  value={roundLength}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <Row>
                <Col sm="3">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.togglePlayerPerRoomLimit(3)} checked={playerPerRoomLimit === 3} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Only 3 player rooms
                    </div>
                  </Label>
                </Col>
                <Col sm="3">
                  <Label className="game-type-input" style={{ marginLeft: 10 }}>
                    <Input type="checkbox" onClick={() => this.togglePlayerPerRoomLimit(4)} checked={playerPerRoomLimit === 4} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Only 4 player rooms
                    </div>
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('parasta')} checked={parasta} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      P
                    </div>
                  </Label>
                </Col>
                <Col sm="6">
                  <Label className="game-type-input" style={{ marginLeft: 10 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('G')} checked={G} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      G
                    </div>
                  </Label>
                </Col>
                <Col sm="6">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('atra')} checked={atra} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Fast
                    </div>
                  </Label>
                </Col>
                <Col sm="6">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('lightning')} checked={lightning} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Lightning
                    </div>
                  </Label>
                </Col>
                <Col sm="6">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('unlimited')} checked={unlimited} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Unlimited
                    </div>
                  </Label>
                </Col>
                <Col sm="6">
                  <Label className="game-type-input" style={{ marginLeft: 10 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('pro')} checked={pro} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Pro
                    </div>
                  </Label>
                </Col>
                <Col sm="6">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('maza')} checked={maza} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Small
                    </div>
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('privateTournament')} checked={privateTournament} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Private
                    </div>
                  </Label>
                </Col>
                {privateTournament ? (
                  <Col sm="9">
                    <Label for="password">
                      Password
                    </Label>
                    <Input
                      type="string"
                      name="password"
                      id="password"
                      value={password}
                      onChange={this.handleChange}
                    />
                  </Col>
                ) : (null)}
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="game-type-input" style={{ marginLeft: 40 }}>
                    <Input type="checkbox" onClick={() => this.toggleGameType('devTournament')} checked={devTournament} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      DEV ONLY
                    </div>
                  </Label>
                </Col>
              </Row>

              <FormGroup>
                <Label for="startDate">
                  Start Time
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                  //  time={false}
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.ChangeStartDate}
                      value={startDate}
                      defaultValue={new Date()}
                    />
                  </Col>
                  {/*  <Col md="6">
                  <DateTimePicker
                    date={false}
                    timeFormat="HH:mm"
                    culture="lv"
                    onChange={this.ChangeStartTime}
                    value={startTime}
                    defaultValue={new Date()}
                  />
                </Col>  */}
                </Row>
              </FormGroup>
              {/* <FormGroup>
                <Label for="registerTime">
                  Registration time (min)
                </Label>
                <Input
                  type="number"
                  name="registerTime"
                  id="registerTime"
                  value={registerTime}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup> */}

              <FormGroup>
                <Label for="registerTime">
                  Registration time
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.changeRegisterDateTime}
                      value={registerDateTime}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Label for="maxPlayers">
                  Max Number of Players per Tournament
                </Label>
                <Input
                  type="number"
                  name="maxPlayers"
                  id="maxPlayers"
                  value={maxPlayers}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <FormGroup>
                <Label for="winnerPercent">
                  Winning Percent
                </Label>
                <Input
                  type="number"
                  name="winnerPercent"
                  id="winnerPercent"
                  value={winnerPercent}
                  onChange={this.handleChange}
                  onKeyDown={numbersOnly}
                  onWheel={(e) => e.target.blur()}
                />
              </FormGroup>
              <FormGroup className="tournaments-image-option">
                <FileUploadImage 
                  preview={sponsorPreview}
                  imageUrl={sponsorImageUrl}
                  showCrop={sponsorShowCrop}
                  hiddenFileInput={this.sponsorHiddenFileInput}
                  handleImageClick={this.sponsorHandleImageClick}
                  handleImageChange={this.handleImageChange}
                  handleEditPhoto={this.sponsorHandleEditPhoto}
                  uploadEventFile={this.uploadEventFile}
                  imageType={TOURNAMENT_OPTION_IMAGES.sponsor}
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                />
                <FileUploadImage 
                  preview={prizePreview}
                  imageUrl={prizeImageUrl}
                  showCrop={prizeShowCrop}
                  hiddenFileInput={this.prizeHiddenFileInput}
                  handleImageClick={this.prizeHandleImageClick}
                  handleImageChange={this.handleImageChange}
                  handleEditPhoto={this.prizeHandleEditPhoto}
                  uploadEventFile={this.uploadEventFile}
                  imageType={TOURNAMENT_OPTION_IMAGES.prize}
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                />
              </FormGroup>
              {/**"aaaaa" */}

              <FormGroup className="tournaments-image-option">
                <div style={{width: '50%', display: 'flex', alignItems: 'start', justifyContent: 'center'}}>
                  <Label>
                    <Input type="checkbox" onClick={() => this.toggleBannerPrizeChecked()} checked={bannerPrizeChecked} readOnly />
                    <span className="checkmark" />
                    <div className="game-type-text">
                      Prize
                    </div>
                  </Label>
                </div>
                <FileUploadImage 
                  preview={bannerPrizePreview}
                  imageUrl={bannerPrizeImageUrl}
                  showCrop={bannerPrizeShowCrop}
                  hiddenFileInput={this.bannerPrizeHiddenFileInput}
                  handleImageClick={this.bannerPrizeHandleImageClick}
                  handleImageChange={this.handleImageChange}
                  handleEditPhoto={this.bannerPrizeHandleEditPhoto}
                  uploadEventFile={this.uploadEventFile}
                  imageType={TOURNAMENT_OPTION_IMAGES.banner}//"aaaa changed"
                  loading={loading}
                  handlePreviewPhoto={this.handlePreviewPhoto}
                  handleReSetImage={this.handleReSetImage}
                  handleReSetPreviewImage={this.handleReSetPreviewImage}
                  disabled={!bannerPrizeChecked}
                />
              </FormGroup>

              {/* <FormGroup>
                <Label style={{marginRight: 20}}>
                  <Input type="checkbox" onClick={() => this.toggleGameType('G')} checked={G} readOnly />
                  <span className="checkmark" />
                  <div className="game-type-text">
                    Show Banner
                  </div>
                </Label>
              </FormGroup> */}

              <FormGroup>
                <Label style={{marginLeft: 20, display: 'block'}}>
                  <Input type="checkbox" onClick={() => this.toggleBannerShowChecked()} checked={bannerShowChecked} readOnly />
                  <span className="checkmark" />
                  <div className="game-type-text">
                    Show banner
                  </div>
                </Label>


                <Label for="startDate">
                  Banner Start Time
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.ChangeBannerStartDate}
                      value={bannerStartDate}
                      defaultValue={new Date()}
                      disabled={!bannerShowChecked}
                    />
                  </Col>
                </Row>
                <Label for="startDate">
                  Banner End Time
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.ChangeBannerEndDate}
                      value={bannerEndDate}
                      defaultValue={new Date()}
                      disabled={!bannerShowChecked}
                    />
                  </Col>
                </Row>
              </FormGroup>


            </Form>
          </ModalBody>
          <ModalFooter>
            {
              ableToSave ? (
                <Button color="primary" onClick={this.addTournament} disabled>Add Tournament</Button>
              ) : (
                <Button color="primary" onClick={this.addTournament}>Add Tournament</Button>
              )
            }
            {' '}
            <Button color="secondary" onClick={this.toggleAdd}>Close</Button>
          </ModalFooter>
        </Modal>


        <Modal container={'div > div'} size="xl" isOpen={openPlayersModal} toggle={this.togglePlayers}>
          <ModalHeader toggle={this.togglePlayers}>
            Players
          </ModalHeader>
          <ModalBody>
            <ScrollAreaWrapper
              className="tournaments-results-scrollarea"
              contentClassName="tournaments-scrollarea-body"
              show
              rightOffset={13}
              topOffset={2}
              bottomOffset={2}
            >
              {(tournamentPlayersArr && tournamentPlayersArr[playersModalId]) ? (
              <table style={{ width: '95%' }}>
                <colgroup>
                  <col span="1" />
                </colgroup>
                <thead className="tournaments-table-header-admin">
                  <tr>
                    <th>
                      #
                    </th>
                    <th>
                      Joined
                    </th>
                    <th>
                      Name
                    </th>
                    <th>
                      Player Id
                    </th>
                    <th>
                      Tournament Points
                    </th>
                    <th>
                      Room Points
                    </th>
                    <th>
                      Status
                    </th>
                    <th>
                      Winnings
                    </th>
                    <th>
                      History
                    </th>
                    <th>
                      Message
                    </th>
                  </tr>
                </thead>
                  {console.log('tournamentPlayersArr', tournamentPlayersArr)}
                <tbody className="tournaments-table-body-admin">
                    {tournamentPlayersArr[playersModalId].map((tournamentPlayer, index) => (
                      <tr key={tournamentPlayer.key} className={`tournaments-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                      <td className="tournaments-table-col">
                        {index + 1}
                      </td>
                      <td className="tournaments-table-col">
                        <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                            {tournamentPlayer.joinTime}
                        </Moment>
                      </td>
                      <td className="tournaments-table-col">
                          {tournamentPlayer.name}
                      </td>
                      <td className="tournaments-table-col">
                          {tournamentPlayer.uid}
                      </td>
                      <td className="tournaments-table-col">
                          {tournamentPlayer.tournamentPoints}
                      </td>
                      <td className="tournaments-table-col">
                        {tournamentPlayer.totalRoomPoints}
                      </td>
                      <td className="tournaments-table-col">
                          {!tournamentPlayer.quit ? (tournamentPlayer.paused ? 'paused' : 'active') : 'left'}
                      </td>
                      <td className="tournaments-table-col">
                          {tournamentPlayer.winnings}
                      </td>
                      <td className="tournaments-table-col">
                          <Button className="allTournaments-table-col-button" color="primary" onClick={() => this.openPlayerHistoryModal(playersModalId, tournamentPlayer.key)}>
                          History
                        </Button>
                      </td>
                      <td className="tournaments-table-col">                        
                        <Button color="primary" onClick={() => this.openChatModalFunc(tournamentPlayer.uid, tournamentPlayer.name)}>
                          Message
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              ) : (null)}
            </ScrollAreaWrapper>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.togglePlayers}>Close</Button>
          </ModalFooter>
        </Modal>


        <Modal container={'div > div'} size="lg" isOpen={openPlayerHistoryModal} toggle={this.togglePlayerHistory}>
          <ModalHeader toggle={this.togglePlayerHistory}>
            {(tournamentPlayers && tournamentPlayers[playersModalId] && playersModalId && playerHistoryId && tournamentPlayers[playersModalId][playerHistoryId]) ? tournamentPlayers[playersModalId][playerHistoryId].name : ''}
          </ModalHeader>
          <ModalBody>
            {tournamentPlayerHistory && (
              <table style={{ width: '95%' }}>
                <colgroup>
                  <col span="1" />
                </colgroup>
                <thead className="tournaments-table-header tournaments-table-header-admin">
                  <tr>
                    <th>
                      Type
                    </th>
                    <th>
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="tournaments-table-body tournaments-table-body-admin">
                  {Object.keys(tournamentPlayerHistory).map((key, index) => (
                    <tr key={key} className={`tournaments-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                      <td className="tournaments-table-col">
                        {tournamentPlayerHistory[key].type}
                      </td>
                      <td className="tournaments-table-col">
                        <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                          {tournamentPlayerHistory[key].time}
                        </Moment>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.togglePlayerHistory}>Close</Button>
          </ModalFooter>
        </Modal>


        <Modal container={'div > div'} size="xl" className="tournaments-results-modal" isOpen={openResultsModal} toggle={this.toggleResults}>
          <ModalHeader toggle={this.toggleResults}>
            Results
          </ModalHeader>
          <ModalBody>
            {tournamentResults && size(tournamentResults) > 0 && (
              <div className="tournaments-results">
                <Row className="tournaments-results-header-admin text-center">
                  <Col sm="3" className="tournaments-results-room-id">
                    Room Id
                  </Col>
                  <Col className="tournaments-results-room-id">
                    Start time
                  </Col>
                  <Col className="tournaments-results-room-id">
                    End Time
                  </Col>
                  <Col className="tournaments-results-room-id">
                    {`Player 1`}
                  </Col>
                  <Col className="tournaments-results-room-id">
                    {`Player 2`}
                  </Col>
                  <Col className="tournaments-results-room-id">
                    {`Player 3`}
                  </Col>
                  <Col className="tournaments-results-room-id">
                    {`Player 4`}
                  </Col>
                </Row>
                <Row className="tournaments-results-body-admin">
                  <Col sm="12">
                    {tournamentResults.map((round, index) => {
                      if (index === 0) {
                        return null;
                      }

                      return (
                        <Fragment>
                          <Row className={`tournaments-results-round ${index % 2 === 0 ? ('odd') : ('even')}`}>
                            <Col sm="12" className="tournaments-results-round-text-admin" onClick={() => this.toggleResultRound(index)}>
                              {`Round ${index}`}
                            </Col>
                            <Col sm="12" className="tournaments-results-col">
                              {Object.keys(round).map(key => {
                                let playersInRound = 0;

                                return (
                                  <Fragment>
                                    {round[key] && round[key].result && (
                                      <Row key={key} className={`tournaments-results-room-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                                        <Col sm="3" className="tournaments-results-room-id">
                                          {key}
                                        </Col>
                                        <Col className="tournaments-results-room-id">
                                          {round[key].result.startTime ? (
                                            <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                                              {round[key].result.startTime}
                                            </Moment>
                                          ) : (null)}
                                        </Col>
                                        <Col className="tournaments-results-room-id">
                                          {round[key].result.closeTime ? (
                                            <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                                              {round[key].result.closeTime}
                                            </Moment>
                                          ) : (null)}
                                        </Col>
                                        {Object.keys(round[key].result).map(key2 => {
                                          if (key2 === 'startTime' || key2 === 'closeTime') {
                                            return null;
                                          }
                                          playersInRound += 1;

                                          console.log('playersInRound', playersInRound);
                                          return (
                                            <Col className="tournaments-results-room-player">
                                              <div className={`tournaments-results-room-player-name ${round[key].result[key2].disqualified ? 'tournaments-results-room-player-disqualified' : ''}`}>
                                                {round[key].result[key2].name}
                                              </div>
                                              <div className="tournaments-results-room-player-tourPoints">
                                                {`Tourn. points: ${round[key].result[key2].tournamentPoints}`}
                                              </div>
                                              <div className="tournaments-results-room-player-roomPoints">
                                                {`Room points: ${round[key].result[key2].roomPoints}`}
                                              </div>
                                              {round[key].result[key2].disqualified ? (
                                                'disqualified'
                                              ) : (
                                                null
                                              )}
                                            </Col>
                                          );
                                        })}
                                        {playersInRound <= 3 ? (
                                          <Col className="tournaments-results-room-id" />
                                        ):(null)}
                                      </Row>
                                    )}
                                  </Fragment>
                                );
                              })}
                            </Col>
                          </Row>
                        </Fragment>
                      );
                    })}
                  </Col>
                </Row>
              </div>
            )}

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleResults}>Close</Button>
          </ModalFooter>
        </Modal>

        <Modal container={'div > div'} isOpen={openChatModal} toggle={this.toggleChat}>
          <ModalHeader toggle={this.toggleChat}>
            {`Chat - ${openChatModalName} - ${openChatModalUid}`}
          </ModalHeader>
          <ModalBody>
            <Row className="chat-body">
              <Col className="chat-body-wrapper" md="12">
                <ScrollArea
                  speed={0.65}
                  className="admin-chat-scroll-area"
                  contentClassName="admin-chat-scroll-content"
                  //  smoothScrolling
                  verticalContainerStyle={{
                    background: 'transparent',
                    opacity: 1,
                    width: 10,
                  }}
                  verticalScrollbarStyle={{
                    background: '#fff',
                    borderRadius: 2,
                    width: 6,
                    minHeight: 30,
                    minScrollSize: 35,
                  }}
                  horizontal={false}
                  ref={(el) => { this.messagesScrollbar = el; }}
                >
                  {chatMessages && Object.keys(chatMessages).map(key => (
                    <Row key={key}>
                      <Col md="12">
                        <Message message={chatMessages[key]} />
                      </Col>
                    </Row>
                  ))}
                </ScrollArea>
              </Col>
            </Row>
            <Row className="chat-footer" style={{ height: '20%' }}>
              <Form style={{ width: '100%' }}>
                <Col md="12">
                  <Input
                    className="chat-footer-input chat-footer-input-admin"
                    type="textarea"
                    name="inputMessage"
                    id="inputMessage"
                    autoComplete="off"
                    placeholder='Write Message...'
                    style={{

                    }}
                    value={inputMessage}
                    onChange={this.handleChangeChat}
                    onKeyPress={this.handleEnter}
                  />

                </Col>
              </Form>
            </Row>
            <Row>
              <Button
                className="btn-warning admin-file-upload-button"
                onClick={this._handleFileClick}
              >
                {fileName === '' ? '+ Choose File' : fileName}
              </Button>
              <input
                id="files"
                hidden
                ref={this.hiddenFileInput}
                className="chat-footer-input chat-footer-input-admin admin-file-upload-input"
                accept=".jpg,.jpeg,.png,.pdf,.msword,.doc,.rtf,.txt,ain"
                type="file"
                onChange={this.chooseFile}
              />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={this.setSupportMessageAsResponded}>Mark As Read</Button>
            <Button className="contact-support-footer-button" disabled={!ableToSend} color="primary" onClick={this.submitMessage}>
              {/* {t('send')} */}
              Send
            </Button>
            <Button type="button" color="secondary" onClick={this.toggleChat}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  tournamentResults: state.admin.tournamentResults || [],
  tournamentPlayersArr: state.admin.tournamentPlayersArr || [],
  chatMessages: state.admin.chatMessages || {},
});

const mapDispatchToProps = {
  supportAMessageFileUpload: supportMessageFileUpload,
  cancelActiveUserMessages: cancelUserMessages,
  fetchUserMessages: getUserMessages,
  answerSupportMsg: answerSupportMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('admin')(AllBans));
