// import { errorMessages } from '../constants/messages';
import {
  map, size, forEach, filter, find, isEqual,
} from 'lodash'; // , orderBy, keyBy, sortBy, get

import axios from 'axios';
import i18n from 'i18next';

import moment from 'moment';
import {
  Firebase,
  FirebaseRef,
  AdminLogsRef,
  RoomsRef,
  UserStatsRef,
  TournamentsRef,
  LeaderboardRef,
  FirebaseStorage,
} from '../lib/firebase';
import {
  isAndroid,
  isIOS,
  isIOS13,
  isTablet,
  isMobile,
  isDesktop,
  browserName,
  browserVersion,
  osName,
  osVersion,
  mobileModel
} from 'react-device-detect'; 
import { errorMessages } from '../constants/messages';
import * as statisticsConst from '../constants/statisticsTypes';
import * as constants from '../constants/constants';
import config from '../constants/config';

function dateFormat(formatData) {
  return moment(formatData).format('DD-MM-YYYY HH:MM:SS');
}

/*
function dateFormatMoment(formatData, dateType) {
  return moment(formatData).format(dateType);
} */

export function getmissedTurnsCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => AdminLogsRef().child('missedTurnsCount').once('value', (snapshot) => {
    const array = [];

    snapshot.forEach((childSnapshot) => {
      const val = childSnapshot.val();
      const { key } = childSnapshot;
      array.push({ key, ...val });
    });

    array.sort((a, b) => b.count - a.count);

    return resolve(dispatch({ type: 'MISSED_TURNS_COUNT', data: array }));
  })).catch((err) => { console.log(err.message); });
}

export function getProfanitiesList() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('profanitiesList').once('value', (snapshot) => {
    const profanitiesList = snapshot.val() || {};

    return resolve(dispatch({ type: 'PROFANITIES_LIST', data: profanitiesList }));
  })).catch((err) => { console.log(err.message); });
}

export function addProfanity(word) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise((resolve) => {
    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'addProfanity',
      adminId: UID,
      adminEmail,
      adminName,
      data: word,
      date: Firebase.database.ServerValue.TIMESTAMP,
    });

    return FirebaseRef.child(`profanitiesList/${word}`).set({ word, count: 0 }).then(() => resolve());
  });
}


export function deleteProfanity(word) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise((resolve) => {
    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'deleteProfanity',
      adminId: UID,
      adminEmail,
      adminName,
      data: word,
      date: Firebase.database.ServerValue.TIMESTAMP,
    });

    FirebaseRef.child(`profanitiesList/${word}`).remove().then(() => resolve());
  });
}

export function getmissedTurnsData(userId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());


  return dispatch => new Promise(resolve => AdminLogsRef().child(`missedTurnsData/${userId}`).once('value', (snapshot) => {
    const missedTurnsData = snapshot.val() || {};

    return resolve(dispatch({ type: 'MISSED_TURNS_DATA', data: missedTurnsData }));
  })).catch((err) => { console.log(err.message); });
}

export function getUserCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());


  return dispatch => new Promise(resolve => FirebaseRef.child('statistics/userCount')
    .on('value', (snapshot) => {
      const userCount = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_USER_COUNT', data: userCount }));
    })).catch((err) => { console.log(err.message); });
}

export function getUsersRange(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('users fetch getUsersRange', start, end);

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('users').off();

    FirebaseRef.child('users')
      .orderByChild('userIndex')
      .startAt(start)
      .endAt(end)
      .on('child_changed', (snapshot) => {
        const user = snapshot.val() || {};
        const { key } = snapshot;

        console.log('getUsersRange change', key, user);

        dispatch({ type: 'ADMIN_USER_REPLACE', data: { user, key } });
      });

    FirebaseRef.child('users')
      .orderByChild('userIndex')
      .startAt(start)
      .endAt(end)
      .once('value', (snapshot) => {
        const users = snapshot.val() || {};

        return resolve(dispatch({ type: 'ADMIN_USERS_REPLACE', data: users }));
      });
  }).catch((err) => { console.log(err.message); });
}


export function getFilteredUsers(filterValue, filterType) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!filterType || !filterValue) return dispatch => new Promise(resolve => resolve(dispatch({ type: 'ADMIN_FILTERED_USERS', data: null })));
  if (filterType === constants.USERNAME_LOWER_UPPER.lowerCaseName || filterType === constants.USERNAME_LOWER_UPPER.lowerCaseLastName) {
    return dispatch => new Promise(resolve => FirebaseRef.child('users')
      .orderByChild(filterType)
      .startAt(filterValue.toLowerCase())
      .endAt(`${filterValue.toLowerCase()}\uf8ff`)
      .once('value', (snapshot) => {
        const data = snapshot.val() || {};

        return resolve(dispatch({ type: 'ADMIN_FILTERED_USERS', data }));
      })).catch((err) => { console.log(err.message); });
  }


  if (filterType === 'uid') {
    return dispatch => new Promise(resolve => FirebaseRef.child('users')
      .orderByKey()
      .equalTo(filterValue.toString())
      .limitToFirst(1)
      .once('value', (snapshot) => {
        const data = snapshot.val() || {};

        return resolve(dispatch({ type: 'ADMIN_FILTERED_USERS', data }));
      })).catch((err) => { console.log(err.message); });
  }

  if (filterType === 'email') {
    return dispatch => new Promise(resolve => FirebaseRef.child('users')
      .orderByChild(filterType)
      .startAt(filterValue)
      .endAt(`${filterValue}\uf8ff`)
      .once('value', (snapshot) => {
        const data = snapshot.val() || {};

        return resolve(dispatch({ type: 'ADMIN_FILTERED_USERS', data }));
      })).catch((err) => { console.log(err.message); });
  }

  return new Promise(resolve => resolve());
}

export function getNonVerifiedUsers(filterValue, filterType) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  console.log('users fetch getNonVerifiedUsers', filterValue, filterType);

  return dispatch => new Promise(resolve => FirebaseRef.child('users')
    .orderByChild('verifiedEmail')
    .equalTo(false)
    // .limitToFirst(300)
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};
      const array = [];

      console.log('data', data);

      Object.keys(data).map((key) => {
        if (filterValue && filterType) {
          if (filterType === 'lowerCaseName') {
            if (data[key].lowerCaseName && data[key].lowerCaseName.includes(filterValue.toLowerCase())) {
              array.push({ key, ...data[key] });
            }
          } else if (filterType === 'lowerCaseLastName') {
            if (data[key].lowerCaseLastName && data[key].lowerCaseLastName.includes(filterValue.toLowerCase())) {
              array.push({ key, ...data[key] });
            }
          } else if (filterType === 'uid') {
            if (key && key.includes(filterValue.toLowerCase())) {
              array.push({ key, ...data[key] });
            }
          } else if (filterType === 'email') {
            if (data[key].email && data[key].email.includes(filterValue.toLowerCase())) {
              array.push({ key, ...data[key] });
            }
          }
        } else {
          array.push({ key, ...data[key] });
        }
        return null;
      });

      array.sort((a, b) => (b.lastLogin - a.lastLogin));
      return resolve(dispatch({ type: 'ADMIN_NONVERIFIED_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function getRoomLogCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());


  return dispatch => new Promise(resolve => FirebaseRef.child('statistics/roomsPlayed')
    .on('value', (snapshot) => {
      const roomsPlayed = snapshot.val() || 0;

      return resolve(dispatch({ type: 'ADMIN_ROOM_LOGS_COUNT', data: roomsPlayed }));
    })).catch((err) => { console.log(err.message); });
}

export function getRoomLogCount2() {
  if (Firebase === null) return () => new Promise(resolve => resolve());


  return dispatch => new Promise(resolve => FirebaseRef.child('statistics/roomsPlayed2')
    .on('value', (snapshot) => {
      const roomsPlayed = snapshot.val() || 0;

      return resolve(dispatch({ type: 'ADMIN_ROOM_LOGS_COUNT_2', data: roomsPlayed }));
    })).catch((err) => { console.log(err.message); });
}


export function getRoomLogsRange(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => AdminLogsRef().child('adminLogs/roomIds')
    .orderByChild('index')
    .startAt(start)
    .endAt(end)
    .once('value', async (snapshot) => {
      const roomLogs = snapshot.val() || {};
      forEach(roomLogs, async (item, key) => {
        const promise1 = await AdminLogsRef().child(`adminLogs/rooms/${key}`).once('value');
        roomLogs[key] = { ...roomLogs[key], partyCount: size(promise1.val()) - 1 };
      });
      console.log(roomLogs, "check===================")

      return resolve(dispatch({ type: 'ADMIN_ROOM_LOGS_REPLACE', data: roomLogs }));
    }, (err) => { console.log(err); })).catch((err) => { console.log(err); });
}


export function getFilteredRoomLogs(filterValue, filterType, filterMinValue, filterSpeed, filterBet, filterAmountOfPlayer, filterCloseReason, showBotRoom, startDate, endDate) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('getFilteredRoomLogs', { filterValue, filterType, filterMinValue, filterSpeed, filterBet, filterAmountOfPlayer, filterCloseReason, showBotRoom, startDate, endDate });

  if (!filterType || (!filterValue && !filterMinValue && !startDate && !endDate && !filterSpeed && !filterBet && !filterAmountOfPlayer && filterType !== constants.MISSEDTURN && !filterCloseReason && !showBotRoom)) return dispatch => new Promise(resolve => resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: null })));

  if (filterCloseReason !== '') {
    if ([constants.LASTROUND, constants.LeftBeforeStart, constants.GAMEERROR, filterCloseReason === constants.LEFTROOM].includes(filterCloseReason)) { //  || filterCloseReason === constants.GAMEERROR || filterCloseReason === constants.LEFTROOM
      return dispatch => new Promise((resolve) => {
        AdminLogsRef().child('adminLogs/roomIds')
          .orderByChild('closeReason')
          .equalTo(filterCloseReason)
          .limitToLast(60000)
          .once('value', async (snapshot) => {
            const data = snapshot.val() || {};
      
            console.log('test 2 startDate', data);
      
            let updatedLogs = [];
            if (Object.keys(data).length > 0) {
              const promises = Object.entries(data).map(async ([key, item]) => {
                if (item.closeReason === filterCloseReason && item.date >= startDate && item.date <= endDate) {
      
                  updatedLogs.push({
                    ...item,
                    key,
                  });
                }
              });
      
              updatedLogs.reverse();
      
              await Promise.all(promises);
            }
      
            return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs }));
          });
      });
    }
    const selectFilterCloseReason = constants.closeReason.filter(item => filterCloseReason === item.id).reduce((obj, key) => {
      const objCopy = { ...obj };
      objCopy[key] = constants.closeReason[key];
      return objCopy;
    });
    return dispatch => new Promise(resolve => {
      AdminLogsRef().child('adminLogs/roomIds')
      .orderByChild('date')
      .startAt(startDate)
        .endAt(endDate)
        .limitToLast(60000)
      .once('value', async (snapshot) => {
        const data = snapshot.val() || {};

        let updatedLogs = [];
        if (Object.keys(data).length > 0) {
          const promises = Object.entries(data).map(async ([key, item]) => {
            if ((item.closeReason === selectFilterCloseReason.filterType && (!selectFilterCloseReason.isOnline && (data[key].isOnline === selectFilterCloseReason.isOnline || !data[key].isOnline)) && (!selectFilterCloseReason.triedToMove && (data[key].triedToMove === selectFilterCloseReason.triedToMove || !data[key].triedToMove))) || (item.closeReason === selectFilterCloseReason.filterType && (selectFilterCloseReason.isOnline && data[key].isOnline === selectFilterCloseReason.isOnline) && (selectFilterCloseReason.triedToMove && data[key].triedToMove === selectFilterCloseReason.triedToMove))) {

              updatedLogs.push({
                  ...item,
                  key,
              });
            }
          });
          
          await Promise.all(promises);
        }
        
        return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
      })
    })
  }


  if (filterMinValue !== '') {
    return dispatch => new Promise(resolve => {
      AdminLogsRef().child('adminLogs/roomIds')
      .orderByChild('date')
      .startAt(startDate)
        .endAt(endDate)
        .limitToLast(60000)
      .once('value', async (snapshot) => {
        const data = snapshot.val() || {};

        let updatedLogs = [];
        if (Object.keys(data).length > 0) {
          const promises = Object.entries(data).map(async ([key, item]) => {
            if (data[key]?.party >= parseInt(filterMinValue, 10)) {

              updatedLogs.push({
                  ...item,
                  key,
              });
            }
          });
          
          await Promise.all(promises);
        }

        return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
      })
    })
  }

  if (filterSpeed !== '') {
    return dispatch => new Promise(resolve => {
      AdminLogsRef().child('adminLogs/roomIds')
      .orderByChild('date')
      .startAt(startDate)
        .endAt(endDate)
        .limitToLast(60000)
      .once('value', async (snapshot) => {
        const data = snapshot.val() || {};

        let updatedLogs = [];
        if (Object.keys(data).length > 0) {
          const promises = Object.entries(data).map(async ([key, item]) => {
            if (data[key]?.speed === filterSpeed) {

              updatedLogs.push({
                  ...item,
                  key,
              });
            }
          });
          
          await Promise.all(promises);
        }
        return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
      })
    })
  }

  if (filterBet !== '') {
    return dispatch => new Promise(resolve => {
      AdminLogsRef().child('adminLogs/roomIds')
      .orderByChild('date')
      .startAt(startDate)
        .endAt(endDate)
        .limitToLast(60000)
      .once('value', async (snapshot) => {
        const data = snapshot.val() || {};

        let updatedLogs = [];
        if (Object.keys(data).length > 0) {
          const promises = Object.entries(data).map(async ([key, item]) => {
            if (data[key]?.bet === filterBet) {

              updatedLogs.push({
                  ...item,
                  key,
              });
            }
          });
          
          await Promise.all(promises);
        }

        return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
      })
    })
  }

  if (filterAmountOfPlayer !== '') {
    if (filterAmountOfPlayer === 'true') {
      return dispatch => new Promise(resolve => {
        AdminLogsRef().child('adminLogs/roomIds')
        .orderByChild('date')
        .startAt(startDate)
          .endAt(endDate)
          .limitToLast(60000)
        .once('value', async (snapshot) => {
          const data = snapshot.val() || {};

          let updatedLogs = [];
          if (Object.keys(data).length > 0) {
            const promises = Object.entries(data).map(async ([key, item]) => {
              if (data[key]?.fourPRoom) {

                updatedLogs.push({
                    ...item,
                    key,
                });
              }
            });
            
            await Promise.all(promises);
          }

          return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
        })
      })
    }
    return dispatch => new Promise(resolve => {
      AdminLogsRef().child('adminLogs/roomIds')
      .orderByChild('date')
      .startAt(startDate)
        .endAt(endDate)
        .limitToFirst(250)
      .once('value', async (snapshot) => {
        const data = snapshot.val() || {};

        let updatedLogs = [];
        if (Object.keys(data).length > 0) {
          const promises = Object.entries(data).map(async ([key, item]) => {
            if (!data[key]?.fourPRoom) {

              updatedLogs.push({
                  ...item,
                  key,
              });
            }
          });
          
          await Promise.all(promises);
        }

        return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs }));
      })
    })
  }

  console.log('adminLogs/roomIds test 2', { filterType, filterValue, startDate, endDate });

  if (filterType === constants.ROOMID && !filterValue && startDate && endDate) {
    return dispatch => new Promise(resolve => AdminLogsRef().child('adminLogs/roomIds')
      .orderByChild('date')
      .startAt(startDate)
      .endAt(endDate)
      .limitToLast(60000)
      .once('value', async (snapshot) => {
        const data = snapshot.val() || {};

        let updatedLogs = [];
        if (Object.keys(data).length > 0) {
          const promises = Object.entries(data).map(async ([key, item]) => {
            updatedLogs.push({
              ...item,
              key,
            });
          });

          await Promise.all(promises);
        }

        console.log('updatedLogs 1', updatedLogs);

        return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
      })).catch((err) => { console.log(err.message); });
  }

  if (filterType === constants.ROOMID) {
    return dispatch => new Promise(resolve => AdminLogsRef().child('adminLogs/roomIds')
      .orderByKey()
      .startAt(filterValue.trim())
      .endAt(`${filterValue.trim()}\uf8ff`)
      .limitToFirst(60000)
      .once('value', async (snapshot) => {
        const data = snapshot.val() || {};

        let updatedLogs = [];
        if (Object.keys(data).length > 0) {
          const promises = Object.entries(data).map(async ([key, item]) => {
            updatedLogs.push({
              ...item,
              key,
            });
          });

          await Promise.all(promises);
        }

        console.log('updatedLogs 2', updatedLogs);

        return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
      })).catch((err) => { console.log(err.message); });
  }

  if (filterType === constants.USERID && startDate && endDate) {
    return dispatch => new Promise(resolve => AdminLogsRef().child(`adminLogs/playerRooms/${filterValue}`)
      .orderByChild('date')
      .startAt(startDate)
      .endAt(endDate)
      .limitToLast(60000)
        .once('value', async (snapshot) => {
          const data = snapshot.val() || {};
          console.log(data, "checkRoomId=");


          let updatedLogs = [];
          if (Object.keys(data).length > 0) {
            const promises = Object.entries(data).map(async ([key, item]) => {
              updatedLogs.push({
                ...item,
                key,
              });
            });

            await Promise.all(promises);
          }

          return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
        })).catch((err) => { console.log(err.message); });
  }

  if (filterType === constants.USERID) {
    return dispatch => new Promise(resolve => AdminLogsRef().child(`adminLogs/playerRooms/${filterValue}`).limitToLast(1000)
      .once('value', async (snapshot) => {
        const data = snapshot.val() || {};
        console.log(data, "checkRoomId=");

        let updatedLogs = [];
        if (Object.keys(data).length > 0) {
          const promises = Object.entries(data).map(async ([key, item]) => {
            updatedLogs.push({
              ...item,
              key,
            });
          });

          await Promise.all(promises);
        }

        return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
      })).catch((err) => { console.log(err.message); });
  }

  if (showBotRoom) {
    return dispatch => new Promise(resolve => {
      AdminLogsRef().child('adminLogs/roomIds')
      .orderByChild('date')
      .startAt(startDate)
      .endAt(endDate)
      .once('value', async (snapshot) => {
        const data = snapshot.val() || {};

        let updatedLogs = [];
        if (Object.keys(data).length > 0) {
          const promises = Object.entries(data).map(async ([key, item]) => {
            if (data[key]?.hasBots) {

              updatedLogs.push({
                  ...item,
                  key,
              });
            }
          });
          
          await Promise.all(promises);
        }

        return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
      })
    })
  }

  if (filterType === constants.MISSEDTURN) {
    return dispatch => new Promise(resolve => {
      AdminLogsRef().child('adminLogs/roomIds')
      .orderByChild('date')
      .startAt(startDate)
      .endAt(endDate)
      .once('value', async (snapshot) => {
        const data = snapshot.val() || {};

        let updatedLogs = [];
        if (Object.keys(data).length > 0) {
          const promises = Object.entries(data).map(async ([key, item]) => {
            if (data[key]?.closeReason === constants.MISSEDTURN) {

              updatedLogs.push({
                  ...item,
                  key,
              });
            }
          });
          
          await Promise.all(promises);
        }

        return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
      })
    })
  }

  if (startDate && endDate) {
    return dispatch => new Promise(resolve => {
      let query = AdminLogsRef().child('adminLogs/roomIds')
        .orderByChild(filterType)
        .startAt(filterValue.trim())
        .endAt(`${filterValue.trim()}\uf8ff`)
        .limitToLast(3000);
    
      query.once('value', async (snapshot) => {
        const data = snapshot.val() || {};
        
        console.log('datadatadata', data, filterType, filterValue);
        
        let updatedLogs = [];
        if (Object.keys(data).length > 0) {
          updatedLogs = Object.entries(data)
            .map(([key, item]) => ({ ...item, key }))
            .filter(item => item.date >= startDate && item.date <= endDate);
        }
        
        return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
      });
    }).catch((err) => { 
      console.log(err.message); 
    });
  }

  return dispatch => new Promise(resolve => AdminLogsRef().child('adminLogs/roomIds')
    .orderByChild(filterType)
    .startAt(filterValue.trim())
    .endAt(`${filterValue.trim()}\uf8ff`)
    .limitToLast(3000)
    .once('value', async (snapshot) => {
      const data = snapshot.val() || {};

      console.log('datadatadata', data, filterType, filterValue);

      let updatedLogs = [];
      if (Object.keys(data).length > 0) {
        const promises = Object.entries(data).map(async ([key, item]) => {
          updatedLogs.push({
            ...item,
            key,
          });
        });

        await Promise.all(promises);
      }

      return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
    })).catch((err) => { console.log(err.message); });
}

export function getMissedRooms(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!start || !end) return () => new Promise(resolve => resolve());

  return () => new Promise(resolve => AdminLogsRef().child('missedRoomsData')
    .orderByChild('date')
    .startAt(start)
    .endAt(end)
    .once('value', (snapshot) => {
      const roomLogs = snapshot.val() || {};

      return resolve(roomLogs); 
    }, (err) => { console.log(err); })).catch((err) => { console.log(err); });
}

export function getSuspiciousPlayers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => AdminLogsRef().child('roomsTogether/suspiciousPlayers')
    .once('value', (snapshot) => {
      const suspiciousPlayers = snapshot.val() || {};

      return resolve(dispatch({ type: 'SUSPICIOUS_PLAYERS', data: suspiciousPlayers }));
    }, (err) => { console.log(err); })).catch((err) => { console.log(err); });
}

export function getSuspiciousPlayersRooms(player1, player2) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => AdminLogsRef().child(`roomsTogether/playerRooms/${player1}/${player2}`)
    .once('value', (snapshot) => {
      const rooms = snapshot.val() || {};

      return resolve(dispatch({ type: 'SUSPICIOUS_PLAYERS_ROOMS', data: rooms }));
    }, (err) => { console.log(err); })).catch((err) => { console.log(err); });
}

export function getFilteredTransactions(provider, purchasedItem, dateFrom, dateTo, show, UUID) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('getFilteredTransactions', provider, purchasedItem, dateFrom, dateTo, show, UUID);

  const startAt = new Date(dateFrom).setHours(0, 0, 0, 0);
  const endAt = new Date(dateTo).setHours(23, 59, 59, 59);
  return dispatch => new Promise((resolve) => {
    if (dateFrom != null && dateTo != null) {
      FirebaseRef.child(show)
        .orderByChild('dateInitiated')
        .startAt(startAt)
        .endAt(endAt)
        .once('value', (snapshot) => {
          let data = snapshot.val() || {};
          if (UUID !== '') {
            data = Object.keys(data).filter(key => data[key].userUid === UUID).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (provider !== 'all') {
            console.log('data 2', data);

            data = Object.keys(data).filter(key => data[key].provider === provider).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (purchasedItem != null) {
            const purchasedItemArray = [];
            Object.keys(purchasedItem).map((key1) => {
              purchasedItemArray.push(purchasedItem[key1].value);
              return null;
            });
            data = Object.keys(data).filter(key => purchasedItemArray.includes(data[key].productNr ? data[key].productNr.toString() : '') === true)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          }
          console.log('data', data);
          return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data }));
        });
    } else if (dateFrom != null && dateTo == null) {
      FirebaseRef.child(show)
        .orderByChild('dateInitiated')
        .startAt(startAt)
        .once('value', (snapshot) => {
          let data = snapshot.val() || {};
          if (UUID !== '') {
            data = Object.keys(data).filter(key => data[key].userUid === UUID).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (provider !== 'all') {
            data = Object.keys(data).filter(key => data[key].provider === provider).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (purchasedItem != null) {
            const purchasedItemArray = [];
            Object.keys(purchasedItem).map((key1) => {
              purchasedItemArray.push(purchasedItem[key1].value);
              return null;
            });
            data = Object.keys(data).filter(key => purchasedItemArray.includes(data[key].productNr ? data[key].productNr.toString() : '') === true)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          }
          console.log('data', data);

          return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data }));
        });
    } else if (dateFrom == null && dateTo != null) {
      FirebaseRef.child(show)
        .orderByChild('dateInitiated')
        .endAt(endAt)
        .once('value', (snapshot) => {
          let data = snapshot.val() || {};
          if (UUID !== '') {
            data = Object.keys(data).filter(key => data[key].userUid === UUID).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (provider !== 'all') {
            data = Object.keys(data).filter(key => data[key].provider === provider).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (purchasedItem != null) {
            const purchasedItemArray = [];
            Object.keys(purchasedItem).map((key1) => {
              purchasedItemArray.push(purchasedItem[key1].value);
              return null;
            });
            data = Object.keys(data).filter(key => purchasedItemArray.includes(data[key].productNr ? data[key].productNr.toString() : '') === true)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          }
          console.log('dataaaa', data);

          return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data }));
        });
    } else {
      FirebaseRef.child(show)
        .orderByChild('dateInitiated')
        .once('value', (snapshot) => {
          let data = snapshot.val() || {};
          if (UUID !== '') {
            data = Object.keys(data).filter(key => data[key].userUid === UUID).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (provider !== 'all') {
            console.log('data', data);
            data = Object.keys(data).filter(key => data[key].provider === provider).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (purchasedItem != null) {
            const purchasedItemArray = [];
            Object.keys(purchasedItem).map((key1) => {
              purchasedItemArray.push(purchasedItem[key1].value);
              return null;
            });
            data = Object.keys(data).filter(key => purchasedItemArray.includes(data[key].productNr ? data[key].productNr.toString() : '') === true)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          }
          console.log('data', data);

          return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data }));
        });
    }
  }).catch((err) => { console.log(err.message); });
}

export function getPaymentsRange(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('initiatedPayments')
    .orderByChild('index')
    .startAt(start)
    .endAt(end)
    .once('value', (snapshot) => {
      const initiatedPayments = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data: initiatedPayments }));
    })).catch((err) => { console.log(err.message); });
}


export function getCompletedPaymentsRange(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('completedPayments')
    .orderByChild('index')
    .startAt(start)
    .endAt(end)
    .once('value', (snapshot) => {
      const completedPayments = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data: completedPayments }));
    })).catch((err) => { console.log(err.message); });
}

export function getInitiatedPaymentsRange(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('incompletePayments')
    .orderByChild('index')
    .startAt(start)
    .endAt(end)
    .once('value', (snapshot) => {
      const initiatedPayments = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data: initiatedPayments }));
    })).catch((err) => { console.log(err.message); });
}

export function getPaymentsCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('initiatedPaymentsCount/count')
      .on('value', (snapshot) => {
        const paymentsCount = snapshot.val() || 0;

        dispatch({ type: 'ADMIN_PAYMENTS_COUNT', data: paymentsCount });

        FirebaseRef.child('initiatedPaymentsRemovedCount/count')
          .on('value', (snapshot2) => {
            const removedPaymentsCount = snapshot2.val() || 0;

            return resolve(dispatch({ type: 'ADMIN_REMOVED_PAYMENTS_COUNT', data: removedPaymentsCount }));
          });
      });
  }).catch((err) => { console.log(err.message); });
}


export function getCompletedPaymentsCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('completedPaymentsCount/count')
    .once('value', (snapshot) => {
      const paymentsCount = snapshot.val() || 0;

      return resolve(dispatch({ type: 'ADMIN_COMPLETED_PAYMENTS_COUNT', data: paymentsCount }));
    })).catch((err) => { console.log(err.message); });
}

export function getInitiatedPaymentsCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('incompletePaymentsCount/count')
    .once('value', (snapshot) => {
      const paymentsCount = snapshot.val() || 0;

      return resolve(dispatch({ type: 'ADMIN_INITIATED_PAYMENTS_COUNT', data: paymentsCount }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllUsers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('getAllUsers');

  return dispatch => new Promise(resolve => FirebaseRef.child('users')
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_USERS_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}


export function getUserMessages(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child(`supportChat/messages/${uid.toString()}`)
    .limitToLast(100)
    .on('value', (snapshot) => {
      const messages = snapshot.val() || {};

      resolve(dispatch({
        type: 'USER_CHATS',
        data: messages,
      }));
    }));
}

export function cancelUserMessages(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`supportChat/messages/${uid.toString()}`).off();
    return resolve(dispatch({
      type: 'USER_CHATS',
      data: {},
    }));
  });
}

export function getActiveReadMessages() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('supportChat/activeChats')
      .orderByChild('responded').equalTo(true).once('value', (readChatsSnapshot) => {
        const readChats = readChatsSnapshot.val() || {};

        console.log('activeChats readChats', readChats);

        FirebaseRef.child('supportChat/activeChats')
          .orderByChild('responded').equalTo(true).on('child_added', (unreadChatsSnapshot) => {
            const unreadChats = unreadChatsSnapshot.val() || {};
            const { key } = unreadChatsSnapshot;

            dispatch({
              type: 'ACTIVE_READ_MESSAGE_CHANGE',
              data: unreadChats,
              key,
            });
          });

        FirebaseRef.child('supportChat/activeChats')
          .orderByChild('responded').equalTo(true).on('child_changed', (unreadChatsSnapshot) => {
            const unreadChats = unreadChatsSnapshot.val() || {};
            const { key } = unreadChatsSnapshot;

            dispatch({
              type: 'ACTIVE_READ_MESSAGE_CHANGE',
              data: unreadChats,
              key,
            });
          });

        FirebaseRef.child('supportChat/activeChats')
          .orderByChild('responded').equalTo(true).on('child_removed', (unreadChatsSnapshot) => {
            const { key } = unreadChatsSnapshot;

            dispatch({
              type: 'ACTIVE_READ_MESSAGES_REMOVE',
              key,
            });
          });

        resolve(dispatch({
          type: 'ACTIVE_READ_MESSAGES',
          data: readChats,
        }));
      });
  });
}

export function getActiveUnreadMessages() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('supportChat/activeChats')
      .orderByChild('responded').equalTo(false).once('value', (unreadChatsSnapshot) => {
        const unreadChats = unreadChatsSnapshot.val() || {};

        console.log('activeChats unreadChats', unreadChats);

        FirebaseRef.child('supportChat/activeChats')
          .orderByChild('responded').equalTo(false).on('child_added', (unreadChatsChangeSnapshot) => {
            const unreadChatsChange = unreadChatsChangeSnapshot.val() || {};
            const { key } = unreadChatsChangeSnapshot;

            dispatch({
              type: 'ACTIVE_UNREAD_MESSAGE_CHANGE',
              data: unreadChatsChange,
              key,
            });
          });

        FirebaseRef.child('supportChat/activeChats')
          .orderByChild('responded').equalTo(false).on('child_changed', (unreadChatsChangeSnapshot) => {
            const unreadChatsChange = unreadChatsChangeSnapshot.val() || {};
            const { key } = unreadChatsChangeSnapshot;

            dispatch({
              type: 'ACTIVE_UNREAD_MESSAGE_CHANGE',
              data: unreadChatsChange,
              key,
            });
          });

        FirebaseRef.child('supportChat/activeChats')
          .orderByChild('responded').equalTo(false).on('child_removed', (unreadChatsChangeSnapshot) => {
            const { key } = unreadChatsChangeSnapshot;

            dispatch({
              type: 'ACTIVE_UNREAD_MESSAGES_REMOVE',
              key,
            });
          });

        resolve(dispatch({
          type: 'ACTIVE_UNREAD_MESSAGES',
          data: unreadChats,
        }));
      });
  });
}

export function getActiveMessages2() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('supportChat/activeChats')
    .orderByChild('active').equalTo(true).on('value', (snapshot) => {
      const activeChats = snapshot.val() || {};

      console.log('activeChats', activeChats);

      resolve(dispatch({
        type: 'ACTIVE_MESSAGES',
        data: activeChats,
      }));
    }));
}

export function updateAvatarFileUpload(fileUpload, editUserId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = editUserId;

  if (fileUpload !== '') {
    const baseData = fileUpload.toString();

    return () => new Promise((resolve) => {
      console.log('called here@@@@@@@@@@@@@@@@@', UID, baseData.replace(/^data:image\/[a-z]+;base64,/, ''));
      const uploadTask = FirebaseStorage.ref(`/userAvatars/${UID}`).child(`${UID}-${(Date.now().toString())}`)
        .putString(baseData.replace(/^data:image\/[a-z]+;base64,/, ''), 'base64', { contentType: 'image/jpg' });

      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            resolve({ status: 'success', url: downloadURL });
          });
        });
    });
  }
  return () => new Promise(resolve => resolve());
}

export function supportMessageFileUpload(fileUpload) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (fileUpload !== '') {
    return () => new Promise((resolve) => {
      const uploadTask = FirebaseStorage.ref(`/attachFiles/${(Date.now().toString())}`)
        .put(fileUpload);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            resolve({ status: 'success', url: downloadURL });
          });
        });
    });
  }
  return () => new Promise(resolve => resolve());
}

export async function supportFileUpload(fileUpload) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    if (fileUpload) {
      const uploadTask = FirebaseStorage.ref(`/attachFiles/${(Date.now().toString())}`)
        .put(fileUpload);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            return resolve({ status: 'success', url: downloadURL });
          });
        });
    } else {
      return resolve({ status: 'error' });
    }
  });
}

export function answerSupportMessage({
  uid, message, fileUploadUrl, fileName, screen,
}) {
  if (!uid) return () => new Promise(resolve => resolve());

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  console.log('Firebase.auth().currentUser', { currentUser: Firebase.auth().currentUser });

  let env = screen || constants.DWEB_PLATFORM;

    const isInDraugiemAppFrame = config.isInDraugiemAppFrame();
    const isInFacebooxAppFrame = config.isInFacebookAppFrame();
    const isInInboxAppFrame = config.isInInboxAppFrame();
    let deviceInfo = screen || constants.DWEB_PLATFORM;
    if (isTablet) {
      env = constants.TABLET_PLATFORM;
      deviceInfo = constants.TABLET_PLATFORM;
    } else if (isIOS) {
      env = constants.IOS_PLATFORM.toLowerCase();
      deviceInfo = constants.IOS_PLATFORM.toLowerCase();
    } else if (isAndroid && !isDesktop) {
      env = constants.ANDROID_PLATFORM;
      deviceInfo = constants.ANDROID_PLATFORM;
    } else if (isInDraugiemAppFrame) {
      env = constants.DRAUGIEM_PLATFORM;
    } else if (isInFacebooxAppFrame) {
      env = constants.FACEBOOK_PLATFORM;
    } else if (isInInboxAppFrame) {
      env = constants.INBOX_PLATFORM;
    }
    let additionalData = {};
    
    if (deviceInfo === 'android' || deviceInfo === 'ios') {
      additionalData = {
        ...additionalData,
        appVersion: osVersion || '',
        osVersion: `${osName || ''} - ${mobileModel || ''}`,
      };
    }

  return () => new Promise(resolve => FirebaseRef.child(`users/${uid.toString()}/name`).once('value', (snapshot) => {
    const name = snapshot.val() || null;
    FirebaseRef.child(`supportChat/messages/${uid.toString()}`).push({
      message: !message ? '' : message,
      date: Firebase.database.ServerValue.TIMESTAMP,
      fileUpload: fileUploadUrl || null,
      fileName: fileName || null,
      env,
      device: deviceInfo,
    }).then(() => {
      FirebaseRef.child(`supportChat/activeChats/${uid.toString()}`).update({
        //  active: true,
        name: name || null,
        responded: true,
        autoRespond: false,
        lastResponse: Firebase.database.ServerValue.TIMESTAMP,
        read: false,
      });

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'sendMessage',
        message: !message ? '' : message,
        adminId: UID,
        adminEmail,
        adminName,
        userId: uid.toString(),
        userName: name || null,
        date: Firebase.database.ServerValue.TIMESTAMP,
        fileUpload: fileUploadUrl || null,
        fileName: fileName || null,
        env,
        device: deviceInfo,
      });

      resolve({ status: 'success' });
    }).catch((err) => {
      resolve({ status: 'error', mesage: err });
    });
  }));
}


export function setSupportMessageAsResponded({ uid }) {
  if (!uid) return () => new Promise(resolve => resolve());

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise(resolve => FirebaseRef.child(`users/${uid.toString()}/name`).once('value', (snapshot) => {
    const name = snapshot.val() || null;

    console.log('name', { name });

    FirebaseRef.child(`supportChat/activeChats/${uid.toString()}`).update({
      responded: true,
      autoRespond: false,
    }).then(() => {
      console.log('test2');

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'setMessageAsRead',
        adminId: UID,
        adminEmail,
        adminName,
        userId: uid.toString(),
        userName: name || null,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      resolve({ status: 'success' });
    }).catch((err) => {
      console.log(err);
      resolve({ status: 'error', mesage: err });
    });
  }));
}

export function messageAll(message, filterPlayers, messageLvInput, messageEnInput, messageRuInput, languageType) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    console.log(message, 'ALL', filterPlayers, 'filter', messageLvInput, 'LV', messageEnInput, 'EN', messageRuInput, 'RU', languageType, 'admin check');

    const messageAllFunction = Firebase.app().functions().httpsCallable('messageAll');

    messageAllFunction({
      message,
      filterPlayers,
      messageLvInput,
      messageEnInput,
      messageRuInput,
      languageType,
    }).then((res) => {
      if (res.data && res.data.status === constants.SERVER_STATUS_CODE.success) {
        return resolve({ status: constants.SERVER_STATUS_CODE.success });
      }
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    }).catch(err => resolve({ status: constants.SERVER_STATUS_CODE.error, message: err.code }));
  });
}


export function getUsersCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('users_count')
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'USERS_COUNT_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}


export function getAllVipUsers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('users fetch getAllVipUsers');

  return dispatch => new Promise(resolve => FirebaseRef.child('users')
    .orderByChild('vip')
    .equalTo(true)
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_VIP_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}


export function getAllBans() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('bans')
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};

      const array = [];
      map(data, (item, key) => {
        array.push({ key, ...data[key] });
        return null;
      });
      array.sort((a, b) => b.endDate - a.endDate);

      return resolve(dispatch({ type: 'ADMIN_BANS2_REPLACE', array }));
    })).catch((err) => { console.log(err.message); });
}


export function getBansRange(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('bans')
    .orderByChild('endDate')
    .limitToLast(end)
    .on('value', (snapshot) => {
      const bans = snapshot.val() || {};
      const array = [];
      map(bans, (item, key) => {
        array.push({ key, ...bans[key] });
        return null;
      });
      array.sort((a, b) => b.endDate - a.endDate);
      return resolve(dispatch({ type: 'ADMIN_BANS_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}


export function getBansCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('statistics/bansCount')
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'BANS_COUNT_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllTransactions() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('transactions')
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_TRANSACTIONS_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllTournaments() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => TournamentsRef().child('newTournamentsList')
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_TOURNAMENTS_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}

export function getClaimedPlayers(code) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child(`claimedGiftCodes/${code}`)
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_CLAIMEDPLAYERS_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllGiftCodes() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('giftCodes')
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};
      const array = [];

      Object.keys(data).map((key) => {
        array.push({ key, ...data[key] });
        return null;
      });

      array.sort((a, b) => (a.expires - b.expires));
      return resolve(dispatch({ type: 'ADMIN_GIFTCODES_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function getTournamentPlayers(tournamentId, tournamentState) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const promise1 = TournamentsRef().child(`newTournaments/${tournamentId}/players`).once('value');
    const promise2 = TournamentsRef().child(`newTournaments/${tournamentId}/playersStaticData`).once('value');

    Promise.all([promise1, promise2]).then((promisesRes) => {
      let playersData;
      let playersStaticData;

      promisesRes.map((promiseRes, index) => {
        if (index === 0) {
          playersData = promiseRes.val() || {};
        } else if (index === 1) {
          playersStaticData = promiseRes.val() || {};
        }
        return null;
      });

      const players = {};
      const playersArr = [];

      if (playersStaticData) {
        Object.keys(playersStaticData).map((key) => {
          players[key] = { ...playersData[key], ...playersStaticData[key] };
          playersArr.push({ key, ...playersData[key], ...playersStaticData[key] });
          return null;
        });
      }
      console.log(players, "tournamentsPlayers playersArr", playersArr);

      //closed
      if (tournamentState === 'closed') {
        playersArr.sort((a, b) => (a.finalPosition - b.finalPosition));

        playersArr.sort((a, b) => {
          const aMainPoints = a.tournamentPoints || 0;
          const bMainPoints = b.tournamentPoints || 0;

          const aTotalRoomPoints = a.totalRoomPoints || 0;
          const bTotalRoomPoints = b.totalRoomPoints || 0;

          if (bMainPoints === aMainPoints) {
            return bTotalRoomPoints - aTotalRoomPoints;
          }
          return bMainPoints - aMainPoints;
        });
      } else {
        playersArr.sort((a, b) => (a.joinTime - b.joinTime));
      }

      const players2 = {};

      playersArr.map((player) => {
        players2[player.key] = { ...player };
      });

      console.log('players2', players2);

      return resolve(dispatch({ type: 'TOURNAMENT_PLAYERS', data: { tournamentPlayers: players, tournamentPlayersArr: playersArr, tournamentId } }));
    });
  }).catch((err) => { console.log(err.message); });
}

export function getTournamentPlayerHistory(tournamentId, playerId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    TournamentsRef().child(`tournamentPlayerHistory/${tournamentId}/players/${playerId}`).once('value', (snapshot) => {
      const playerHistory = snapshot.val() || null;

      return resolve(dispatch({ type: 'TOURNAMENT_PLAYER_HISTORY', data: playerHistory }));
    }).catch((err) => {
      console.log(err);
      return resolve();
    });
  }).catch((err) => { console.log(err.message); });
}

export function getTournamentResults(tournamentId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const promise1 = TournamentsRef().child(`newTournaments/${tournamentId}/rounds`).once('value');

    Promise.all([promise1]).then((promisesRes) => {
      let rounds;

      promisesRes.map((promiseRes, index) => {
        if (index === 0) {
          rounds = promiseRes.val() || {};
        }
        return null;
      });

      return resolve(dispatch({ type: 'TOURNAMENT_RESULTS', data: { rounds, tournamentId } }));
    });
  }).catch((err) => { console.log(err.message); });

  // return dispatch => new Promise((resolve) => {
  //   const promise1 = AdminLogsRef().child(`adminLogs/rooms/${tournamentId}`).once('value');
  //   const promise2 = RoomsRef.child(`roomsPoints/${tournamentId}/points`).once('value');

  //   return Promise.all([promise1, promise2]).then((promisesRes) => {
  //     let data;
  //     let roomsPoints;
  //     promisesRes.map((resp, index) => {
  //       if (index === 0) {
  //         data = resp.val();
  //       } else if (index === 1) {
  //         roomsPoints = resp.val();
  //       }
  //       return null;
  //     });
  //     console.log(data, roomsPoints, "tournamentsPoints")

  //     return resolve(dispatch({
  //       type: 'TOURNAMENT_RESULTS', data: { data, tournamentId }, roomsPoints
  //     }));
  //   });
  // });
}


export function forceCloseTournament(tournamentId) {
  if (Firebase === null || !tournamentId) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    const forceCloseTournamentFunction = Firebase.app().functions().httpsCallable('forceCloseTournament');

    forceCloseTournamentFunction({
      tournamentId,
    }).then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'forceCloseTournament',
        adminId: UID,
        adminEmail,
        adminName,
        tournamentId,
        data: null,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      return resolve();
    });
  });
}


export function deleteUser(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  /*
  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  */

  return () => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const deleteUserFunction = Firebase.app().functions().httpsCallable('deleteUser');

      FirebaseRef.child(`users/${uid.toString()}/name`).once('value', (snapshot) => {
        const name = snapshot.val() || {};
        if (name) {
          deleteUserFunction({
            uid: uid.toString(),
          }).then((result) => {
            resolve(result);
          });
        }
      });
    }
  }));
}

export function manualyVerifyUser(uid, lang) {
  console.log('manualyVerifyUser', uid, lang);
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    const sendVerificationEmailFunction = Firebase.app().functions().httpsCallable('sendVerificationEmail2');

    sendVerificationEmailFunction({
      uid: uid.toString(),
      lang,
    }).then((result) => {
      console.log('sendVerificationEmailFunction result', result);
      if (result.data.status === 'success') {
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'sendVerifiedEmail',
          adminId: UID,
          adminEmail,
          adminName,
          uid,
          date: Firebase.database.ServerValue.TIMESTAMP,
        });
      }

      dispatch({ type: 'SEND_NON_VERIFIED_PLAYER', data: { status: 'success', uid } });
      resolve({ status: 'success' });
    });
  });
}

export function toggleLogRocket(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!uid) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav id' })); }

  const adminId = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise(resolve => FirebaseRef.child(`users/${uid.toString()}/enableLogRocket`)
    .once('value', (userSnapshot) => {
      const enableLogRocket = userSnapshot.val() || false;

      FirebaseRef.child(`users/${uid.toString()}`).update({
        enableLogRocket: !enableLogRocket,
      })
        .then(() => {
          dispatch({ type: 'CHANGE_LOGROCKET', data: { status: 'success', enabled: !enableLogRocket, uid } });

          AdminLogsRef().child('adminLogs/adminActions').push({
            type: !enableLogRocket ? 'enableLogrocket' : 'disableLogrocket',
            adminId,
            adminEmail,
            adminName,
            data: { uid },
            userId: uid,
            date: Firebase.database.ServerValue.TIMESTAMP,
          });

          return resolve({ status: 'success', enabled: !enableLogRocket });
        })
        .catch(err => resolve({ status: 'error', message: err }));
    }));
}

export function toggleSmartlook(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!uid) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav id' })); }

  return dispatch => new Promise(resolve => FirebaseRef.child(`users/${uid.toString()}/enableSmartlook`)
    .once('value', (userSnapshot) => {
      const enableSmartlook = userSnapshot.val() || false;

      FirebaseRef.child(`users/${uid.toString()}`).update({
        enableSmartlook: !enableSmartlook,
      })
        .then(() => {
          dispatch({ type: 'CHANGE_SMARTLOOK', data: { status: 'success', enabled: !enableSmartlook, uid } });

          return resolve({ status: 'success', enabled: !enableSmartlook });
        })
        .catch(err => resolve({ status: 'error', message: err }));
    }));
}


export function resetActiveRoom(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!uid) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav id' })); }

  return () => new Promise((resolve) => {
    FirebaseRef.child(`users/${uid.toString()}`).update({
      activeRoom: null,
      joinedRooms: null,
    })
      .then(() => resolve({ status: 'success' }))
      .catch(err => resolve({ status: 'error', message: err }));
  });
}


export function blockUser(uid, endDate, reason) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const endDate2 = new Date(endDate);

  const endDateParsed = Date.parse(endDate2);

  if (!uid) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav id' })); }
  if (!reason) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav iemesla' })); }
  if (!endDateParsed || !(endDate2 instanceof Date)) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav beigu laika' })); }

  const blockUserFunction = Firebase.app().functions().httpsCallable('blockUser');

  return dispatch => new Promise(resolve => blockUserFunction({
    uid,
    endDate: endDateParsed,
    reason,
  }).then(() => {
    dispatch({ type: 'CHANGE_BLOCK', data: { status: 'success', blocked: true, uid } });

    return resolve({ status: 'success' });
  }).catch((err) => {
    console.log(err);
    return resolve({ status: 'success' });
  }));
}


export function unblockUser(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!uid) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav id' })); }

  const unblockUserFunction = Firebase.app().functions().httpsCallable('unblockUser');

  return dispatch => new Promise(resolve => unblockUserFunction({
    uid,
  }).then(() => {
    dispatch({ type: 'CHANGE_BLOCK', data: { status: 'success', blocked: false, uid } });

    return resolve({ status: 'success' });
  }).catch((err) => {
    console.log(err);
    return resolve({ status: 'success' });
  }));
}


export function editUser(uid, bal, lvl, gPlayed, totalPnts, firstName, lastName, photo, updateFlag, filterValue, filterType, name, lowerCaseName, lowerCaseLastName) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!lvl && lvl !== 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav līmeņa' })); }
  if (!gPlayed && gPlayed !== 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav izspēlēto spēļu' })); }
  if (Number.isNaN(Number(lvl)) || lvl < 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Līmenim jābūt pozitīvam skaitlim' })); }
  if (Number.isNaN(Number(gPlayed)) || gPlayed < 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Izspēlētajām spēlēm jābūt pozitīvam skaitlim' })); }

  if (!firstName) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingFirstName })); }
  if (!lastName) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLastName })); }

  return () => new Promise((resolve) => {
    const promise1 = FirebaseRef.child(`users/${uid.toString()}/lvl`).once('value');
    const promise2 = FirebaseRef.child(`users/${uid.toString()}/gPlayed`).once('value');
    const promise3 = FirebaseRef.child(`users/${uid.toString()}/name`).once('value');

    Promise.all([promise1, promise2, promise3]).then((promiseRes) => {
      let userOldLvl;
      let userOldGPlayed;
      let oldName;
      promiseRes.map((res2) => {
        console.log(res2.key, '-----');

        if (res2) {
          if (res2.key === 'lvl') {
            userOldLvl = res2.val() || null;
          } else if (res2.key === 'gPlayed') {
            userOldGPlayed = res2.val() || null;
          } else if (res2.key === 'name') {
            oldName = res2.val() || null;
          }
        }
        return null;
      });

      FirebaseRef.child(`users/${uid.toString()}`).update({
        lvl,
        gPlayed,
        firstName,
        lastName,
        photo,
        name,
        lowerCaseName,
        lowerCaseLastName,
      }).then(() => {
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'editUser',
          adminId: UID,
          adminEmail,
          adminName,
          userId: uid.toString(),
          userName: name || null,
          data: {
            lvl,
            gPlayed,
            oldLvl: userOldLvl || null,
            oldGPLayed: userOldGPlayed || null,
            oldName,
          },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });

        resolve({ status: 'success' });
      }).catch((err) => {
        console.log(err);
        resolve({ status: 'error', message: 'Neizdevās labot' });
      });
    }).catch((err) => {
      console.log(err);
      resolve({ status: 'error', message: 'Neizdevās labot' });
    });
  });
}

export function addBalance(uid, balanceChange) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('addBalance', uid, balanceChange);

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!balanceChange && balanceChange !== 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav bilances' })); }

  return () => new Promise(resolve => FirebaseRef.child(`users/${uid.toString()}/name`).once('value', (snapshot) => {
    const name = snapshot.val() || null;

    FirebaseRef.child(`users/${uid.toString()}/bal`)
      .transaction(bal => (parseInt(bal, 10) || 0) + parseInt(balanceChange, 10)).then((transactionRes) => {
        if (transactionRes.committed) {
          const newBal = transactionRes.snapshot.val();

          UserStatsRef().child('statistics/money').update({ admin: Firebase.database.ServerValue.increment(parseInt(balanceChange, 10)) });

          if (parseInt(balanceChange, 10) < 0) {
            UserStatsRef().child('statistics/money').update({ adminRemoved: Firebase.database.ServerValue.increment(parseInt(balanceChange, 10)) });
          } else {
            UserStatsRef().child('statistics/money').update({ adminAdded: Firebase.database.ServerValue.increment(parseInt(balanceChange, 10)) });
          }

        //  LeaderboardRef.child(`leaderboard/allTime/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`leaderboard/daily/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`leaderboard/week/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`leaderboard/month/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`leaderboard/year/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));

        //  LeaderboardRef.child(`leaderboardPoints/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`dailyLeaderboardPoints/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`weekLeaderboardPoints/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`monthLeaderboardPoints/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`yearLeaderboardPoints/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));

          UserStatsRef().child(`userBalHistory/${uid.toString()}`).push({
            time: Date.now(),
            type: 'adminChange',
            change: balanceChange,
            old: newBal - parseInt(balanceChange, 10),
            new: newBal,
          });

          console.log('test2');

          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'addUserBalance',
            adminId: UID,
            adminEmail,
            adminName,
            userId: uid.toString(),
            userName: name || null,
            data: {
              old: newBal - parseInt(balanceChange, 10),
              new: newBal,
            },
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
        }
        resolve({ status: 'success' });
      }).catch((err) => {
        console.log(err);
        resolve({ status: 'error', message: 'Neizdevās labot' });
      });
  }));
}


export function addPoints(uid, totalPntsChange) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!totalPntsChange && totalPntsChange !== 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav bilances' })); }

  return () => new Promise(resolve => FirebaseRef.child(`users/${uid.toString()}/name`).once('value', (snapshot) => {
    const name = snapshot.val() || null;

    FirebaseRef.child(`users/${uid.toString()}/totalPnts`)
      .transaction(totalPnts => (parseInt(totalPnts, 10) || 0) + parseInt(totalPntsChange, 10)).then((transactionRes) => {
        if (transactionRes.committed) {
          const newPoints = transactionRes.snapshot.val();

          UserStatsRef().child(`userPointsHistory/${uid.toString()}`).push({
            time: Date.now(),
            type: 'adminChange',
            change: totalPntsChange,
            old: newPoints - parseInt(totalPntsChange, 10),
            new: newPoints,
          });

        //  LeaderboardRef.child(`leaderboard/allTime/${uid.toString()}/totalPnts`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`leaderboard/daily/${uid.toString()}/totalPnts`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`leaderboard/week/${uid.toString()}/totalPnts`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`leaderboard/month/${uid.toString()}/totalPnts`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`leaderboard/year/${uid.toString()}/totalPnts`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));

        //  LeaderboardRef.child(`leaderboardPoints/${uid.toString()}/tP`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`dailyLeaderboardPoints/${uid.toString()}/tP`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`weekLeaderboardPoints/${uid.toString()}/tP`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`monthLeaderboardPoints/${uid.toString()}/tP`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`yearLeaderboardPoints/${uid.toString()}/tP`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));

          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'addUserPoints',
            adminId: UID,
            adminEmail,
            adminName,
            userId: uid.toString(),
            userName: name || null,
            data: {
              old: newPoints - parseInt(totalPntsChange, 10),
              new: newPoints,
            },
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
        }
        resolve({ status: 'success' });
      }).catch(() => {
        resolve({ status: 'error', message: 'Neizdevās labot' });
      });
  }));
}

export function addGiftCode(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    code, expires, playerLimit, value,
  } = data;

  const expiresParsed = Date.parse(expires);
  const curDateParsed = Date.parse(new Date());
  const pattern = /^[a-zA-Z0-9]*$/;

  if (!code) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingGiftCode })); }
  if (code.match(pattern) === null) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.giftCodePatternFailed })); }
  if (code.length > 10 || code.length < 4) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.giftCodeLongFailed })); }
  if (!expiresParsed) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingExpire })); }
  if (!playerLimit || playerLimit < 0) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPlayerLimit })); }
  if (!value || value < 0) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingValue })); }

  return () => new Promise((resolve) => {
    FirebaseRef.child(`giftCodes/${code.toLowerCase()}`)
      .once('value', (giftCodeSnapshot) => {
        const giftCode = giftCodeSnapshot.val() || {};
        if (!giftCode.code) {
          const createGiftCodeFunction = Firebase.app().functions().httpsCallable('createGiftCode');

          return createGiftCodeFunction({
            code: code.toLowerCase(),
            expires: expiresParsed,
            playerLimit,
            value,
          }).then((result) => {
            if (result.data.status) {
              AdminLogsRef().child('adminLogs/adminActions').push({
                type: 'createGiftCode',
                adminId: UID,
                adminEmail,
                adminName,
                data: {
                  code: code.toLowerCase(),
                  expires: dateFormat(expires),
                  playerLimit,
                  value,
                },
                date: Firebase.database.ServerValue.TIMESTAMP,
              });

              return resolve({ status: 'success' });
            }
            return resolve({ status: 'error', message: result.data.error });
          }).catch(err => resolve({ status: 'error', message: err.message }));
        }

        if (giftCode.code === code.toLowerCase() && giftCode.expires < curDateParsed) {
          const createGiftCodeFunction = Firebase.app().functions().httpsCallable('createGiftCode');

          return createGiftCodeFunction({
            code: code.toLowerCase(),
            expires: expiresParsed,
            playerLimit,
            value,
          }).then((result) => {
            if (result.data.status) {
              AdminLogsRef().child('adminLogs/adminActions').push({
                type: 'createGiftCode',
                adminId: UID,
                adminEmail,
                adminName,
                data: {
                  code: code.toLowerCase(),
                  expires: dateFormat(expires),
                  playerLimit,
                  value,
                },
                date: Firebase.database.ServerValue.TIMESTAMP,
              });

              return resolve({ status: 'success' });
            }
            return resolve({ status: 'error', message: result.data.error });
          }).catch(err => resolve({ status: 'error', message: err.message }));
        }

        return resolve({ status: 'error', message: 'codeExist' });
      }).catch(err => resolve({ status: 'error', error: err }));
  });
}

export function editGiftCode({ data, oldData }) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  const {
    code, expires, playerLimit, value,
  } = data;

  const expiresParsed = Date.parse(expires);
  const pattern = /^[a-zA-Z0-9]*$/;
  const curDateParsed = Date.parse(new Date());

  if (!code) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingGiftCode })); }
  if (code.match(pattern) === null) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.giftCodePatternFailed })); }
  if (code.length > 10 || code.length < 3) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.giftCodeLongFailed })); }
  if (!expiresParsed) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingExpire })); }
  if (!playerLimit) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPlayerLimit })); }
  if (!value) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingValue })); }

  const dataToEdit = {
    code,
    expires: dateFormat(expires),
    playerLimit,
    value,
  };
  return () => new Promise((resolve) => {
    if (code !== oldData.code) {
      FirebaseRef.child(`giftCodes/${code.toLowerCase()}`)
        .once('value', (giftCodeSnapshot) => {
          const giftCode = giftCodeSnapshot.val() || {};
          if (!giftCode.code) {
            const editGiftCodeFunction = Firebase.app().functions().httpsCallable('editGiftCode');

            return editGiftCodeFunction({
              code: code.toLowerCase(),
              expires: expiresParsed,
              playerLimit,
              value,
            }).then((result) => {
              if (result.data.status === 'success') {
                if (dataToEdit.code === oldData.code) {
                  const changesObj = {};

                  Object.keys(dataToEdit).map((key) => {
                    if (oldData[key] !== dataToEdit[key]) {
                      changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
                    }
                    return null;
                  });

                  let changedlog = '';

                  Object.keys(changesObj).map((key, index) => {
                    changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

                    if ((index + 1) !== Object.keys(changesObj).length) {
                      changedlog += ', ';
                    }
                    return null;
                  });

                  AdminLogsRef().child('adminLogs/adminActions').push({
                    type: 'editGiftCode',
                    adminId: UID,
                    adminEmail,
                    adminName,
                    giftCodeId: code.toLowerCase(),
                    data: {
                      ...dataToEdit,
                      changedlog,
                    },
                    date: Firebase.database.ServerValue.TIMESTAMP,
                  });
                } else {
                  AdminLogsRef().child('adminLogs/adminActions').push({
                    type: 'createGiftCode',
                    adminId: UID,
                    adminEmail,
                    adminName,
                    data: {
                      code: code.toLowerCase(),
                      expires: dateFormat(expires),
                      playerLimit,
                      value,
                    },
                    date: Firebase.database.ServerValue.TIMESTAMP,
                  });
                }
                return resolve({ status: 'success' });
              }
              return resolve({ status: 'error', message: result.data.error });
            }).catch(err => resolve({ status: 'error', message: err.message }));
          }

          if (giftCode.code === code.toLowerCase() && giftCode.expires < curDateParsed) {
            const editGiftCodeFunction = Firebase.app().functions().httpsCallable('editGiftCode');

            return editGiftCodeFunction({
              code: code.toLowerCase(),
              expires: expiresParsed,
              playerLimit,
              value,
            }).then((result) => {
              if (result.data.status === 'success') {
                if (dataToEdit.code === oldData.code) {
                  const changesObj = {};

                  Object.keys(dataToEdit).map((key) => {
                    if (oldData[key] !== dataToEdit[key]) {
                      changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
                    }
                    return null;
                  });

                  let changedlog = '';

                  Object.keys(changesObj).map((key, index) => {
                    changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

                    if ((index + 1) !== Object.keys(changesObj).length) {
                      changedlog += ', ';
                    }
                    return null;
                  });

                  AdminLogsRef().child('adminLogs/adminActions').push({
                    type: 'editGiftCode',
                    adminId: UID,
                    adminEmail,
                    adminName,
                    giftCodeId: code.toLowerCase(),
                    data: {
                      ...dataToEdit,
                      changedlog,
                    },
                    date: Firebase.database.ServerValue.TIMESTAMP,
                  });
                } else {
                  AdminLogsRef().child('adminLogs/adminActions').push({
                    type: 'createGiftCode',
                    adminId: UID,
                    adminEmail,
                    adminName,
                    data: {
                      code: code.toLowerCase(),
                      expires: dateFormat(expires),
                      playerLimit,
                      value,
                    },
                    date: Firebase.database.ServerValue.TIMESTAMP,
                  });
                }
                return resolve({ status: 'success' });
              }
              return resolve({ status: 'error', message: result.data.error });
            }).catch(err => resolve({ status: 'error', message: err.message }));
          }

          return resolve({ status: 'error', message: 'codeExist' });
        }).catch(err => resolve({ status: 'error', error: err }));
    } else {
      const editGiftCodeFunction = Firebase.app().functions().httpsCallable('editGiftCode');
      editGiftCodeFunction({
        code: code.toLowerCase(),
        expires: expiresParsed,
        playerLimit,
        value,
      }).then((result) => {
        if (result.data.status === 'success') {
          if (dataToEdit.code === oldData.code) {
            const changesObj = {};
            Object.keys(dataToEdit).map((key) => {
              if (oldData[key] !== dataToEdit[key]) {
                changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
              }
              return null;
            });
            let changedlog = '';
            Object.keys(changesObj).map((key, index) => {
              changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

              if ((index + 1) !== Object.keys(changesObj).length) {
                changedlog += ', ';
              }
              return null;
            });
            AdminLogsRef().child('adminLogs/adminActions').push({
              type: 'editGiftCode',
              adminId: UID,
              adminEmail,
              adminName,
              giftCodeId: code.toLowerCase(),
              data: {
                ...dataToEdit,
                changedlog,
              },
              date: Firebase.database.ServerValue.TIMESTAMP,
            });
          } else {
            AdminLogsRef().child('adminLogs/adminActions').push({
              type: 'createGiftCode',
              adminId: UID,
              adminEmail,
              adminName,
              data: {
                code: code.toLowerCase(),
                expires: expiresParsed,
                playerLimit,
                value,
              },
              date: Firebase.database.ServerValue.TIMESTAMP,
            });
          }
          return resolve({ status: 'success' });
        }
        return resolve({ status: 'error', message: result.data.error });
      }).catch(err => resolve({ status: 'error', message: err.message }));
    }
  });
}

export function deleteGiftCode(code) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  if (!code) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingGiftCode })); }

  return () => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const deleteGiftCodeFunction = Firebase.app().functions().httpsCallable('deleteGiftCode');

      deleteGiftCodeFunction({
        code: code.toString(),
      }).then((result) => {
        if (result.data.status === 'success') {
          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'deleteGiftCode',
            adminId: UID,
            adminEmail,
            adminName,
            code,
            data: null,
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
          resolve({ status: 'success' });
        } else {
          resolve({ status: 'error', message: result.data.error });
        }
      })
        .catch(err => resolve({ status: 'error', message: err.message }));
    }
  }));
}

export function saveEmailTemplate(name, design, html) {
  console.log('saveEmailTemplate', { name, design, html });

  FirebaseRef.child('emailTemplates').push({
    name,
    design,
    html,
    date: Firebase.database.ServerValue.TIMESTAMP,
  });
}


export function getTemplateData() {
  return dispatch => new Promise((resolve) => {
    console.log('getTemplateData');

    FirebaseRef.child('eTemplates/verifyEmail').once('value', (snapshot) => {
      const verifyEmail = snapshot.val() || null;

      console.log('eTemplates/verifyEmail data', { verifyEmail });

      FirebaseRef.child('eTemplates/resetPassword').once('value', (snapshot2) => {
        const resetPassword = snapshot2.val() || null;

        console.log('eTemplates/resetPassword data', { resetPassword });


        FirebaseRef.child('eTemplates/resetEmail').once('value', (snapshot3) => {
          const resetEmail = snapshot3.val() || null;

          console.log('eTemplates/resetEmail data', { resetEmail });

          dispatch({
            type: 'TEMPLATE_DATA',
            data: { verifyEmail, resetEmail, resetPassword },
          });

          return resolve({ verifyEmail, resetEmail, resetPassword });
        }, (err) => { console.log(err); return resolve(); });
      }, (err) => { console.log(err); return resolve(); });
    }, (err) => { console.log(err); return resolve(); });
  });
}


export function updateTemplateData(type, data) {
  return () => new Promise((resolve) => {
    console.log('updateTemplateData', { type, data });

    if (type === 'verifyEmail') {
      FirebaseRef.child('eTemplates/verifyEmail').set(data);
    } else if (type === 'resetEmail') {
      FirebaseRef.child('eTemplates/resetEmail').set(data);
    } else if (type === 'resetPassword') {
      FirebaseRef.child('eTemplates/resetPassword').set(data);
    }
    return resolve();
  });
}

export function addNewEvent(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    startDate, endDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl, pageToOpen, showInBanner, devMode, bannerImageType, externalLink,
  } = data;

  const startParsedDate = Date.parse(startDate);
  const endParsedDate = Date.parse(endDate);

  if (startParsedDate > endParsedDate) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.wrongEventDate }));
  }
  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!imageUrl && !bannerImageType) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }

  return () => new Promise((resolve) => {
    const createEventFunction = Firebase.app().functions().httpsCallable('createEvent');

    createEventFunction({
      startDate: startParsedDate,
      endDate: endParsedDate,
      titleEN: enTitle,
      titleLV: lvTitle,
      titleRU: ruTitle,
      descriptionEN: enDescription,
      descriptionLV: lvDescription,
      descriptionRU: ruDescription,
      imageUrl,
      pageToOpen,
      showInBanner,
      devMode,
      bannerImageType,
      externalLink,
    }).then((result) => {
      if (result.data.status === 'success') {
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'createNewEvent',
          adminId: UID,
          adminEmail,
          adminName,
          data: {
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
            titleEN: enTitle,
            titleLV: lvTitle,
            titleRU: ruTitle,
            descriptionEN: enDescription,
            descriptionLV: lvDescription,
            descriptionRU: ruDescription,
            imageUrl,
            pageToOpen,
            showInBanner,
            devMode,
            externalLink,
          },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });

        return resolve({ status: 'success' });
      }
      return resolve({ status: 'error', message: result.data.error });
    }).catch(err => resolve({ status: 'error', message: err.message }));
  });
}

export function editSelectEvent(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    selectEventId, startDate, endDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl, pageToOpen, showInBanner, oldData, devMode, bannerImageType, externalLink,
  } = data;

  const startParsedDate = Date.parse(startDate);
  const endParsedDate = Date.parse(endDate);

  if (startParsedDate > endParsedDate) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.wrongEventDate }));
  }
  if (!selectEventId) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingData })); }
  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!imageUrl && !bannerImageType) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  console.log(selectEventId, 'devmode action');
  return () => new Promise((resolve) => {
    const editEventFunction = Firebase.app().functions().httpsCallable('editEvent');

    editEventFunction({
      eventId: selectEventId.toString(),
      startDate: startParsedDate,
      endDate: endParsedDate,
      titleEN: enTitle,
      titleLV: lvTitle,
      titleRU: ruTitle,
      descriptionEN: enDescription,
      descriptionLV: lvDescription,
      descriptionRU: ruDescription,
      imageUrl,
      pageToOpen,
      showInBanner,
      devMode,
      bannerImageType,
      externalLink,
    }).then((result) => {
      if (result.data.status === 'success') {
        if (startParsedDate !== Date.parse(oldData.startDate) || endParsedDate !== Date.parse(oldData.endDate) || enTitle !== oldData.titleEN || enDescription !== oldData.descriptionEN || pageToOpen !== oldData.pageToOpen || showInBanner !== oldData.showInBanner || devMode !== oldData.devMode) {
          const dataToEdit = {
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
            titleEN: enTitle,
            titleLV: lvTitle,
            titleRU: ruTitle,
            descriptionEN: enDescription,
            descriptionLV: lvDescription,
            descriptionRU: ruDescription,
            imageUrl,
            pageToOpen,
            showInBanner,
            devMode,
            externalLink,
          };
          const changesObj = {};
          Object.keys(dataToEdit).map((key) => {
            if (oldData[key] !== dataToEdit[key]) {
              changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
            }
            return null;
          });

          let changedlog = '';
          Object.keys(changesObj).map((key, index) => {
            changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

            if ((index + 1) !== Object.keys(changesObj).length) {
              changedlog += ', ';
            }
            return null;
          });

          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'editSelectEvent',
            adminId: UID,
            adminEmail,
            adminName,
            eventId: selectEventId,
            data: {
              ...dataToEdit,
              changedlog,
            },
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
        }

        return resolve({ status: 'success' });
      }
      return resolve({ status: 'error', message: result.data.error });
    }).catch(err => resolve({ status: 'error', message: err.message }));
  });
}

export function getAllEvents(filterType) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const filterDate = Date.parse(new Date());

  return dispatch => new Promise(resolve => FirebaseRef.child('events')
    .on('value', (snapshot) => {
      const allEvents = snapshot.val() || {};
      const array = [];
      Object.keys(allEvents).map((key) => {
        if (filterType === 'PAST') {
          if (allEvents[key].endDate < filterDate) {
            array.push({ key, ...allEvents[key] });
            // return null;
          }
        } else if (filterType === 'CURRENT') {
          if (allEvents[key].startDate <= filterDate && allEvents[key].endDate >= filterDate) {
            array.push({ key, ...allEvents[key] });
            // return null;
          }
        } else if (filterType === 'PLAN') {
          if (allEvents[key].startDate > filterDate) {
            array.push({ key, ...allEvents[key] });
            // return null;
          }
        } else {
          array.push({ key, ...allEvents[key] });
          // return null;
        }
        return null;
      });

      array.sort((a, b) => (b.endDate - a.endDate));
      return resolve(dispatch({ type: 'ADMIN_EVENTS_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function deleteEvent(selectEventId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const deleteEventFunction = Firebase.app().functions().httpsCallable('deleteEvent');

      deleteEventFunction({
        eventId: selectEventId.toString(),
      }).then((result) => {
        if (result.data.status === 'success') {
          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'deleteEvent',
            adminId: UID,
            adminEmail,
            adminName,
            eventId: selectEventId.toString(),
            data: null,
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
          resolve({ status: 'success' });
        } else {
          resolve({ status: 'error', message: result.data.error });
        }
      })
        .catch(err => resolve({ status: 'error', message: err.message }));
    }
  }));
}

export function getAllCampaigns(filterType) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const filterDate = Date.parse(new Date());

  return dispatch => new Promise(resolve => FirebaseRef.child('campaigns')
    .on('value', (snapshot) => {
      const allCampaigns = snapshot.val() || {};
      const array = [];
      Object.keys(allCampaigns).map((key) => {
        if (filterType === 'PAST') {
          if (allCampaigns[key].endDate < filterDate) {
            array.push({ key, ...allCampaigns[key], filterType: 'past' });
            // return null;
          }
        } else if (filterType === 'CURRENT') {
          if (allCampaigns[key].startDate <= filterDate && allCampaigns[key].endDate >= filterDate) {
            array.push({ key, ...allCampaigns[key], filterType: 'current' });
            // return null;
          }
        } else if (filterType === 'PLAN') {
          if (allCampaigns[key].startDate > filterDate) {
            array.push({ key, ...allCampaigns[key], filterType: 'plan' });
            // return null;
          }
        } else {
          const type = allCampaigns[key].endDate < filterDate ? 'past' : (allCampaigns[key].startDate <= filterDate && allCampaigns[key].endDate >= filterDate) ? 'current' : (allCampaigns[key].startDate > filterDate) ? 'plan' : ''
          array.push({ key, ...allCampaigns[key], filterType: type });
          // return null;
        }
        return null;
      });

      array.sort((a, b) => (b.endDate - a.endDate));
      return resolve(dispatch({ type: 'ADMIN_CAMPAIGNS_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function getCampaignHistory() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child('campaignHistory')
      .once('value', (snapshot) => {
        const campaignHistory = snapshot.val() || {};

        console.log(campaignHistory, "checkCampaignHistory")
        return resolve(dispatch({ type: 'ADMIN_CAMPAIGN_HISTORY_REPLACE', data: campaignHistory }));
    })
      .catch(err => resolve({ status: 'error', message: err.message }));
  });
}

export function getCampaignShowedHistory() {
  console.log('call getCampaignShowedHistory');
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child('campaignShowedHistory')
      .once('value', (snapshot) => {
        const campaignShowedHistory = snapshot.val() || {};

        console.log(campaignShowedHistory, "checkCampaignShowedHistory")
        return resolve(dispatch({ type: 'ADMIN_CAMPAIGN_SHOWED_HISTORY_REPLACE', data: campaignShowedHistory }));
    })
      .catch(err => resolve({ status: 'error', message: err.message }));
  });
}

export function getSingleCampaignShowedHistory(id) {
  console.log('call getSingleCampaignShowedHistory', id);
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return (dispatch) => new Promise((resolve) => {
    /* FirebaseRef.child(`campaignHistory/${id}`)
      .on('value', (snapshot) => {
        const campaignHistory = snapshot.val() || {};

        console.log(campaignHistory, "campaignHistory")
        dispatch({ type: 'ADMIN_CAMPAIGN_HISTORY_REPLACE_SINGLE', id, data: campaignHistory });
      }); */

    FirebaseRef.child(`campaignShowedHistory/${id}`)
      .once('value', (snapshot) => {
        const campaignShowedHistory = snapshot.val() || {};

        console.log(campaignShowedHistory, "checkCampaignShowedHistory")
        return resolve(dispatch({ type: 'ADMIN_CAMPAIGN_SHOWED_HISTORY_REPLACE_SINGLE', id, data: campaignShowedHistory }));
      });
  });
}

export function getSingleCampaignShowedHistory2(id) {
  console.log('call getSingleCampaignShowedHistory2', id);
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child(`campaignHistory/${id}`)
      .once('value', (snapshot) => {
        const campaignHistory = snapshot.val() || {};

        console.log(campaignHistory, "test data campaignHistory");
        dispatch({ type: 'ADMIN_CAMPAIGN_HISTORY_SINGLE', id, data: campaignHistory });

        FirebaseRef.child(`campaignHistory/${id}`)
          .on('child_added', (snapshot) => {
            const key = snapshot.key;
            if (!campaignHistory[key]) {
              const change = snapshot.val() || {};

              console.log(change, "test data campaignHistory")
              dispatch({ type: 'ADMIN_CAMPAIGN_HISTORY_SINGLE_ADDED', id, key, data: change });
            } else {
              // console.log('ignore campaignHistory', key);
            }
          });
      });

    FirebaseRef.child(`campaignShowedHistory/${id}`)
      .once('value', (snapshot) => {
        const campaignShowedHistory = snapshot.val() || {};

        console.log(campaignShowedHistory, "test data checkCampaignShowedHistory");

        FirebaseRef.child(`campaignShowedHistory/${id}`)
          .on('child_added', (snapshot) => {
            const key = snapshot.key;
            if (!campaignShowedHistory[key]) {
              const change = snapshot.val() || {};

              console.log(change, "test data checkCampaignShowedHistory");
              dispatch({ type: 'ADMIN_CAMPAIGN_SHOWED_HISTORY_SINGLE_ADDED', key, id, data: change });
            } else {
              // console.log('ignore checkCampaignShowedHistory', key);
            }
          });

  return resolve(dispatch({ type: 'ADMIN_CAMPAIGN_SHOWED_HISTORY_SINGLE', id, data: campaignShowedHistory }));
      });

    

    
  });
}

export function closeSingleCampaignShowedHistory(id) {
  console.log('call closeSingleCampaignShowedHistory', id);
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child(`campaignHistory/${id}`).off();
    FirebaseRef.child(`campaignShowedHistory/${id}`).off();
  });
}

export function deleteCampaign(selectCampaignId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise((resolve) => {
    FirebaseRef.child(`campaigns/${selectCampaignId}`).remove().then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'deleteCampaign',
        adminId: UID,
        adminEmail,
        adminName,
        campaignId: selectCampaignId.toString(),
        data: null,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      resolve({ status: 'success', message: 'success' });
    })
      .catch(err => resolve({ status: 'error', message: err.message }));
  });
}

export function editSelectCampaign(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    selectCampaignId,
    startDate,
    endDate,
    selectedLocations,
    selectedDesigns,
    selectedEnvs,
    selectedPlatforms,
    imageUrl,
    imageUrlEn,
    imageUrlRu,
    pageToOpen,
    oldData,
    adFormMeasure,
    externalBanner,
    overflowBackgroundColor,
    title,
  } = data;

  const startParsedDate = Date.parse(startDate);
  const endParsedDate = Date.parse(endDate);

  if (startParsedDate > endParsedDate) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.wrongEventDate }));
  }
  if (!selectCampaignId) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingData })); }
  if (!selectedLocations || !selectedLocations.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLocations })); }
  if (!selectedDesigns || !selectedDesigns.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.selectedDesigns })); }
  if (!selectedEnvs || !selectedEnvs.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.selectedEnvs })); }
  if (!selectedPlatforms || !selectedPlatforms.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.selectedPlatforms })); }
  if ((!externalBanner) && (!imageUrl || !imageUrlEn || !imageUrlRu)) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  // if (!title) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.title })); }

  return () => new Promise((resolve, reject) => {
    FirebaseRef.child(`campaigns/${selectCampaignId}`).update({
      startDate: startParsedDate,
      endDate: endParsedDate,
      selectedLocations,
      selectedPlatforms,
      selectedDesigns,
      selectedEnvs,
      imageUrl,
      imageUrlEn,
      imageUrlRu,
      externalBanner,
      overflowBackgroundColor: overflowBackgroundColor || null,
      pageToOpen,
      adFormMeasure,
      title,
    })
      .then(() => {
        if (startParsedDate !== Date.parse(oldData.startDate) || endParsedDate !== Date.parse(oldData.endDate) || !isEqual(selectedLocations, oldData.selectedLocations) || !isEqual(selectedDesigns, oldData.selectedDesigns) || !isEqual(selectedPlatforms, oldData.selectedPlatforms) || !isEqual(selectedEnvs, oldData.selectedEnvs) || pageToOpen !== oldData.pageToOpen || adFormMeasure !== oldData.adFormMeasure) {
          const dataToEdit = {
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
            selectedDesigns,
            selectedLocations,
            selectedPlatforms,
            selectedEnvs,
            imageUrl,
            imageUrlEn,
            imageUrlRu,
            externalBanner,
            overflowBackgroundColor: overflowBackgroundColor || null,
            title: title || null,
            pageToOpen,
            adFormMeasure,
          };
          const changesObj = {};
          Object.keys(dataToEdit).map((key) => {
            if (oldData[key] !== dataToEdit[key]) {
              changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
            }
            return null;
          });

          let changedlog = '';
          Object.keys(changesObj).map((key, index) => {
            changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

            if ((index + 1) !== Object.keys(changesObj).length) {
              changedlog += ', ';
            }
            return null;
          });

          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'editSelectCampaign',
            adminId: UID,
            adminEmail,
            adminName,
            campaignId: selectCampaignId,
            data: {
              ...dataToEdit,
              changedlog,
            },
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
        }
        return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.error });
      })
      .catch(() => reject({ status: constants.SERVER_STATUS_CODE.error, message: constants.SERVER_STATUS_CODE.error }));
  });
}

export function addNewCampaign(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('addNewCampaign', data);

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    startDate, endDate, selectedLocations, selectedPlatforms, selectedDesigns, selectedEnvs, imageUrl, imageUrlEn, imageUrlRu, pageToOpen, adFormMeasure, externalBanner, overflowBackgroundColor, title,
  } = data;

  const startParsedDate = Date.parse(startDate);
  const endParsedDate = Date.parse(endDate);

  if (startParsedDate > endParsedDate) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.wrongEventDate }));
  }

  if (!selectedLocations || !selectedLocations.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLocations })); }
  if (!selectedPlatforms || !selectedPlatforms.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPlatforms })); }
  if (!selectedDesigns || !selectedDesigns.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingDesigns })); }
  if (!selectedEnvs || !selectedEnvs.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingEnvs })); }

  console.log('addNewCampaign test 1');

  if (!externalBanner) {
    if (!imageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
    if (!imageUrlEn) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
    if (!imageUrlRu) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto }));
  }

  console.log('addNewCampaign test 2');

  return () => new Promise((resolve, reject) => {
    // return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });

    FirebaseRef.child('campaigns').push({
      startDate: startParsedDate,
      endDate: endParsedDate,
      selectedLocations,
      selectedPlatforms,
      selectedDesigns,
      selectedEnvs,
      imageUrl,
      imageUrlEn,
      imageUrlRu,
      externalBanner,
      overflowBackgroundColor: overflowBackgroundColor || null,
      pageToOpen,
      adFormMeasure,
      title: title || null,
    })
      .then(() => {
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'addNewCampaign',
          adminId: UID,
          adminEmail,
          adminName,
          data: { ...data },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });
        return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });
      })
      .catch(() => reject({ status: constants.SERVER_STATUS_CODE.error, message: constants.SERVER_STATUS_CODE.error }));
  });
}

export function addShopDiscount(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    startDate, endDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl, type, value, showInBanner, devMode, specialPrice, discountPrice, selectedDiscountPackage,
  } = data;

  const startParsedDate = Date.parse(startDate);
  const endParsedDate = Date.parse(endDate);

  if (startParsedDate > endParsedDate) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.wrongEventDate }));
  }
  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!imageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  if (type === constants.SPECIALPRICE) {
    map(constants.SHOP_PRODUCT_TYPES, (item) => {
      if (specialPrice[item.label] || type === constants.BONUS) {
        return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingValue }));
      }
      return null;
    });
  }
  if ((type === constants.DISCOUNTPRICE) && (!discountPrice && discountPrice !== 0)) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingValue }));
  }

  return () => new Promise((resolve) => {
    const createDiscountFunction = Firebase.app().functions().httpsCallable('createDiscount');

    createDiscountFunction({
      startDate: startParsedDate,
      endDate: endParsedDate,
      titleEN: enTitle,
      titleLV: lvTitle,
      titleRU: ruTitle,
      descriptionEN: enDescription,
      descriptionLV: lvDescription,
      descriptionRU: ruDescription,
      imageUrl,
      type,
      value: 10,
      showInBanner,
      devMode,
      specialPrice,
      discountPrice,
      selectedDiscountPackage,
    }).then((result) => {
      if (result.data.status === 'success') {
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'createDiscount',
          adminId: UID,
          adminEmail,
          adminName,
          data: {
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
            titleEN: enTitle,
            titleLV: lvTitle,
            titleRU: ruTitle,
            descriptionEN: enDescription,
            descriptionLV: lvDescription,
            descriptionRU: ruDescription,
            imageUrl,
            type,
            value,
            showInBanner,
            devMode,
            specialPrice,
            discountPrice,
            selectedDiscountPackage: selectedDiscountPackage || [],
          },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });

        return resolve({ status: 'success' });
      } else {
        return resolve({ status: 'error', message: result.data.error });
      }
    }).catch((err) => {
      resolve({ status: 'error', message: err.message });
    });
  });
}

export function editShopDiscount(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    selectEventId, startDate, endDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl, type, value, showInBanner, oldData, devMode, discountPrice, specialPrice, selectedDiscountPackage,
  } = data;

  const startParsedDate = Date.parse(startDate);
  const endParsedDate = Date.parse(endDate);

  if (startParsedDate > endParsedDate) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.wrongEventDate }));
  }
  if (!selectEventId) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingData })); }
  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!imageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }

  if (type === constants.SPECIALPRICE || type === constants.BONUS) {
    map(constants.SHOP_PRODUCT_TYPES, (item) => {
      if (specialPrice[item.label]) {
        return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingValue }));
      }
      return null;
    });
  }
  if ((type === constants.DISCOUNTPRICE) && (!discountPrice && discountPrice !== 0)) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingValue }));
  }

  return () => new Promise((resolve) => {
    const editEventFunction = Firebase.app().functions().httpsCallable('editDiscount');

    editEventFunction({
      discountId: selectEventId.toString(),
      startDate: startParsedDate,
      endDate: endParsedDate,
      titleEN: enTitle,
      titleLV: lvTitle,
      titleRU: ruTitle,
      descriptionEN: enDescription,
      descriptionLV: lvDescription,
      descriptionRU: ruDescription,
      imageUrl,
      type,
      value: 10,
      showInBanner,
      devMode,
      specialPrice,
      discountPrice,
      selectedDiscountPackage,
    }).then((result) => {
      if (result.data.status === 'success') {
        if (startParsedDate !== Date.parse(oldData.startDate) || endParsedDate !== Date.parse(oldData.endDate) || enTitle !== oldData.titleEN || enDescription !== oldData.descriptionEN || type !== oldData.type || value !== oldData.value || showInBanner !== oldData.showInBanner || devMode !== oldData.devMode) {
          const dataToEdit = {
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
            titleEN: enTitle,
            titleLV: lvTitle,
            titleRU: ruTitle,
            descriptionEN: enDescription,
            descriptionLV: lvDescription,
            descriptionRU: ruDescription,
            imageUrl,
            type,
            value,
            showInBanner,
            devMode,
            specialPrice: (type === constants.SPECIALPRICE || type === constants.BONUS) ? specialPrice : null,
            discountPrice,
            selectedDiscountPackage: selectedDiscountPackage || [],
          };
          const changesObj = {};
          Object.keys(dataToEdit).map((key) => {
            if (oldData[key] !== dataToEdit[key]) {
              changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
            }
            return null;
          });

          let changedlog = '';
          Object.keys(changesObj).map((key, index) => {
            changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

            if ((index + 1) !== Object.keys(changesObj).length) {
              changedlog += ', ';
            }
            return null;
          });

          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'editDiscount',
            adminId: UID,
            adminEmail,
            adminName,
            eventId: selectEventId,
            data: {
              ...dataToEdit,
              changedlog,
            },
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
        }
        return resolve({ status: 'success' });
      }
      return resolve({ status: 'error', message: result.data.error });
    }).catch(err => resolve({ status: 'error', message: err.message }));
  });
}

export function getAllShopDiscounts(filterType) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const filterDate = Date.parse(new Date());
  return dispatch => new Promise(resolve => FirebaseRef.child('discounts')
    .on('value', (snapshot) => {
      const allShopDiscount = snapshot.val() || {};
      const array = [];
      Object.keys(allShopDiscount).map((key) => {
        if (filterType === 'PAST') {
          if (allShopDiscount[key].endDate < filterDate) {
            array.push({ key, ...allShopDiscount[key] });
            // return null;
          }
        } else if (filterType === 'CURRENT') {
          if (allShopDiscount[key].startDate <= filterDate && allShopDiscount[key].endDate >= filterDate) {
            array.push({ key, ...allShopDiscount[key] });
            // return null;
          }
        } else if (filterType === 'PLAN') {
          if (allShopDiscount[key].startDate > filterDate) {
            array.push({ key, ...allShopDiscount[key] });
            // return null;
          }
        } else {
          array.push({ key, ...allShopDiscount[key] });
          // return null;
        }
        return null;
      });

      array.sort((a, b) => (b.endDate - a.endDate));
      return resolve(dispatch({ type: 'ADMIN_DISCOUNTS_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function deleteShopDiscount(selectEventId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const deleteEventFunction = Firebase.app().functions().httpsCallable('deleteDiscount');

      deleteEventFunction({
        discountId: selectEventId.toString(),
      }).then((result) => {
        if (result.data.status === 'success') {
          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'deleteDiscount',
            adminId: UID,
            adminEmail,
            adminName,
            discountId: selectEventId,
            data: null,
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
          resolve({ status: 'success' });
        } else {
          resolve({ status: 'error', message: result.data.error });
        }
      })
        .catch(err => resolve({ status: 'error', message: err.message }));
    }
  }));
}

export function editBan(uid, endDate, reason) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  const endDate2 = new Date(endDate);

  const endDateParsed = Date.parse(endDate2);

  if (!uid) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav id' })); }
  if (!reason) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav iemesla' })); }
  if (!endDateParsed || !(endDate2 instanceof Date)) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav beigu laika' })); }

  let blocked = false;
  if (endDate > Date.now()) {
    blocked = true;
  }

  return () => new Promise(resolve => FirebaseRef.child(`bans/${uid.toString()}`).update({
    endDate: endDateParsed,
    reason,
    blocked,
  }).then(() => {
    FirebaseRef.child(`users/${uid}`).update({
      banEndDate: endDateParsed,
      banReason: reason,
      blocked,
    });

    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'editBan',
      adminId: UID,
      adminEmail,
      adminName,
      userId: uid.toString(),
      data: {
        endDate: endDateParsed,
        reason,
        blocked,
      },
      date: Firebase.database.ServerValue.TIMESTAMP,
    });

    resolve({ status: 'success' });
  }).catch((err) => {
    resolve({ status: 'error', message: err });
  }));
}

const isdigit = (value) => {
  const val = !!Number(value);
  return val;
};

export function addTournament(data) {
  console.log('addTournament', data);
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve({ status: 'error', message: 'Nav UID' }));

  const {
    name,
    titleLV,
    titleEN,
    titleRU,
    entryFee,
    bonus,
    startDate,
    registerTime,
    winnerPercent,
    parasta,
    G,
    atra,
    lightning,
    unlimited,
    pro,
    maxPlayers,
    maza,
    rounds,
    roundLength,
    newRoundPause,
    bet,
    privateTournament,
    devTournament,
    password,
    sponsorImageUrl,
    prizeImageUrl,
    playerPerRoomLimit,
    bannerPrizeChecked,
    bannerPrizeImageUrl,
    bannerShowChecked,
    bannerStartDate,
    bannerEndDate, 
  } = data;

  const startDateParsed = Date.parse(startDate);

  if (privateTournament && (!password || (typeof password !== 'string') || (password && password.length !== 4) || (!isdigit(password)))) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Wrong password' })); }

  if (!titleLV || !titleEN || !titleRU) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing title' })); }
  if (!entryFee) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing entry fee' })); }
  if (!rounds) { return () => new Promise(resolve => resolve({ status: 'error', message: 'No starting tokens' })); }
  if (!roundLength) { return () => new Promise(resolve => resolve({ status: 'error', message: 'No round length' })); }
  if (!winnerPercent) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing winning percent' })); }
  if (!maxPlayers) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing maximum players' })); }

  if (Number.isNaN(Number(entryFee)) || parseInt(entryFee, 10) <= 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Entry fee has to be positive number' })); }
  if (Number.isNaN(Number(rounds)) || parseInt(rounds, 10) <= 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Rounds per tournament has to be positive number' })); }
  if (Number.isNaN(Number(roundLength)) || parseInt(roundLength, 10) < 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Round length has to be positive number' })); }
  if (!startDateParsed || !(startDate instanceof Date)) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing date' })); }
  if (Number.isNaN(Number(winnerPercent)) || parseInt(winnerPercent, 10) <= 0 || parseInt(winnerPercent, 10) > 100) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Winning percent has to be positive number' })); }

  let gameType = 'P';
  if (parasta) {
    gameType = 'P';
  } else if (G) {
    gameType = 'G';
  }

  let newState;

  if (startDateParsed && registerTime && startDateParsed - (1000 * 60 * registerTime) > Date.now()) {
    newState = 'pending';
  } else if (startDateParsed && registerTime && startDateParsed - (1000 * 60 * registerTime) < Date.now() && startDateParsed > Date.now()) {
    newState = 'registration';
  } else {
    return () => new Promise(resolve => resolve({ status: 'error', message: 'Start time cannot be in the past' }));
  }

  newState = 'pending';

  let roundLengthTime = 20;

  if (roundLength > 24) {
    roundLengthTime = 60;
  } else if (roundLength > 12) {
    roundLengthTime = 40;
  }

  const maxLengthTime = roundLengthTime * rounds;

  return () => new Promise(resolve => TournamentsRef().child('newTournaments').push({
    params: {
      name,
      bet: bet || '1:10',
      currentState: newState,
      currentRound: 1,
      entryFee: parseInt(entryFee, 10),
      bonus: parseInt(bonus, 10) || 0,
      rounds: parseInt(rounds, 10),
      roundLength: parseInt(roundLength, 10),
      startDate: startDateParsed,
      registerTime,
      winnerPercent,
      maxPlayers,
      maxLengthTime: maxLengthTime || null,
      gameType,
      fastGame: atra || null,
      lightningGame: lightning || null,
      unlimitedGame: unlimited || null,
      smallGame: maza,
      proGame: pro,
      newRoundPause,
      totalBank: 0,
      open: true,
      privateTournament: privateTournament || null,
      devTournament: devTournament || null,
      password: password || null,
      sponsorImg: sponsorImageUrl,
      prizeImg: prizeImageUrl,
      playerPerRoomLimit: playerPerRoomLimit || null,
    },
  }).then(async (res) => {
    const { key } = res;

    const promises = [];

    if (key) {
      if (bannerShowChecked) {
        const createEventFunction = Firebase.app().functions().httpsCallable('createEvent');

        console.log('call create banner', bannerShowChecked, bannerPrizeChecked, bannerPrizeImageUrl, bannerStartDate, bannerEndDate, startDateParsed)

        const bannerStartDateParsed = Date.parse(bannerStartDate);
        const bannerEndDateParsed = Date.parse(bannerEndDate);

        console.log("bannerPrizeChecked", bannerPrizeChecked, bannerPrizeImageUrl);

        await createEventFunction({
          startDate: bannerStartDateParsed,
          endDate: bannerEndDateParsed,
          tournamentStartDate: startDateParsed,
          name: titleLV,
          titleEN: titleEN,
          titleLV: titleLV,
          titleRU: titleRU,
          descriptionEN: titleEN,
          descriptionLV: titleLV,
          descriptionRU: titleRU,
          descriptionTournamentId: key,
          bannerPrizeChecked,
          imageUrl: (bannerPrizeChecked && bannerPrizeImageUrl) ? bannerPrizeImageUrl : null,
          pageToOpen: 'futureTournaments',
          showInBanner: bannerShowChecked,
          devMode: true, // devTournament
          bannerImageType: null,
          externalLink: null,
        });
      }

      const joinTournamentFunction = Firebase.app().functions('europe-west1').httpsCallable('joinTournament');
      const leaveTournamentFunction = Firebase.app().functions().httpsCallable('leaveTournament');
      const pauseTournamentFunction = Firebase.app().functions().httpsCallable('pauseTournament');
      const returnToTournamentFunction = Firebase.app().functions().httpsCallable('returnToTournament');

      joinTournamentFunction({ tournamentId: 'init' });
      leaveTournamentFunction({ tournamentId: 'init' });
      pauseTournamentFunction({ tournamentId: 'init' });
      returnToTournamentFunction({ tournamentId: 'init' });

      promises.push(TournamentsRef().child(`newTournamentsList/${key}`).set({
        currentState: newState,
        currentRound: 1,
        name: titleLV,
        bet: bet || '1:10',
        entryFee: parseInt(entryFee, 10),
        bonus: parseInt(bonus, 10) || 0,
        rounds: parseInt(rounds, 10),
        roundLength: parseInt(roundLength, 10),
        startDate: startDateParsed,
        registerTime,
        winnerPercent,
        sponsorImg: sponsorImageUrl || null,
        prizeImg: prizeImageUrl || null,
        maxPlayers,
        maxLengthTime: maxLengthTime || null,
        gameType,
        fastGame: atra || null,
        lightningGame: lightning || null,
        unlimitedGame: unlimited || null,
        smallGame: maza,
        proGame: pro,
        newRoundPause,
        totalBank: 0,
        open: true,
        privateTournament: privateTournament || null,
        devTournament: devTournament || null,
        playerPerRoomLimit: playerPerRoomLimit || null,
      }));

      promises.push(AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'createdTournament',
        adminId: UID,
        adminEmail,
        adminName,
        tournamentId: key,
        data: {
          name: titleLV,
          bet: bet || '1:10',
          currentState: newState,
          currentRound: 1,
          entryFee: parseInt(entryFee, 10),
          bonus: parseInt(bonus, 10) || 0,
          rounds: parseInt(rounds, 10),
          roundLength: parseInt(roundLength, 10),
          startDate: startDateParsed,
          registerTime,
          winnerPercent,
          sponsorImg: sponsorImageUrl || null,
          prizeImg: prizeImageUrl || null,
          maxPlayers,
          gameType,
          fastGame: atra || null,
          lightningGame: lightning || null,
          unlimitedGame: unlimited || null,
          smallGame: maza,
          proGame: pro,
          newRoundPause,
          totalBank: 0,
          open: true,
          privateTournament: privateTournament || null,
          devTournament: devTournament || null,
          password: password || null,
          playerPerRoomLimit: playerPerRoomLimit || null,
        },
        date: Firebase.database.ServerValue.TIMESTAMP,
      }));
    }

    Promise.allSettled(promises).then(() => {
      resolve({ status: 'success' });
    }).catch((err) => {
      resolve({ status: 'success' });
    });
  })
    .catch((err) => {
      resolve({ status: 'error', message: err });
    }));
}


export function editTournament({ data, oldData }) {
  console.log('editTournament', data, oldData);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

//  const {
//    prevState, tournamentToEdit, name, entryFee, bonus, startDate, registerTime, winnerPercent, parasta, G, atra, lightning, unlimited, pro, maxPlayers, maza, rounds, roundLength, newRoundPause, bet, privateTournament, devTournament, password, sponsorImageUrl, prizeImageUrl, playerPerRoomLimit,
//  } = data;

  const {
    prevState, tournamentToEdit, sponsorImageUrl, prizeImageUrl, titleLV, titleEN, titleRU, entryFee, bonus, startDate, registerTime, winnerPercent, parasta, G, atra, lightning, unlimited, pro, maxPlayers, maza, rounds, roundLength, newRoundPause, bet, password, privateTournament, devTournament, playerPerRoomLimit, bannerPrizeChecked, bannerPrizeImageUrl, bannerShowChecked, bannerStartDate, bannerEndDate,
  } = data;

  const startDateParsed = Date.parse(startDate);

  if (!tournamentToEdit) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Error editing tournament' })); }
  if (!titleLV || !titleEN || !titleRU) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing title' })); }
  if (!entryFee) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing entry fee' })); }
  if (!rounds) { return () => new Promise(resolve => resolve({ status: 'error', message: 'No starting tokens' })); }
  if (!roundLength) { return () => new Promise(resolve => resolve({ status: 'error', message: 'No round length' })); }
  if (!winnerPercent) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing winning percent' })); }
  if (!maxPlayers) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing maximum players' })); }

  if (Number.isNaN(Number(entryFee)) || parseInt(entryFee, 10) <= 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Entry fee has to be positive number' })); }
  if (Number.isNaN(Number(rounds)) || parseInt(rounds, 10) <= 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Rounds per tournament has to be positive number' })); }
  if (Number.isNaN(Number(roundLength)) || parseInt(roundLength, 10) < 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Round length has to be positive number' })); }
  if (!startDateParsed || !(startDate instanceof Date)) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing date' })); }
  if (Number.isNaN(Number(winnerPercent)) || parseInt(winnerPercent, 10) <= 0 || parseInt(winnerPercent, 10) > 100) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Winning percent has to be positive number' })); }

  let gameType = 'P';
  if (parasta) {
    gameType = 'P';
  } else if (G) {
    gameType = 'G';
  }

  let newState;

  if (startDateParsed && registerTime && startDateParsed - (1000 * 60 * registerTime) > Date.now()) {
    newState = 'pending';
  } else if (startDateParsed && registerTime && startDateParsed - (1000 * 60 * registerTime) < Date.now() && startDateParsed > Date.now()) {
    newState = 'registration';
  } else {
    return () => new Promise(resolve => resolve({ status: 'error', message: 'Start time cannot be in the past' }));
  }

  newState = 'pending';

  let roundLengthTime = 20;

  if (roundLength > 24) {
    roundLengthTime = 60;
  } else if (roundLength > 12) {
    roundLengthTime = 40;
  }

  const maxLengthTime = roundLengthTime * rounds;

  const dataToEdit = {
    currentState: newState,
    name: titleLV,
    bet: bet || '1:10',
    entryFee: parseInt(entryFee, 10),
    bonus: parseInt(bonus, 10) || 0,
    rounds: parseInt(rounds, 10),
    roundLength: parseInt(roundLength, 10),
    startDate: startDateParsed,
    registerTime,
    winnerPercent,
    sponsorImg: sponsorImageUrl || null,
    prizeImg: prizeImageUrl || null,
    maxPlayers,
    maxLengthTime: maxLengthTime || null,
    gameType,
    fastGame: atra || null,
    lightningGame: lightning || null,
    unlimitedGame: unlimited || null,
    smallGame: maza,
    proGame: pro,
    newRoundPause,
    open: true,
    privateTournament: privateTournament || null,
    devTournament: devTournament || null,
    playerPerRoomLimit: playerPerRoomLimit || null,
  };

  if (!privateTournament) {
    dataToEdit.password = null;
  }

  if (privateTournament && password) {
    dataToEdit.password = password;
  }

  console.log('UID 0', { UID, tournamentToEdit, dataToEdit });

  return () => new Promise(resolve => TournamentsRef().child(`newTournaments/${tournamentToEdit}/params`).update(dataToEdit).then(async () => {
    const promises = [];

    if (bannerShowChecked) {
      const createEventFunction = Firebase.app().functions().httpsCallable('createEvent');

      console.log('call create banner', bannerShowChecked, bannerPrizeChecked, bannerPrizeImageUrl, bannerStartDate, bannerEndDate, startDateParsed)

      const bannerStartDateParsed = Date.parse(bannerStartDate);
      const bannerEndDateParsed = Date.parse(bannerEndDate);

      console.log("bannerPrizeChecked", bannerPrizeChecked, bannerPrizeImageUrl);

      await createEventFunction({
        startDate: bannerStartDateParsed,
        endDate: bannerEndDateParsed,
        tournamentStartDate: startDateParsed,
        name: titleLV,
        titleEN: titleEN,
        titleLV: titleLV,
        titleRU: titleRU,
        descriptionEN: titleEN,
        descriptionLV: titleLV,
        descriptionRU: titleRU,
        descriptionTournamentId: tournamentToEdit,
        bannerPrizeChecked,
        imageUrl: (bannerPrizeChecked && bannerPrizeImageUrl) ? bannerPrizeImageUrl : null,
        pageToOpen: 'futureTournaments',
        showInBanner: bannerShowChecked,
        devMode: devTournament,
        bannerImageType: null,
        externalLink: null,
      });
    }

    if (newState === 'pending') {
      promises.push(TournamentsRef().child(`newTournaments/${tournamentToEdit}/players`).once('value', (snapshot) => {
        const players = snapshot.val();

        TournamentsRef().child(`newTournamentsList/${tournamentToEdit}/roomsResolved`).remove();

        TournamentsRef().child(`newTournaments/${tournamentToEdit}/params/roomsResolved`).remove();

        if (players) {
          Object.keys(players).map((key) => {
            FirebaseRef.child(`users/${key.toString()}/activeTournament`).remove();
            return null;
          });
        }
      }));
    } else if (newState === 'registration') {
      if (startDateParsed && startDateParsed - (1000 * 60 * 5) > Date.now()) {
        promises.push(TournamentsRef().child(`newTournaments/${tournamentToEdit}/players`).once('value', (snapshot) => {
          const players = snapshot.val();

          TournamentsRef().child(`newTournamentsList/${tournamentToEdit}/roomsResolved`).remove();

          TournamentsRef().child(`newTournaments/${tournamentToEdit}/params/roomsResolved`).remove();

          if (players) {
            Object.keys(players).map((key) => {
              FirebaseRef.child(`users/${key.toString()}/activeTournament`).remove();
              return null;
            });
          }
        }));
      }
    }

    if (newState && prevState === 'closed') {
      TournamentsRef().child(`newTournaments/${tournamentToEdit}/players`).remove();
      TournamentsRef().child(`newTournaments/${tournamentToEdit}/playersStaticData`).remove();
      TournamentsRef().child(`newTournaments/${tournamentToEdit}/params`).update({
        registeredPlayers: 0,
        totalBank: 0,
        currentRound: 1,
      });
      TournamentsRef().child(`newTournamentsList/${tournamentToEdit}`).update({
        registeredPlayers: 0,
        totalBank: 0,
        currentRound: 1,
      });
    }

    promises.push(TournamentsRef().child(`newTournamentsList/${tournamentToEdit}`).update({
      currentState: newState,
      name: titleLV,
      bet: bet || '1:10',
      entryFee: parseInt(entryFee, 10),
      bonus: parseInt(bonus, 10) || 0,
      rounds: parseInt(rounds, 10),
      roundLength: parseInt(roundLength, 10),
      startDate: startDateParsed,
      registerTime,
      winnerPercent,
      sponsorImg: sponsorImageUrl || null,
      prizeImg: prizeImageUrl || null,
      maxPlayers,
      maxLengthTime: maxLengthTime || null,
      gameType,
      fastGame: atra || null,
      lightningGame: lightning || null,
      unlimitedGame: unlimited || null,
      smallGame: maza,
      proGame: pro,
      newRoundPause,
      open: true,
      privateTournament: privateTournament || null,
      devTournament: devTournament || null,
      playerPerRoomLimit: playerPerRoomLimit || null,
    }));

    const changesObj = {};

    Object.keys(dataToEdit).map((key) => {
      if (oldData[key] !== dataToEdit[key] && key !== 'open') {
        changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
      }
      return null;
    });

    console.log('changesObj', { changesObj, length: Object.keys(changesObj).length });

    let changedlog = '';

    Object.keys(changesObj).map((key, index) => {
      changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

      console.log('index', { index, length: Object.keys(changesObj).length });

      if ((index + 1) !== Object.keys(changesObj).length) {
        changedlog += ', ';
      }
      return null;
    });

    console.log('changedlog', { changedlog });

    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'editedTournament',
      adminId: UID,
      adminEmail,
      adminName,
      tournamentId: tournamentToEdit,
      data: { ...dataToEdit, changedlog },
      date: Firebase.database.ServerValue.TIMESTAMP,
    });

    Promise.all(promises).then(() => {
      resolve({ status: 'success' });
    });
  })
    .catch((err) => {
      resolve({ status: 'error', message: err });
    }));
}

export function deleteTournament(tournamentId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      TournamentsRef().child(`newTournamentsList/${tournamentId}`).remove();

      TournamentsRef().child(`tournamentPlayerHistory/${tournamentId}`).remove();

      TournamentsRef().child(`newTournaments/${tournamentId}`).remove().then(() => {
        resolve({ status: 'success' });
      });

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'deletedTournament',
        adminId: UID,
        adminEmail,
        adminName,
        tournamentId,
        data: null,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });
    }
  }));
}

export function getRoomLog(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const promise1 = AdminLogsRef().child(`adminLogs/rooms/${roomId}`).once('value');
    const promise2 = RoomsRef.child(`roomsPoints/${roomId}/points`).once('value');

    return Promise.all([promise1, promise2]).then((promisesRes) => {
      let data;
      let roomsPoints;

      promisesRes.map((resp, index) => {
        if (index === 0) {
          data = resp.val();
        } else if (index === 1) {
          roomsPoints = resp.val();
        }
        return null;
      });

      return resolve(dispatch({
        type: 'ADMIN_ROOM_LOGS', data, roomId, roomsPoints,
      }));
    });
  }).catch((err) => { console.log(err.message); });
}

export function getUserGamesHistory(userId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const promises = [];
  const stats = {};

  return dispatch => new Promise((resolve) => {
    for (let i = 1; i < 30; i += 1) {
      const today = new Date();
      today.setDate(today.getDate() - i);

      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      let date = today.getDate();

      if (month < 10) {
        month = `0${month}`;
      }
      if (date < 10) {
        date = `0${date}`;
      }

      const dateString = `${year}-${month}-${date}`;

      promises.push(UserStatsRef().child(`userRoomsResults/${dateString}/${userId.toString()}`)
        .once('value', (snapshot) => {
          const history = snapshot.val() || {};

          stats[dateString] = history;
        }));
    }

    const today2 = new Date();

    const year2 = today2.getFullYear();
    let month2 = today2.getMonth() + 1;
    let date2 = today2.getDate();

    if (month2 < 10) {
      month2 = `0${month2}`;
    }
    if (date2 < 10) {
      date2 = `0${date2}`;
    }

    const dateString2 = `${year2}-${month2}-${date2}`;

    Promise.all(promises).then(() => {
      const promises2 = [];
      promises2.push(new Promise((resolve2) => {
        UserStatsRef().child(`todayRoomResults/playerRooms/${userId.toString()}`)
          .once('value', (snapshot) => {
            const history = snapshot.val() || {};
            let todayStats = {};
            const uid = userId;

            Object.keys(history).map((key) => {
              const { totalResults, totalBalChange, totalPointsChange } = history[key];
              if (totalResults && totalResults[uid]) {
                todayStats = {
                  ...todayStats,
                  wins: (todayStats.wins || 0) + ((totalResults[uid] && totalResults[uid].wins) ? totalResults[uid].wins : 0),
                  largeWins: (todayStats.largeWins || 0) + ((totalResults[uid] && totalResults[uid].largeWins) ? totalResults[uid].largeWins : 0),
                  smallWins: (todayStats.smallWins || 0) + ((totalResults[uid] && totalResults[uid].smallWins) ? totalResults[uid].smallWins : 0),
                  zoleWins: (todayStats.zoleWins || 0) + ((totalResults[uid] && totalResults[uid].zoleWins) ? totalResults[uid].zoleWins : 0),
                  loses: (todayStats.loses || 0) + ((totalResults[uid] && totalResults[uid].loses) ? totalResults[uid].loses : 0),
                  largeLoses: (todayStats.largeLoses || 0) + ((totalResults[uid] && totalResults[uid].largeLoses) ? totalResults[uid].largeLoses : 0),
                  smallLoses: (todayStats.smallLoses || 0) + ((totalResults[uid] && totalResults[uid].smallLoses) ? totalResults[uid].smallLoses : 0),
                  zoleLoses: (todayStats.zoleLoses || 0) + ((totalResults[uid] && totalResults[uid].zoleLoses) ? totalResults[uid].zoleLoses : 0),
                  galdinsLoses: (todayStats.galdinsLoses || 0) + ((totalResults[uid] && totalResults[uid].galdinsLoses) ? totalResults[uid].galdinsLoses : 0),
                  galdinsWins: (todayStats.galdinsWins || 0) + ((totalResults[uid] && totalResults[uid].galdinsWins) ? totalResults[uid].galdinsWins : 0),


                  roomsPlayed: (todayStats.roomsPlayed || 0) + 1,
                  roundsPlayed: (todayStats.roundsPlayed || 0) + ((totalResults[uid] && totalResults[uid].roundsPlayed) ? totalResults[uid].roundsPlayed : 0),

                  balChange: (todayStats.balChange || 0) + (totalBalChange[uid] || 0),
                  pointsChange: (todayStats.pointsChange || 0) + (totalPointsChange[uid] || 0),
                };
              } else {
                todayStats = {
                  ...todayStats,
                  balChange: ((todayStats && todayStats.balChange) ? todayStats.balChange : 0) + (totalBalChange[uid] || 0),
                  pointsChange: ((todayStats && todayStats.pointsChange) ? todayStats.pointsChange : 0) + (totalPointsChange[uid] || 0),
                };
              }

              return null;
            });

            stats[dateString2] = todayStats;

            return resolve2();
          });
      }));
      console.log('total result', stats);

      Promise.all(promises2).then(() => {
        resolve(dispatch({
          type: 'USER_GAMES_HISTORY',
          data: stats,
        }));
      });
    });
  });
}

export function getUserBalanceHistory(userId, startAt, endAt) {
  console.log('getUserBalanceHistory', { userId, startAt, endAt });
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('test 1');

  if (!UID) return () => new Promise(resolve => resolve());

  console.log('test 2');

  return dispatch => new Promise(resolve => UserStatsRef().child(`userBalHistory/${userId.toString()}`)
    .orderByChild('time')
    .startAt(startAt)
    .endAt(endAt)
    .once('value', (snapshot) => {
      const history = snapshot.val() || {};
      console.log('history', { history });

      resolve(dispatch({
        type: 'USER_BAL_HISTORY',
        data: history,
      }));
    })
    .catch((err) => { console.log(err); }));
}

export function getUserPointsHistory(userId, startAt, endAt) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => UserStatsRef().child(`userPointsHistory/${userId.toString()}`)
    .orderByChild('time')
    .startAt(startAt)
    .endAt(endAt)
    .once('value', (snapshot) => {
      const history = snapshot.val() || {};

      resolve(dispatch({
        type: 'USER_POINTS_HISTORY',
        data: history,
      }));
    }, ((err) => {
      console.log(err);
      return resolve(null);
    })));
}

export function setNextDealCards(roomId, cards) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  console.log('setNextDealCards', cards);

  return () => new Promise((resolve) => {
    const setNextDealCardsFunction = Firebase.app().functions().httpsCallable('setNextDealCards');

    setNextDealCardsFunction({
      roomId,
      cards,
      playerCards: {
        ...cards,
      },
    }).then((result) => {
      resolve(result);
    });
  });
}


export function setUserBal(roomId, userId, userPos, newBal) {
  if (Firebase === null || !roomId || !userId || !userPos || !newBal) return () => new Promise(resolve => resolve());

  if (Number.isNaN(newBal)) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    FirebaseRef.child(`users/${userId}`).update({
      bal: parseInt(newBal, 10),
    });

    resolve('success');
  });
}


export function setUserTournamentBal(roomId, tournamentId, userId, userPos, newBal) {
  if (Firebase === null || !roomId || !userId || !userPos || !newBal) return () => new Promise(resolve => resolve());

  if (Number.isNaN(newBal)) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    RoomsRef.child(`rooms/${roomId}/players/${userPos}`).update({
      bal: parseInt(newBal, 10),
    });

    RoomsRef.child(`rooms/${roomId}/playersList/${userPos}`).update({
      bal: parseInt(newBal, 10),
    });

    FirebaseRef.child(`tourPlayerData/${userId}/${tournamentId}`).update({
      bal: parseInt(newBal, 10),
    });

    FirebaseRef.child(`tourPlayers/${tournamentId}/${userId}`).update({
      bal: parseInt(newBal, 10),
    });

    resolve('success');
  });
}

export function disableTimer(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise(resolve => RoomsRef.child(`rooms/${roomId}/globalParams/disableTimer`).once('value', (disabledSnapshot) => {
    const disabled = disabledSnapshot.val() || false;
    RoomsRef.child(`rooms/${roomId}/globalParams`).update({
      disableTimer: !disabled,
    });

    resolve('success');
  }));
}

export function toggleAutomation(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise(resolve => RoomsRef.child(`rooms/${roomId}/globalParams/automated`).once('value', (disabledSnapshot) => {
    const disabled = disabledSnapshot.val() || false;
    RoomsRef.child(`rooms/${roomId}/globalParams`).update({
      automated: !disabled,
    });

    resolve('success');
  }));
}

export function getSmartLookEnabled() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('smartLookEnabled')
    .on('value', (snapshot) => {
      const smartLookEnabled = snapshot.val() || false;

      resolve(dispatch({
        type: 'SMARTLOOK_STATUS',
        data: smartLookEnabled,
      }));
    }));
}

export function changeSmartLook(value) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return () => new Promise((resolve) => {
    FirebaseRef.child('smartLookEnabled').set(value);

    return resolve();
  });
}


export function getAdmins() {
  if (Firebase === null) return () => new Promise(resolve => resolve());


  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('users').off();

    const promise1 = FirebaseRef.child('users').orderByChild('role').equalTo('admin').once('value');
    const promise2 = FirebaseRef.child('users').orderByChild('role').equalTo('tester').once('value');

    Promise.all([promise1, promise2]).then((promisesRes) => {
      let admins;
      let testers;

      promisesRes.map((resp, index) => {
        if (index === 0) {
          admins = resp.val();
        } else if (index === 1) {
          testers = resp.val();
        }
        return null;
      });

      const users = { ...admins, ...testers };

      return resolve(dispatch({ type: 'ADMIN_LIST_REPLACE', data: users }));
    });
  }).catch((err) => { console.log(err.message); });
}


export function getBotUsers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());


  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('users').off();

    console.log('users fetch getBotUsers');

    const promise1 = FirebaseRef.child('users').orderByChild('botUser').equalTo(true).once('value');

    Promise.all([promise1]).then((promisesRes) => {
      let bots;

      promisesRes.map((resp, index) => {
        if (index === 0) {
          bots = resp.val();
        }
        return null;
      });

      return resolve(dispatch({ type: 'BOTS_LIST_REPLACE', data: bots }));
    });
  }).catch((err) => { console.log(err.message); });
}

export function getAdminActionLog(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    AdminLogsRef().child('adminLogs/adminActions')
      .orderByChild('index')
      .startAt(start)
      .endAt(end)
      .once('value', async (snapshot) => {
        const adminActionLogs = snapshot.val() || {};
        console.log('Admin Actioin Logs', adminActionLogs);


        //>>>>>>>>>>>>>>>>>>
        const updatedLogs = {};

        const fetchAdminName = async (adminId) => {
          const nameSnapshot = await FirebaseRef.child(`users/${adminId}/name`).once('value');
          return nameSnapshot.val();
        };

        for (const [id, log] of Object.entries(adminActionLogs)) {
          try {
            const adminName = await fetchAdminName(log.adminId);
            updatedLogs[id] = {
              ...log,
              adminName
            };
          } catch (error) {
            updatedLogs[id] = {
              ...log,
              adminName: log.adminName
            };
            console.error(`Failed to fetch name for adminId ${log.adminId}: `, error);
          }
        }
        //<<<<<<<<<<<<<<<<<<<<<<

        return resolve(dispatch({
          type: 'ADMIN_ACTION_LOGS',
          data: Object.keys(updatedLogs).sort().reverse().reduce((obj, key2) => {
            obj[key2] = updatedLogs[key2];
            return obj;
          }, {}),
        }));
      }, (err) => { console.log(err); });
  })
    .catch((err) => { console.log(err); });
}

export function getAdminActionLogCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('statistics/adminActionsCount')
    .once('value', (snapshot) => {
      const logsCount = snapshot.val() || {};
      AdminLogsRef().child('adminLogs/adminActions')
        .orderByChild('index')
        .startAt(logsCount)
        .on('child_added', (snapshot2) => {
          const adminActionLogs = snapshot2.val() || {};
          const { key } = snapshot2;
          FirebaseRef.child('statistics/adminActionsCount')
            .once('value', (snapshot3) => {
              const logsCount2 = snapshot3.val() || {};
              dispatch({ type: 'ADMIN_ACTION_LOGS_COUNT', data: logsCount2 });
            });
          dispatch({
            type: 'ADMIN_ACTION_LOG',
            data: { log: adminActionLogs, key },
          });
        });
      console.log('logsCount!', logsCount);
      return resolve(dispatch({ type: 'ADMIN_ACTION_LOGS_COUNT', data: logsCount }));
    })).catch((err) => { console.log(err.message); });
}

export function updateRole(editUid, selectedRole) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!editUid || !selectedRole || selectedRole !== 'admin' || selectedRole !== 'tester' || selectedRole !== 'player') {
    return () => new Promise((resolve) => {
      FirebaseRef.child(`users/${editUid}/role`).set(selectedRole);

      AdminLogsRef().child(`admins/${editUid}/role`).set(selectedRole);
      LeaderboardRef.child(`admins/${editUid}/role`).set(selectedRole);
      RoomsRef.child(`admins/${editUid}/role`).set(selectedRole);
      UserStatsRef().child(`admins/${editUid}/role`).set(selectedRole);
      TournamentsRef().child(`admins/${editUid}/role`).set(selectedRole);

      return resolve();
    });
  }

  return () => new Promise(resolve => resolve());
}


export function getStatistic(id, time, roundStart, roundTo) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  if (roundStart === '') { roundStart = constants.STATISTICS_RANGE.start; }
  if (roundTo === '') { roundTo = constants.STATISTICS_RANGE.end; }
  let idStrings = '';

  idStrings = statisticsConst.statisticsTypes.filter(type => type.id === id)[0].link;
  // let endString;
  // let startString;
  // let endDate;
  // let startDate;
  // let startBalance;
  // let endBalance;

  const endString = new Date(Date.now()).toLocaleString('en-US', { timeZone: 'Europe/Riga' });
  const startString = new Date(constants.CALC_ACTIVATE_DATE_BY_TIME(time)).toLocaleString('en-US', { timeZone: 'Europe/Riga' });

  const yyyy = new Date(endString).getFullYear().toString();
  let MM = (new Date(endString).getMonth() + 1).toString();
  let dd = new Date(endString).getDate().toString();
  let hh = new Date(endString).getHours().toString();

  if (MM < 10) { MM = `0${MM}`; }
  if (dd < 10) { dd = `0${dd}`; }
  if (hh < 10) { hh = `0${hh}`; }

  const endDate = `${yyyy}-${MM}-${dd}-${hh}`;

  const yyyy2 = new Date(startString).getFullYear().toString();
  let MM2 = (new Date(startString).getMonth() + 1).toString();
  let dd2 = new Date(startString).getDate().toString();
  let hh2 = new Date(startString).getHours().toString();

  if (MM2 < 10) { MM2 = `0${MM2}`; }
  if (dd2 < 10) { dd2 = `0${dd2}`; }
  if (hh2 < 10) { hh2 = `0${hh2}`; }

  const startDate = `${yyyy2}-${MM2}-${dd2}-${hh2}`;

  return dispatch => new Promise((resolve) => {
    const promises = [];
    if (id === statisticsConst.LASTONLINEPLAYERS) {
      idStrings.map((idString) => {
        let endAt;
        let startAt;
        if (time === '1') {
          endAt = Date.now();
          startAt = constants.CALC_ACTIVATE_DATE_BY_TIME(7);
        } else if (time === '2') {
          endAt = constants.CALC_ACTIVATE_DATE_BY_TIME(7);
          startAt = constants.CALC_ACTIVATE_DATE_BY_TIME(30);
        } else if (time === '3') {
          endAt = constants.CALC_ACTIVATE_DATE_BY_TIME(30);
          startAt = constants.CALC_ACTIVATE_DATE_BY_TIME(90);
        } else if (time === '4') {
          endAt = constants.CALC_ACTIVATE_DATE_BY_TIME(90);
          startAt = constants.CALC_ACTIVATE_DATE_BY_TIME(180);
        } else if (time === '5') {
          endAt = constants.CALC_ACTIVATE_DATE_BY_TIME(180);
          startAt = constants.CALC_ACTIVATE_DATE_BY_TIME(360);
        } else if (time === '6') {
          endAt = constants.CALC_ACTIVATE_DATE_BY_TIME(360);
          startAt = '';
        }

        let firebaseProm;

        firebaseProm = UserStatsRef().child(`${idString}`)
          .orderByValue();

        if (time !== '6') {
          firebaseProm = firebaseProm.startAt(startAt).endAt(endAt);
        } else {
          firebaseProm = firebaseProm.endAt(endAt);
        }

        firebaseProm = firebaseProm.once('value', (snapshot) => {
          const statistics = snapshot.val() || {};
          const split = idString.split('/');
          dispatch({ type: 'ADMIN_STATISTICS_REPLACE', data: statistics, key: split[1] });
        });

        promises.push(firebaseProm);
        return null;
      });
    } else {
      idStrings.map((idString) => {
        let firebaseProm;
        if (time.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
          if (id === statisticsConst.USERSCOINANALYSIS) {
            if (idString === filter(statisticsConst.statisticsTypes, ['id', statisticsConst.ACTIVEPLAYERDAY])[0].link) {
              firebaseProm = AdminLogsRef().child(`statistics/${idString}`).orderByKey();
            } else {
              firebaseProm = AdminLogsRef().child(`statistics/${idString}`).orderByChild('date');
            }
          } else {
            firebaseProm = AdminLogsRef().child(`statistics/${idString}`).orderByKey();
          }
        } else if (id === statisticsConst.USERSCOINANALYSIS) {
          if (idString === filter(statisticsConst.statisticsTypes, ['id', statisticsConst.ACTIVEPLAYERDAY])[0].link) {
            firebaseProm = AdminLogsRef().child(`statistics/${idString}`).orderByKey()
          } else {
            if (idString === 'players/activePlayersDay') {
              firebaseProm = AdminLogsRef().child(`statistics/${idString}`).orderByKey();
              firebaseProm = firebaseProm.startAt(startDate).endAt(endDate);
            } else {
              firebaseProm = AdminLogsRef().child(`statistics/${idString}`)
                .orderByChild('date')
                .startAt(constants.CALC_ACTIVATE_DATE_BY_TIME(time))
                .endAt(Date.now());
            }
          }
        } else {
          firebaseProm = AdminLogsRef().child(`statistics/${idString}`).orderByKey();

          if (idString.slice(-7) !== statisticsConst.STATISTICS_FILTER_TYPE.history) {
            firebaseProm = firebaseProm.startAt(startDate).endAt(endDate);
          }
        }

        firebaseProm = firebaseProm.once('value', (snapshot) => {
          const statistics = snapshot.val() || {};
          const split = idString.split('/');

          if (idString.slice(-12) === statisticsConst.STATISTICS_FILTER_TYPE.roundsPlayed) {
            Object.keys(statistics).map((key) => {
              let filtered = statistics[key];
              filtered = Object.keys(filtered).filter(key1 => (parseInt(key1, 10) >= parseInt(roundStart, 10) && parseInt(key1, 10) <= parseInt(roundTo, 10)))
                .reduce((obj, key2) => {
                  obj[key2] = filtered[key2];
                  return obj;
                }, {});
              statistics[key] = filtered;
              return null;
            });
          }
          console.log(time, "statistics test", statistics, idString, split);

          dispatch({ type: 'ADMIN_STATISTICS_REPLACE', data: statistics, key: split[split.length - 1] });
        });

        promises.push(firebaseProm);
        return null;
      });
    }

    Promise.all(promises).then(() => resolve());
  }).catch((err) => { console.log(err.message); });
}


export function setMobileVersion(version, dev) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!version) {
    return () => new Promise(resolve => resolve());
  }

  if (dev) {
    return () => new Promise((resolve) => {
      FirebaseRef.child('settings/mobileVersionDev').set(version);

      return resolve();
    });
  }
  return () => new Promise((resolve) => {
    FirebaseRef.child('settings/mobileVersion').set(version);

    return resolve();
  });
}

export function getMobileVersion(dev) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (dev) {
    return () => new Promise(resolve => FirebaseRef.child('settings/mobileVersionDev').once('value', (snapshot) => {
      const mobileVersion = snapshot.val() || null;

      return resolve({ data: mobileVersion });
    }));
  }
  return () => new Promise(resolve => FirebaseRef.child('settings/mobileVersion').once('value', (snapshot) => {
    const mobileVersion = snapshot.val() || null;

    return resolve({ data: mobileVersion });
  }));
}


export function setTreasureChestEnabled(value) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    if (value) {
      FirebaseRef.child('settings/treasureChestEnabled').set(true);
    } else {
      FirebaseRef.child('settings/treasureChestEnabled').remove();
    }

    return resolve();
  });
}

export function getTreasureChestEnabled() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return () => new Promise(resolve => FirebaseRef.child('settings/treasureChestEnabled').once('value', (snapshot) => {
    const treasureChestEnabled = snapshot.val() || null;

    return resolve({ data: treasureChestEnabled });
  }));
}


export function getUserAchievements(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    let userAchievements;
    let claimedAchievements;
    let claimedAchievementsHistory;

    let gamesPlayed;
    let gamesWon;

    const promises = [];

    promises.push(new Promise(resolve2 => FirebaseRef.child(`users/${uid}/gPlayed`)
      .once('value', (snapshot) => {
        gamesPlayed = snapshot.val() || 0;

        return resolve2();
      }, (err) => {
        console.log(err);
        gamesPlayed = 0;
        return resolve2();
      })));

    promises.push(new Promise(resolve2 => FirebaseRef.child(`users/${uid}/gWon`)
      .once('value', (snapshot) => {
        gamesWon = snapshot.val() || 0;

        return resolve2();
      }, (err) => {
        console.log(err);
        gamesWon = 0;
        return resolve2();
      })));

    promises.push(new Promise(resolve2 => FirebaseRef.child(`userAchievements/${uid}`)
      .once('value', (snapshot) => {
        userAchievements = snapshot.val() || {};

        return resolve2();
      }, (err) => {
        console.log(err);
        userAchievements = {};
        return resolve2();
      })));

    promises.push(new Promise(resolve2 => FirebaseRef.child(`claimedAchievements/${uid}`)
      .once('value', (snapshot) => {
        claimedAchievements = snapshot.val() || {};

        return resolve2();
      }, (err) => {
        console.log(err);
        claimedAchievements = {};
        return resolve2();
      })));

    promises.push(new Promise(resolve2 => FirebaseRef.child(`claimedAchievementsHistory/${uid}`)
      .once('value', (snapshot) => {
        claimedAchievementsHistory = snapshot.val() || {};

        return resolve2();
      }, (err) => {
        console.log(err);
        claimedAchievementsHistory = {};
        return resolve2();
      })));

    return Promise.all(promises).then(() => resolve(dispatch({
      type: 'USER_ACHIEVEMENTS',
      data: {
        userAchievements,
        claimedAchievements,
        claimedAchievementsHistory,
        gamesPlayed,
        gamesWon,
      },
    })));
  });
}

export function getUserSettings(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    let usersSettings;

    const promises = [];

    promises.push(new Promise((resolve2) => FirebaseRef.child(`usersSettings/${uid}`)
      .once('value', (snapshot) => {
        usersSettings = snapshot.val() || null;

        return resolve2();
      }, (err) => {
        usersSettings = null;
        return resolve2();
      })));

    return Promise.all(promises).then(() => resolve(usersSettings));
  });
}

export function getAutoPlayOtherUser(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    let disabledAutoPlay;

    const promises = [];

    promises.push(new Promise((resolve2) => FirebaseRef.child(`users/${uid}/disableAutoPlay`)
      .once('value', (snapshot) => {
        disabledAutoPlay = snapshot.val() || false;

        return resolve2();
      }, (err) => {
        disabledAutoPlay = false;
        return resolve2();
      })));

    return Promise.all(promises).then(() => resolve(disabledAutoPlay));
  });
}

export function updateProfile(formData) {
  const {
    // userId,
    firstName,
    lastName,
    photo,
  } = formData;

  return () => new Promise(async (resolve, reject) => {
    // Are they a user?
    const UID = Firebase.auth().userId;

    if (!UID) {
      return reject({
        // message: errorMessages.missingFirstName
      });
    }

    // Validation rules
    if (!firstName) {
      return reject({
        // message: errorMessages.missingFirstName
      });
    }
    if (!lastName) {
      return reject({
        // message: errorMessages.missingLastName
      });
    }

    const updateProfileFunction = Firebase.app()
      .functions()
      .httpsCallable('updateProfile');

    return updateProfileFunction({
      firstName,
      lastName,
      photo,
    })
      .then(() => {
        // getUserData(dispatch);
        // console.log('###############', res);
        // if (res.data.message == 'maxNameChangeLimit' && res.data.status == 'error') {
        //   return resolve({ status: 'error', message: 'maxNameChangeLimit' });
        // }
        // return resolve({ status: 'success' });
      })
      .catch(() => reject({
        // message: errorMessages.errorUpdating
      }));
  }).catch((err) => {
    console.log(err);
    throw err;
  });
}

export function fetchFeedbackHistory(selectedId, design, platform) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise(resolve => FirebaseRef.child(`feedbackHistory/${selectedId}`)
    .on('value', (snapshot) => {
      const allFeedbackHistory = snapshot.val() || {};
      const array = [];
      let totalBal = 0;
      forEach(allFeedbackHistory, (item, key) => {
        if (item.design === design.value && item.platform === platform.value && parseFloat(item.rating) > 0) {
          array.push({ key, selectedId, ...item });
          totalBal += parseFloat(item.rating) > 0 ? parseFloat(item.rating) : 0;
        }
      });
      array.sort((a, b) => (b.lastFeedback - a.lastFeedback));
      return resolve(dispatch({ type: 'ADMIN_FEEDBACKS_HISTORY_REPLACE', data: array, totalRating: totalBal }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllFeedbacks() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise(resolve => FirebaseRef.child(`feedbackHistory`)
    .on('value', (snapshot) => {
      const allFeedbackHistory = snapshot.val() || {};
      const array = [];
      let totalBal = 0;
      forEach(allFeedbackHistory, (items, key) => {
        forEach(items, (item, idx) => {
          if (item.rating && parseFloat(item.rating) > 0) {
            array.push({ key, feedbackId: idx, ...item });
            totalBal += parseFloat(item.rating) > 0 ? parseFloat(item.rating) : 0;
          }
        });
      });
      array.sort((a, b) => (b.lastFeedback - a.lastFeedback));
      return resolve(dispatch({ type: 'ADMIN_FEEDBACKS_REPLACE', data: array, totalRating: totalBal }));
    })).catch((err) => { console.log(err.message); });
}

export function getFeedbacks() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise(resolve => {
    dispatch({ type: 'ADMIN_MULTIPLE_FEEDBACKS_REPLACE', data: {}, feedbackLoading: true });
    FirebaseRef.child('feedback')
    .on('value', (snapshot) => {
      const allFeedbacks = snapshot.val() || {};
      console.log(allFeedbacks, "check===========")
      return resolve(dispatch({ type: 'ADMIN_MULTIPLE_FEEDBACKS_REPLACE', data: allFeedbacks, feedbackLoading: false }));
    })
  }).catch((err) => { console.log(err.message); });
}

export function getFeedbacksResults() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise(resolve => {
    dispatch({ type: 'ADMIN_MULTIPLE_FEEDBACKS_RESULTS_REPLACE', data: {}, feedbackResultsLoading: true });

    FirebaseRef.child('feedbackResults')
    .on('value', (snapshot) => {
      const allFeedbackResults = snapshot.val() || {};
      return resolve(dispatch({ type: 'ADMIN_MULTIPLE_FEEDBACKS_RESULTS_REPLACE', data: allFeedbackResults, feedbackResultsLoading: false }));
    })
  }).catch((err) => { console.log(err.message); });
}


export function getEditorData() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise(resolve => FirebaseRef.child('versionHistory')
    .orderByChild('versionDate')
    .once('value', (snapshot) => {
      const data = snapshot.val() || null;

      const array = [];

      Object.keys(data).map((key) => {
        array.push({ key, ...data[key] });
        return null;
      });

      array.sort((a, b) => (b.versionDate - a.versionDate));

      return resolve(dispatch({ type: 'ADMIN_VERSION_REPLACE', data: array }));
    }).catch((err) => {
      console.log(err);
      return resolve(null);
    }));
}

export function updateEditorData(selectId, data, versionDate) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());
  if (!data || !versionDate) return () => new Promise(resolve => resolve());
  const versionFormatDate = Date.parse(versionDate);
  return () => new Promise((resolve, reject) => {
    FirebaseRef.child(`versionHistory/${selectId}`).update({
      versionDate: versionFormatDate,
      [constants.sendLanguage.lv]: data.lv,
      [constants.sendLanguage.en]: data.en,
      [constants.sendLanguage.ru]: data.ru,
    })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch((err) => { console.log(err); return reject({ status: constants.SERVER_STATUS_CODE.error }); });
  });
}

export function addEditerData(data, versionDate) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  if (!data || !versionDate) return () => new Promise(resolve => resolve());
  const versionFormatDate = Date.parse(versionDate);
  return () => new Promise((resolve, reject) => {
    console.log('update path', { data, versionDate });
    //  return resolve();
    // const versionFormatDate = dateFormatMoment(versionDate, constants.FORMAT_DATE_TYPE.pointsfullDate);
    FirebaseRef.child('versionHistory').push({
      versionDate: versionFormatDate,
      [constants.sendLanguage.lv]: data.lv,
      [constants.sendLanguage.en]: data.en,
      [constants.sendLanguage.ru]: data.ru,
    })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch((err) => { console.log(err); return reject({ status: constants.SERVER_STATUS_CODE.error }); });
  });
}

export function deleteEditor(selectId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve, reject) => {
    FirebaseRef.child(`versionHistory/${selectId}`)
      .remove()
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch((err) => { console.log(err); return reject({ status: constants.SERVER_STATUS_CODE.error }); });
  });
}

export function blogImageFileUpload(fileUpload) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (fileUpload !== '') {
    return () => new Promise((resolve) => {
      const uploadTask = FirebaseStorage.ref(`/blogImages/${(Date.now().toString())}`)
        .put(fileUpload);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            resolve({ status: 'success', url: downloadURL });
          });
        });
    });
  }
  return () => new Promise(resolve => resolve());
}

export function addBlogEditerData(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const {
    blogDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl,
  } = data;

  const blogFormatDate = Date.parse(blogDate);

  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!imageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }

  return () => new Promise((resolve, reject) => {
    FirebaseRef.child('blogs').push({
      blogDate: blogFormatDate,
      titleLV: lvTitle,
      titleEN: enTitle,
      titleRU: ruTitle,
      descriptionLV: lvDescription,
      descriptionEN: enDescription,
      descriptionRU: ruDescription,
      imageUrl,
    })
      .then(() => {
        return AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'addBlog',
          adminId: UID,
          adminEmail,
          adminName,
          date: Firebase.database.ServerValue.TIMESTAMP,
          data: { title: enTitle },
        });
      })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch((err) => {
        console.log(err);
        return reject({ status: constants.SERVER_STATUS_CODE.error });
      });
  });
}

export function getBlogEditorData() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise(resolve => FirebaseRef.child('blogs')
    .orderByKey()
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};
      const array = [];

      Object.keys(data).map((key) => {
        array.push({ key, ...data[key] });
        return null;
      });

      array.sort((a, b) => (b.blogDate - a.blogDate));

      return resolve(dispatch({ type: 'ADMIN_BLOGS_REPLACE', data: array }));
    }).catch((err) => {
      console.log(err);
      return resolve(null);
    }));
}

export function updateBlogEditorData(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return () => new Promise(resolve => resolve());
  const {
    selectId, blogDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl,
  } = data;

  if (!selectId) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingData })); }
  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!imageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }

  const blogFormatDate = Date.parse(blogDate);
  return () => new Promise((resolve, reject) => {
    FirebaseRef.child(`blogs/${selectId}`).update({
      blogDate: blogFormatDate,
      titleLV: lvTitle,
      titleEN: enTitle,
      titleRU: ruTitle,
      descriptionLV: lvDescription,
      descriptionEN: enDescription,
      descriptionRU: ruDescription,
      imageUrl,
    })
      .then(() => {
        return AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'updateBlog',
          adminId: UID,
          adminEmail,
          adminName,
          date: Firebase.database.ServerValue.TIMESTAMP,
          data: { id: selectId },
        });
      })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch((err) => {
        console.log(err);
        reject({ status: constants.SERVER_STATUS_CODE.error });
      });
  });
}

export function deleteBlogEditor(selectId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise((resolve, reject) => {
    FirebaseRef.child(`blogs/${selectId}`)
      .remove()
      .then(() => {
        return AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'deleteBlog',
          adminId: UID,
          adminEmail,
          adminName,
          date: Firebase.database.ServerValue.TIMESTAMP,
          data: { id: selectId },
        });
      })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch((err) => { 
        console.log(err); 
        return reject({ status: constants.SERVER_STATUS_CODE.error }); 
      });
  });
}

export function deleteUserFeedback(userId, selectId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve, reject) => {
    FirebaseRef.child(`feedback/${userId}/${selectId}`)
      .remove()
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch((err) => { console.log(err); return reject({ status: constants.SERVER_STATUS_CODE.error }); });
  });
}

export function checkFeedbackTime() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('settings/feedback')
    .once('value', (snapshot) => {
      const feedbackSetting = snapshot.val() || {};

      console.log({ feedbackSetting });
      let feedbackStartTime = false;
      const nowDate = Date.parse(new Date());
      if (feedbackSetting && feedbackSetting.feedbackStart) {
        if (feedbackSetting.feedbackStartTime) {
          feedbackStartTime = nowDate > constants.CALC_ACTIVE_FEEDBACK_TIME(feedbackSetting.feedbackStartTime, constants.FEEDBACK_SHOW_TIME_RANGE.end);
        } else {
          feedbackStartTime = true;
        }
      } else {
        feedbackStartTime = true;
      }

      const previousFeedbackTime = feedbackSetting.feedbackStartTime || 0;
      return resolve(dispatch({ type: 'START_FEEDBACK_TIME', data: feedbackStartTime, previousFeedbackTime }));
    }).catch((err) => {
      console.log(err);
      return resolve(null);
    }));
}

/*
function generateRandom(min = 0, max = 100) {
  const difference = max - min;
  let rand = Math.random();
  rand = Math.floor(rand * difference);
  rand += min;
  return rand;
} */

export function startFeedback() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());
  return () => new Promise((resolve, reject) => {
    FirebaseRef.child('settings/feedback').set({
      feedbackStartTime: Date.parse(new Date()),
      feedbackStart: true,
      devModal: true,
    })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success })).catch((err) => {
        console.log(err);
        return reject({ status: constants.SERVER_STATUS_CODE.error });
      });
  });
}

export function stopFeedback() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());
  return () => new Promise((resolve, reject) => {
    FirebaseRef.child('settings/feedback').set({
      feedbackStart: false,
    })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success })).catch((err) => {
        console.log(err);
        return reject({ status: constants.SERVER_STATUS_CODE.error });
      });
  });
}

export function getSuspicisousTransactionsPlayers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const endString = Date.now();
  const startString = constants.CALC_ACTIVATE_DATE_BY_TIME(constants.WEEKLY_VALUE.twoWeek);
  return dispatch => new Promise(resolve => FirebaseRef.child('completedPayments')
    .orderByChild('dateInitiated')
    .startAt(startString)
    .endAt(endString)
    .once('value', async (snapshot) => {
      const suspiciousTransactions = snapshot.val() || {};

      const dateTransactions = {};
      const dateSuspiciousPlayers = {};

      map(suspiciousTransactions, async (trans, key) => {
        const transDate = moment(new Date(trans.dateInitiated).toLocaleString(statisticsConst.STATISTICS_ADMIN_TIMEZONE.timelocal, { timeZone: statisticsConst.STATISTICS_ADMIN_TIMEZONE.timezone })).format(constants.FORMAT_DATE_TYPE.fullDayMonth);
        dateTransactions[key] = {
          ...trans,
          formatDate: transDate,
        };
      });

      if (dateTransactions) {
        await Promise.all(map(dateTransactions, async (item, key) => {
          const userFirebaseProm = await FirebaseRef.child(`users/${item.userUid}`).once('value');
          const user = userFirebaseProm.val();
          if (user) {
            if (!find(dateSuspiciousPlayers, findItem => findItem.formatDate === item.formatDate && findItem.userUid === item.userUid)) {
              const filterData = filter(dateTransactions, filterItem => filterItem.formatDate === item.formatDate && filterItem.userUid === item.userUid);
              if (size(filterData) >= 5) {
                const userName = user.name || '--';
                dateSuspiciousPlayers[key] = {
                  formatDate: item.formatDate,
                  count: size(filterData),
                  userUid: item.userUid,
                  name: userName,
                  dateInitiated: item.dateInitiated,
                };
              }
            }
          }
        }));
      }
      return resolve(dispatch({ type: 'ADMIN_SUSPICISOUS_TRANSACTIONS_REPLACE', data: dateSuspiciousPlayers, allSuspiciousTransactions: dateTransactions }));
    })).catch((err) => { console.log(err.message); });
}

export function getSuspiciousFoldPlayers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const endString = Date.now();
  const startString = constants.CALC_ACTIVATE_DATE_BY_TIME(constants.WEEKLY_VALUE.twoWeek);
  return dispatch => new Promise(resolve => AdminLogsRef().child('roomsTogether/quitRounds')
    .orderByChild('dateAdded')
    .startAt(startString)
    .endAt(endString)
    .once('value', async (snapshot) => {
      const data = snapshot.val() || {};
      const array = [];

      await Promise.all(Object.keys(data).map(async (key) => {
        const globalRoomData = await AdminLogsRef().child(`roomsTogether/rooms/${data[key].roomId}`).once('value');
        const userFirebaseProm = await FirebaseRef.child(`users/${data[key].uid}`).once('value');
        const user = userFirebaseProm.val();
        const roomData = globalRoomData.val();

        const checkUser = user && user.name ? user.name : '--';
        let checkRoom = false;
        if (roomData && roomData.date) {
          checkRoom = true;
        }

        if (data[key].count > 5) {
          array.push({ key, ...data[key], supiciousFold: checkRoom, userName: checkUser });
        }
      }));

      await array.sort((a, b) => (b.dateAdded - a.dateAdded));

      return resolve(dispatch({ type: 'SUSPICIOUS_FOLD_PLAYERS', data: filter(array, item => item.supiciousFold) }));
    }, (err) => { console.log(err); })).catch((err) => { console.log(err); });
}

export function getGlobalRoomData(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    AdminLogsRef().child(`roomsTogether/rooms/${roomId}`).once('value', (snapShot) => {
      const data = snapShot.val() || {};
      if (data) {
        AdminLogsRef().child(`roomsTogether/playerRooms/${data.player1}/${data.player2}`)
          .orderByChild('roomId')
          .equalTo(roomId)
          .once('value', (snapshot) => {
            const rooms = snapshot.val() || {};
            return resolve(dispatch({ type: 'ADMIN_ROOM_GLOBAL_PARAMS', data: rooms }));
          });
      }
    });
  });
}

export function editUserDob(editUserId, dob) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (dob) {
    if (Date.parse(dob) < Date.parse(constants.LIMIT_DATE_RANGE.minDate) || Date.parse(dob) > Date.parse(constants.LIMIT_DATE_RANGE.maxDate)) {
      return () => new Promise(resolve => resolve({ status: constants.SERVER_STATUS_CODE.error, message: errorMessages.wrongDate }));
    }
  }

  const birthday = dob ? Date.parse(dob) : null;
  return () => new Promise((resolve) => {
    FirebaseRef.child(`users/${editUserId.toString()}`).update({
      birthday,
    }).then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'editDob',
        adminId: UID,
        adminEmail,
        adminName,
        userId: editUserId.toString(),
        birthday,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch((err) => {
      console.log(err);
      return resolve({ status: constants.SERVER_STATUS_ERROR.error });
    });
  });
}

export function getUserLastIp(userUid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  return dispatch => new Promise(resolve => FirebaseRef.child(`usersLastIp/${userUid}`)
    .once('value', (snapshot) => {
      const lastIpData = snapshot.val() ? { userUid: userUid, IP: snapshot.val() } : {};

      return resolve(dispatch({ type: 'ADMIN_LAST_IP_REPLACE', data: lastIpData }));
    })).catch((err) => { console.log(err.message); });
}

export function getNotifications() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  return dispatch => new Promise(resolve => FirebaseRef.child('notifications')
    .on('value', (snapshot) => {
      const allNotifications = snapshot.val() || {};

      console.log(allNotifications, 'all notifications');

      return resolve(dispatch({ type: 'ADMIN_NOTIFICATIONS_REPLACE', data: allNotifications }));
    })).catch((err) => { console.log(err.message); });
}

export function addNotification(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const {
    lvTitle, enTitle, ruTitle, lvText, enText, ruText, notifyIdentifier, notificationDate, notificationType, showNotification,
  } = data;
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise((resolve, reject) => {
    FirebaseRef.child('notifications').push({
      lvTitle,
      enTitle,
      ruTitle,
      lvText,
      enText,
      ruText,
      notifyIdentifier,
      notificationDate: Date.parse(notificationDate),
      notificationType,
      showNotification,
    })
      .then(() => {
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'addNotification',
          adminId: UID,
          adminEmail,
          adminName,
          data: { ...data },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });
        return resolve({ status: constants.SERVER_STATUS_CODE.success });
      })
      .catch(() => reject({ status: constants.SERVER_STATUS_CODE.error }));
  });
}

export function editNotification(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const {
    selectNotificationId, lvTitle, enTitle, ruTitle, lvText, enText, ruText, notifyIdentifier, notificationDate, notificationType, showNotification,
  } = data;
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise(resolve => FirebaseRef.child(`notifications/${selectNotificationId}`).update({
    lvTitle,
    enTitle,
    ruTitle,
    lvText,
    enText,
    ruText,
    notifyIdentifier,
    notificationDate: Date.parse(notificationDate),
    notificationType,
    showNotification,
  }).then(() => {
    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'editNotification',
      adminId: UID,
      adminEmail,
      adminName,
      data: {
        ...data,
      },
      date: Firebase.database.ServerValue.TIMESTAMP,
    });
    return resolve({ status: constants.SERVER_STATUS_CODE.success });
  })).catch((err) => { console.log(err.message); });
}

export function deleteNotification(selectNotificationId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise(resolve => Firebase.auth().onAuthStateChanged(() => {
    FirebaseRef.child(`notifications/${selectNotificationId}`).remove().then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'deleteNotification',
        adminId: UID,
        adminEmail,
        adminName,
        selectNotificationId,
        data: null,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    });
  }));
}


const getMultiAccountPairsFunction = (dispatch) => {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return new Promise(resolve => FirebaseRef.child('multiAccounts').once('value', (snapshot) => {
    const data = snapshot.val() || {};

    resolve(dispatch({
      type: 'MULTI_ACCOUNTS',
      data,
    }));
  }).catch(() => {
    resolve();
  }));
};

export function getMultiAccountPairs() {
  return dispatch => getMultiAccountPairsFunction(dispatch);
}

export function addMultiAccountPair(uid1, uid2, isNewGroup) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!uid1 || !uid2) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  const promise1 = FirebaseRef.child(`users/${uid1}/name`).once('value');
  const promise2 = FirebaseRef.child(`users/${uid2}/name`).once('value');

  return dispatch => new Promise(resolve => Promise.all([promise1, promise2]).then(async (promisesRes) => {
    let name1;
    let name2;

    promisesRes.map((resp, index) => {
      if (index === 0) {
        name1 = resp.val() || null;
      } else if (index === 1) {
        name2 = resp.val() || null;
      }
      return null;
    });

    await FirebaseRef.child(`multiAccounts/${uid1}/${uid2}`).update({
      uid: uid2,
      name: name2,
      date: Firebase.database.ServerValue.TIMESTAMP,
    }).then(async () => {
      await FirebaseRef.child(`multiAccounts/${uid1}/${uid1}`).update({
        uid: uid1,
        name: name1,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: isNewGroup ? 'addMultiAccountPair' : 'addMultiAccount',
        adminId: UID,
        adminEmail,
        adminName,
        uid1: uid1 || null,
        uid2: uid2 || null,
        data: { uid1, uid2 },
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      await getMultiAccountPairsFunction(dispatch);

      return resolve();
    }).catch(() => {
      resolve();
    });
  }));
}


export function removeMultiAccountPair(uid1, uid2, length) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!uid1 || !uid2) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (uid1 !== uid2) {
    console.log('removing not root');
    return dispatch => new Promise(resolve => FirebaseRef.child(`multiAccounts/${uid1}/${uid2}`).remove().then(async () => {
      if (length <= 2) {
        await FirebaseRef.child(`multiAccounts/${uid1}/${uid1}`).remove();
      }

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: length === 2 ? 'removeMultiAccountPair' : 'removeMultiAccount',
        adminId: UID,
        adminEmail,
        adminName,
        uid1: uid1 || null,
        uid2: uid2 || null,
        data: { uid1, uid2 },
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      await getMultiAccountPairsFunction(dispatch);
      return resolve();
    }).catch(() => {
      resolve();
    }));
  }
  const promise1 = FirebaseRef.child(`multiAccounts/${uid1}`).once('value');

  return dispatch => new Promise(resolve => Promise.all([promise1]).then(async (promisesRes) => {
    let multiAccountsData;

    promisesRes.map((resp, index) => {
      if (index === 0) {
        multiAccountsData = resp.val() || null;
      }
      return null;
    });

    let newId2 = Object.keys(multiAccountsData)[0];

    if (newId2 === uid1) {
      newId2 = Object.keys(multiAccountsData)[1];
    }

    delete multiAccountsData[uid1];

    FirebaseRef.child(`multiAccounts/${uid1}`).remove().then(async () => {
      if (length > 2) {
        await FirebaseRef.child(`multiAccounts/${newId2}`).set(multiAccountsData);
      }

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: length === 2 ? 'removeMultiAccountPair' : 'removeMultiAccount',
        adminId: UID,
        adminEmail,
        adminName,
        uid1: uid1 || null,
        uid2: uid2 || null,
        data: { uid1, uid2: uid2 || null },
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      await getMultiAccountPairsFunction(dispatch);
      return resolve();
    });
  }).catch(() => {
    resolve();
  }));
}

export function getAllHints() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('hints')
    .on('value', (snapshot) => {
      const allHints = snapshot.val() || {};
      const array = [];
      Object.keys(allHints).map((key) => {
        array.push({ key, ...allHints[key] });
        return null;
      });
      return resolve(dispatch({ type: 'ADMIN_HINTS_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllHintsOld() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('hints')
    .orderByChild('hintType')
    .equalTo(constants.OLD_DESIGN)
    .on('value', (snapshot) => {
      const allHints = snapshot.val() || {};
      const array = [];
      Object.keys(allHints).map((key) => {
        array.push({ key, ...allHints[key] });
        return null;
      });
      return resolve(dispatch({ type: 'ADMIN_HINTS_OLD_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllHintsNew() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('hints')
    .orderByChild('hintType')
    .equalTo(constants.NEW_DESIGN)
    .on('value', (snapshot) => {
      const allHints = snapshot.val() || {};
      const array = [];
      Object.keys(allHints).map((key) => {
        array.push({ key, ...allHints[key] });
        return null;
      });
      return resolve(dispatch({ type: 'ADMIN_HINTS_NEW_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function addNewHint(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    showOptions, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, enImageUrl, lvImageUrl, ruImageUrl, devMode, hintType,
  } = data;

  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!enImageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  if (!lvImageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  if (!ruImageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  if (!hintType) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingData })); }

  return () => new Promise((resolve, reject) => {
    FirebaseRef.child('hints').push({
      showOptions,
      titleLV: lvTitle,
      titleEN: enTitle,
      titleRU: ruTitle,
      descriptionLV: lvDescription,
      descriptionEN: enDescription,
      descriptionRU: ruDescription,
      enImageUrl,
      lvImageUrl,
      ruImageUrl,
      devMode,
      hintType,
    })
      .then((res) => {
        console.log('addHint=', res);
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'createNewHint',
          adminId: UID,
          adminEmail,
          adminName,
          data: {
            showOptions,
            titleEN: enTitle,
            titleLV: lvTitle,
            titleRU: ruTitle,
            descriptionEN: enDescription,
            descriptionLV: lvDescription,
            descriptionRU: ruDescription,
            enImageUrl,
            lvImageUrl,
            ruImageUrl,
            devMode,
            hintType,
          },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });
        return resolve({ status: constants.SERVER_STATUS_CODE.success });
      })
      .catch((err) => { console.log(err); return reject({ status: constants.SERVER_STATUS_CODE.error }); });
  });
}

export function editSelectHint(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    selectHintId, showOptions, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, enImageUrl, lvImageUrl, ruImageUrl, oldData, devMode, hintType,
  } = data;

  if (!selectHintId) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingData })); }
  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!enImageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  if (!lvImageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  if (!ruImageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  if (!hintType) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingData })); }

  return () => new Promise((resolve) => {
    FirebaseRef.child(`hints/${selectHintId}`).update({
      showOptions,
      titleLV: lvTitle,
      titleEN: enTitle,
      titleRU: ruTitle,
      descriptionLV: lvDescription,
      descriptionEN: enDescription,
      descriptionRU: ruDescription,
      enImageUrl,
      lvImageUrl,
      ruImageUrl,
      devMode,
      hintType,
    }).then(() => {
      if (showOptions !== oldData.showOptions || enTitle !== oldData.titleEN || enDescription !== oldData.descriptionEN || devMode !== oldData.devMode || hintType !== oldData.hintType) {
        const dataToEdit = {
          showOptions,
          titleEN: enTitle,
          titleLV: lvTitle,
          titleRU: ruTitle,
          descriptionEN: enDescription,
          descriptionLV: lvDescription,
          descriptionRU: ruDescription,
          enImageUrl,
          ruImageUrl,
          lvImageUrl,
          devMode,
          hintType,
        };
        const changesObj = {};
        Object.keys(dataToEdit).map((key) => {
          if (oldData[key] !== dataToEdit[key]) {
            changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
          }
          return null;
        });

        let changedlog = '';
        Object.keys(changesObj).map((key, index) => {
          changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

          if ((index + 1) !== Object.keys(changesObj).length) {
            changedlog += ', ';
          }
          return null;
        });

        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'editSelectHint',
          adminId: UID,
          adminEmail,
          adminName,
          eventId: selectHintId,
          data: {
            ...dataToEdit,
            changedlog,
          },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });
      }

      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch(err => resolve({ status: 'error', message: err.message }));
  });
}

export function deleteHint(hindId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      FirebaseRef.child(`hints/${hindId}`).remove().then(() => {
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'deleteHint',
          adminId: UID,
          adminEmail,
          adminName,
          hindId,
          data: null,
          date: Firebase.database.ServerValue.TIMESTAMP,
        });
        return resolve({ status: constants.SERVER_STATUS_CODE.success });
      })
        .catch(err => resolve({ status: 'error', message: err.message }));
    }
  }));
}

export function getAllReportedPlayers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  // const filterDate = Date.parse(new Date());
  return dispatch => new Promise(resolve => FirebaseRef.child('reportPlayers')
    .on('value', (snapshot) => {
      const allReportedPlayers = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_REPORTED_PLAYERS_REPLACE', data: allReportedPlayers }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllReportedMessages() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  // const filterDate = Date.parse(new Date());
  return dispatch => new Promise(resolve => FirebaseRef.child('reportMessages')
    .on('value', (snapshot) => {
      const allReportedMessages = snapshot.val() || {};
      console.log('allReportedMessages', allReportedMessages);

      return resolve(dispatch({ type: 'ADMIN_REPORTED_MESSAGES_REPLACE', data: allReportedMessages }));
    })).catch((err) => { console.log(err.message); });
}

export function checkAdminReportPlayers(key, reporterId, reporterName, reportedId, reportedName, reason) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  console.log(key, 'checkAdminView');
  return () => new Promise(() => {
    FirebaseRef.child(`reportPlayers/${key.toString()}`)
      .update({
        adminView: true,
      });
    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'reportPlayers',
      adminId: UID,
      adminEmail,
      userId: key,
      adminName,
      userName: reporterName,
      data: {
        reportedId,
        reportedName,
        reason,
        adminView: 'Done',
      },
      date: Firebase.database.ServerValue.TIMESTAMP,
    });
  });
}

export function checkAdminReportMessages(key, reporterId, reporterName, reportedId, reportedName, message) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  console.log(key, reporterId, reporterName, reportedId, reportedName, message, 'checkAdminView');
  return () => new Promise(() => {
    FirebaseRef.child(`reportMessages/${key.toString()}`)
      .update({
        adminView: true,
      });
    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'reportMessages',
      adminId: UID,
      adminEmail,
      adminName,
      date: Firebase.database.ServerValue.TIMESTAMP,
      userId: key,
      userName: reporterName,
      data: {
        reportedId,
        reportedName,
        message,
        adminView: 'Done',
      },
    });
  });
}

export function getConfirmedPlayersCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('users')
    .orderByChild('verifiedEmail')
    .equalTo(true)
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};
      console.log(size(data), 'admin verified users action');

      const filteredUsers = data ? filter(data, item => item.verifiedEmailSent) : {};

      return resolve(dispatch({ type: 'ADMIN_VERIFIED_COUNT_REPLACE', data: size(filteredUsers) }));
    })).catch((err) => { console.log(err.message); });
}

export function checkAutoForCompensation(uid, name) {
  console.log('checkAutoForCompensation', uid, name);
  if (!uid) return () => new Promise(resolve => resolve());

  if (Firebase === null) return () => new Promise(resolve => resolve());

  /*
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  const test = Date.now();

   let d = new Date(test);

   d.setHours(24, 0, 0, 0);
   d = Date.parse(d);

  const startAt = d - (1000 * 60 * 60 * 72);
  const endAt = d;
  const totalPenalty = 0;
  const percentPenalty = 0;
  const adminChangeAuto = false;
  const previousChangeAuto = {};
  console.log('adminPenaltyBonus3'); */

  return () => new Promise(() => {
    const delay = [1, 2, 3, 4, 5];
    const random = Math.floor(Math.random() * delay.length);

    FirebaseRef.child(`checkAutoForCompensation/${uid}`).set({ time: Firebase.database.ServerValue.TIMESTAMP, delay: delay[random] });
  });
}

// export function getRoomChats(filterType, filterVal) {
//   if (Firebase === null) return () => new Promise(resolve => resolve());

//   const d = Date.now();
//   /* let startAt;
//   let endAt;
//   let roomData;
//   const roomMsg = [];
//   let filterRoomData; */

//   const startAt = d - (1000 * 60 * 60 * 24 * constants.WEEKLY_VALUE.twoWeek);
//   const endAt = d;

//   return dispatch => new Promise(async (resolve) => {
//     dispatch({ type: 'PLAYER_ROOM_CHAT_MESSAGE', data: [], chatLoading: true });

//     FirebaseRef.child('playerChat')
//       .orderByChild('time')
//       .startAt(startAt)
//       .endAt(endAt)
//       .once('value', (snapshot) => {
//         const data = snapshot.val();
//         let roomChats = [];
//         if (filterType === constants.ROOMID && filterVal !== '') {
//           roomChats = filter(data, item => item.roomId === filterVal);
//         } else if (filterType === constants.USERID && filterVal !== '') {
//           roomChats = filter(data, item => item.userUid === filterVal);
//         } else {
//           Object.keys(data).map((key) => {
//             roomChats.push({ key, ...data[key] });
//             return null;
//           });
//         }
//         if (size(roomChats) > 0) {
//           roomChats.sort((a, b) => (b.time - a.time));
//         }
//         return resolve(dispatch({ type: 'PLAYER_ROOM_CHAT_MESSAGE', data: roomChats, chatLoading: false }));
//       });
//   });
// }

export function getRoomChats(filterType, filterVal) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const d = Date.now();
  const startAt = d - (1000 * 60 * 60 * 24 * constants.WEEKLY_VALUE.twoWeek);
  const endAt = d;

  return dispatch => new Promise(async (resolve) => {
    dispatch({ type: 'PLAYER_ROOM_CHAT_MESSAGE', data: [], chatLoading: true });

    FirebaseRef.child('playerChat')
      .orderByChild('time')
      .startAt(startAt)
      .endAt(endAt)
      .once('value', async (snapshot) => {
        const data = snapshot.val();
        let roomChats = [];

        if (filterType === constants.ROOMID && filterVal !== '') {
          roomChats = filter(data, item => item.roomId === filterVal);
        } else if (filterType === constants.USERID && filterVal !== '') {
          roomChats = filter(data, item => item.userUid === filterVal);
        } else {
          Object.keys(data).map((key) => {
            roomChats.push({ key, ...data[key] });
            return null;
          });
        }

        // get blocked status for each userUid in roomChats
        const updatedRoomChats = await Promise.all(roomChats.map(async (chat) => {
          const banSnapshot = await FirebaseRef.child(`bans/${chat.userUid}`).once('value');
          const banData = banSnapshot.val();
          return {
            ...chat,
            blocked: banData ? banData.blocked : false
          };
        }));

        if (size(updatedRoomChats) > 0) {
          updatedRoomChats.sort((a, b) => (b.time - a.time));
        }

        return resolve(dispatch({ type: 'PLAYER_ROOM_CHAT_MESSAGE', data: updatedRoomChats, chatLoading: false }));
      });
  });
}

export function getLogRocketUsers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('users')
    .orderByChild('enableLogRocket')
    .equalTo(true)
    .once('value', (userSnapshot) => {
      const enableLogRocketUsers = userSnapshot.val() || {};

      return resolve(dispatch({ type: 'ENABLED_LOG_LOCKET_USERS', data: enableLogRocketUsers }));
    }));
}

export function addWarningMark(playerUid, curWarningMark) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  console.log(playerUid, curWarningMark || 0, 'checkAdminView');
  const updateWarningMark = curWarningMark ? curWarningMark + 1 : 1;
  const cursorExpireDate = Date.parse(moment(new Date()).add(30, 'days'));

  return () => new Promise((resolve) => {
    if (curWarningMark === 0 || !curWarningMark) {
      FirebaseRef.child(`warningPlayers/${playerUid.toString()}`)
        .update({
          warning: updateWarningMark,
          warningDate: Date.now(),
          adminActivated: true,
        });
    } else {
      FirebaseRef.child(`warningPlayers/${playerUid.toString()}`)
        .update({
          warning: updateWarningMark,
          warningDate: Date.now(),
          adminActivated: true,
          cursor: (updateWarningMark > 0 && updateWarningMark % 10 === 0) || null,
          cursorExpire: (updateWarningMark > 0 && updateWarningMark % 10 === 0) ? cursorExpireDate : null,
        });
    }

    FirebaseRef.child(`users/${playerUid.toString()}`)
      .update({
        warning: updateWarningMark,
        warningDate: Date.now(),
        cursor: (updateWarningMark > 0 && updateWarningMark % 10 === 0) || null,
        cursorExpire: (updateWarningMark > 0 && updateWarningMark % 10 === 0) ? cursorExpireDate : null,
      });
    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'warningMark',
      adminId: UID,
      adminEmail,
      adminName,
      date: Firebase.database.ServerValue.TIMESTAMP,
      userId: playerUid,
      data: {
        warning: updateWarningMark,
      },
    });
    return resolve({ status: constants.SERVER_STATUS_CODE.success });

  });
}

export function getWarningPlayers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());

  return dispatch => new Promise(async resolve => FirebaseRef.child('warningPlayers')
    .orderByKey()
    .on('value', (userSnapshot) => {
      const warningPlayers = userSnapshot.val() || {};
      const warningPlayersList = {};

      map(warningPlayers, (item, key) => {
        if (item?.cursor && Date.now() > item?.cursorExpire) {
          FirebaseRef.child(`users/${key.toString()}`).update({
            cursorExpire: null,
            cursor: null,
          });
          FirebaseRef.child(`warningPlayers/${key.toString()}`).update({
            cursorExpire: null,
            cursor: null,
          });
        } else {
          warningPlayersList[key] = {
            ...item,
          };
        }
      })

      return resolve(dispatch({ type: 'REPLACE_WARNING_PLAYERS', data: warningPlayersList }));
    }));
}

export function getDesignPlatforms() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    dispatch({ type: 'REPLACE_DESIGN_PLATFORMS', data: [], designPlatformsLoading: true });
    FirebaseRef.child('designPlatforms')
      .orderByKey()
      .once('value', (userSnapshot) => {
        const designPlatforms = userSnapshot.val() || {};
        return resolve(dispatch({ type: 'REPLACE_DESIGN_PLATFORMS', data: designPlatforms, designPlatformsLoading: false }));
      }).catch(() => {
        return resolve(dispatch({ type: 'REPLACE_DESIGN_PLATFORMS', data: {}, designPlatformsLoading: false }));
      });
  });
}

export function updateDesignPlatform(id, design, value) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  
  return dispatch => new Promise((resolve) => {
    dispatch({ type: 'REPLACE_DESIGN_PLATFORMS', data: [], designPlatformsLoading: true });
    FirebaseRef.child(`designPlatforms/${id}`).update({
      [design]: value,
      setFrequency: design === 'frequency' && value > 0 ? Date.now() : null,
    }).then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'designPlatform',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        data: {
          [design]: value,
        },
      });

      FirebaseRef.child('designPlatforms')
        .orderByKey()
        .once('value', (userSnapshot) => {
          const designPlatforms = userSnapshot.val() || {};

          dispatch({ type: 'GET_DESIGN_PLATFORM', data: designPlatforms });
          return resolve(dispatch({ type: 'REPLACE_DESIGN_PLATFORMS', data: designPlatforms, designPlatformsLoading: false }));
        }).catch(() => {
          return resolve(dispatch({ type: 'REPLACE_DESIGN_PLATFORMS', data: {}, designPlatformsLoading: false }));
        });
    });
  });
};

export function getSurveys() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('surveys')
    .on('value', (snapshot) => {
      const allSurveys = snapshot.val() || {};


      return resolve(dispatch({ type: 'REPLACE_SURVEY_PLATFORMS', data: allSurveys }));
    })).catch((err) => { console.log(err.message); });
  }

export function getSurveyHistory() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    dispatch({ type: 'REPLACE_SURVEY_PLATFORMS', data: [], loading: true });
    FirebaseRef.child('surveyHistory')
      .on('value', (userSnapshot) => {
        const surveyHistory = userSnapshot.val() || {};

        return resolve(dispatch({ type: 'REPLACE_SURVEY_HISTORY_PLATFORMS', data: surveyHistory, loading: false }));
      })
    });
}

export function createSurveyFunc({startDate, endDate, question, description, questionType, devMode, answerList, selectedDesigns, disableAnswer}) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  
  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('surveys').push({
      startDate: Date.parse(startDate),
      endDate: Date.parse(endDate),
      question,
      description,
      questionType,
      devMode: devMode || null,
      answerList,
      selectedDesigns,
      disableAnswer
    }).then((res) => {

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'createSurvey',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        data: {
          startDate: moment(startDate).format('DD-MM-YYYY HH:MM:SS'),
          endDate: moment(endDate).format('DD-MM-YYYY HH:MM:SS'),
          question,
          description,
          questionType,
          devMode,
          answerList,
          selectedDesigns,
          disableAnswer
        },
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}

export function deleteFeedback(selectedId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`feedback/${selectedId}`).remove().then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'deleteFeedback',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        selectedId,
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}

export function createFeedback({startDate, endDate, platforms, designs, devMode}) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  
  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('feedback').startAt(Date.parse(startDate)).endAt(Date.parse(endDate)).once('value', (snapshot) => {
      const results = snapshot.val();
      console.log(results, "resultcheck===============")

      FirebaseRef.child('feedback').push({
        startDate: Date.parse(startDate),
        endDate: Date.parse(endDate),
        platforms,
        designs,
        devMode,
      }).then((res) => {
        console.log(res, "check===============111111111111")
  
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'createFeedback',
          adminId: UID,
          adminEmail,
          adminName,
          date: Firebase.database.ServerValue.TIMESTAMP,
          data: {
            startDate: moment(startDate).format('DD-MM-YYYY HH:MM:SS'),
            endDate: moment(endDate).format('DD-MM-YYYY HH:MM:SS'),
            platforms,
            designs,
            devMode,
          },
        });
        return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });
      }).catch(() => {
        return resolve({ status: constants.SERVER_STATUS_CODE.error });
      });
    })
  });
}

export function editFeedback({selectedId, startDate, endDate, platforms, designs, devMode,}) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`feedback/${selectedId}`).update({
      startDate: Date.parse(startDate),
      endDate: Date.parse(endDate),
      platforms,
      designs,
      devMode,
    }).then((res) => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'editFeedback',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        data: {
          startDate: moment(startDate).format('DD-MM-YYYY HH:MM:SS'),
          endDate: moment(endDate).format('DD-MM-YYYY HH:MM:SS'),
          platforms,
          designs,
          devMode,
        },
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}

export function deleteSurveyFunc(selectedId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`surveys/${selectedId}`).remove().then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'deleteSurvey',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        selectedId,
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}

export function editSurveyFunc({selectedId, startDate, endDate, question, description, questionType, devMode, answerList, selectedDesigns, disableAnswer}) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`surveys/${selectedId}`).update({
      startDate: Date.parse(startDate),
      endDate: Date.parse(endDate),
      question,
      description,
      questionType,
      devMode: devMode || null,
      answerList,
      selectedDesigns,
      disableAnswer
    }).then((res) => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'editSurvey',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        data: {
          startDate: moment(startDate).format('DD-MM-YYYY HH:MM:SS'),
          endDate: moment(endDate).format('DD-MM-YYYY HH:MM:SS'),
          question,
          description,
          questionType,
          devMode,
          answerList,
          selectedDesigns,
          disableAnswer
        },
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}




export function getTranslationsFiles(devMode) {
  console.log('getTranslationsFiles', devMode);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    // dispatch({ type: 'REPLACE_SURVEY_PLATFORMS', data: [], loading: true });

    const promises = [];

    let lvReference = FirebaseStorage.ref(`/translations`).child(`lv.json`);
    let enReference = FirebaseStorage.ref(`/translations`).child(`en.json`);
    let ruReference = FirebaseStorage.ref(`/translations`).child(`ru.json`);

    if (devMode) {
      console.log('get dev translations');

      lvReference = FirebaseStorage.ref(`/translationsDev`).child(`lv.json`);
      enReference = FirebaseStorage.ref(`/translationsDev`).child(`en.json`);
      ruReference = FirebaseStorage.ref(`/translationsDev`).child(`ru.json`);
    }

    const languageMap = {
      'english': null,
      'latvian': null,
      'russian': null,
    };

    promises.push(new Promise((resolve2) => {
      lvReference.getDownloadURL().then((url) => {
        axios.get(url).then((response) => {
          let translationsDb = response.data;

          console.log('translations file lv', translationsDb);

          if (translationsDb.common) { i18n.addResourceBundle('lv', 'common', translationsDb.common); }
          if (translationsDb.holiday) { i18n.addResourceBundle('lv', 'holiday', translationsDb.holiday); }
          if (translationsDb.previousRound) { i18n.addResourceBundle('lv', 'previousRound', translationsDb.previousRound); }
          if (translationsDb.achievements) { i18n.addResourceBundle('lv', 'achievements', translationsDb.achievements); }
          if (translationsDb.game) { i18n.addResourceBundle('lv', 'game', translationsDb.game); }
          if (translationsDb.playerInfo) { i18n.addResourceBundle('lv', 'playerInfo', translationsDb.playerInfo); }
          if (translationsDb.chat) { i18n.addResourceBundle('lv', 'chat', translationsDb.chat); }
          if (translationsDb.notifications) { i18n.addResourceBundle('lv', 'notifications', translationsDb.notifications); }
          if (translationsDb.login) { i18n.addResourceBundle('lv', 'login', translationsDb.login); }
          if (translationsDb.help) { i18n.addResourceBundle('lv', 'help', translationsDb.help); }
          if (translationsDb.settings) { i18n.addResourceBundle('lv', 'settings', translationsDb.settings); }
          if (translationsDb.updateProfile) { i18n.addResourceBundle('lv', 'updateProfile', translationsDb.updateProfile); }
          if (translationsDb.nativeNotifications) { i18n.addResourceBundle('lv', 'nativeNotifications', translationsDb.nativeNotifications); }
          if (translationsDb.bonus) { i18n.addResourceBundle('lv', 'bonus', translationsDb.bonus); }
          if (translationsDb.admin) { i18n.addResourceBundle('lv', 'admin', translationsDb.admin); }
          if (translationsDb.rulesPage) { i18n.addResourceBundle('lv', 'rulesPage', translationsDb.rulesPage); }
          if (translationsDb.rules) { i18n.addResourceBundle('lv', 'rules', translationsDb.rules); }
          if (translationsDb.tournamentHelp) { i18n.addResourceBundle('lv', 'tournamentHelp', translationsDb.tournamentHelp); }

          languageMap.latvian = translationsDb;

          return resolve2(dispatch({ type: 'REPLACE_TRASLATIONS_FILES', data: translationsDb, language: 'lv' }));
        });
      });
    }));

    promises.push(new Promise((resolve2) => {
      enReference.getDownloadURL().then((url) => {
        axios.get(url).then((response) => {
          let translationsDb = response.data;

          console.log('translations file en', translationsDb);

          if (translationsDb.common) { i18n.addResourceBundle('en', 'common', translationsDb.common); }
          if (translationsDb.holiday) { i18n.addResourceBundle('en', 'holiday', translationsDb.holiday); }
          if (translationsDb.previousRound) { i18n.addResourceBundle('en', 'previousRound', translationsDb.previousRound); }
          if (translationsDb.achievements) { i18n.addResourceBundle('en', 'achievements', translationsDb.achievements); }
          if (translationsDb.game) { i18n.addResourceBundle('en', 'game', translationsDb.game); }
          if (translationsDb.playerInfo) { i18n.addResourceBundle('en', 'playerInfo', translationsDb.playerInfo); }
          if (translationsDb.chat) { i18n.addResourceBundle('en', 'chat', translationsDb.chat); }
          if (translationsDb.notifications) { i18n.addResourceBundle('en', 'notifications', translationsDb.notifications); }
          if (translationsDb.login) { i18n.addResourceBundle('en', 'login', translationsDb.login); }
          if (translationsDb.help) { i18n.addResourceBundle('en', 'help', translationsDb.help); }
          if (translationsDb.settings) { i18n.addResourceBundle('en', 'settings', translationsDb.settings); }
          if (translationsDb.updateProfile) { i18n.addResourceBundle('en', 'updateProfile', translationsDb.updateProfile); }
          if (translationsDb.nativeNotifications) { i18n.addResourceBundle('en', 'nativeNotifications', translationsDb.nativeNotifications); }
          if (translationsDb.bonus) { i18n.addResourceBundle('en', 'bonus', translationsDb.bonus); }
          if (translationsDb.admin) { i18n.addResourceBundle('en', 'admin', translationsDb.admin); }
          if (translationsDb.rulesPage) { i18n.addResourceBundle('en', 'rulesPage', translationsDb.rulesPage); }
          if (translationsDb.rules) { i18n.addResourceBundle('en', 'rules', translationsDb.rules); }
          if (translationsDb.tournamentHelp) { i18n.addResourceBundle('en', 'tournamentHelp', translationsDb.tournamentHelp); }

          languageMap.english = translationsDb;

          return resolve2(dispatch({ type: 'REPLACE_TRASLATIONS_FILES', data: translationsDb, language: 'en' }));
        });
      });
    }));

    promises.push(new Promise((resolve2) => {
      ruReference.getDownloadURL().then((url) => {
        axios.get(url).then((response) => {
          let translationsDb = response.data;

          console.log('translations file ru', translationsDb);

          if (translationsDb.common) { i18n.addResourceBundle('ru', 'common', translationsDb.common); }
          if (translationsDb.holiday) { i18n.addResourceBundle('ru', 'holiday', translationsDb.holiday); }
          if (translationsDb.previousRound) { i18n.addResourceBundle('ru', 'previousRound', translationsDb.previousRound); }
          if (translationsDb.achievements) { i18n.addResourceBundle('ru', 'achievements', translationsDb.achievements); }
          if (translationsDb.game) { i18n.addResourceBundle('ru', 'game', translationsDb.game); }
          if (translationsDb.playerInfo) { i18n.addResourceBundle('ru', 'playerInfo', translationsDb.playerInfo); }
          if (translationsDb.chat) { i18n.addResourceBundle('ru', 'chat', translationsDb.chat); }
          if (translationsDb.notifications) { i18n.addResourceBundle('ru', 'notifications', translationsDb.notifications); }
          if (translationsDb.login) { i18n.addResourceBundle('ru', 'login', translationsDb.login); }
          if (translationsDb.help) { i18n.addResourceBundle('ru', 'help', translationsDb.help); }
          if (translationsDb.settings) { i18n.addResourceBundle('ru', 'settings', translationsDb.settings); }
          if (translationsDb.updateProfile) { i18n.addResourceBundle('ru', 'updateProfile', translationsDb.updateProfile); }
          if (translationsDb.nativeNotifications) { i18n.addResourceBundle('ru', 'nativeNotifications', translationsDb.nativeNotifications); }
          if (translationsDb.bonus) { i18n.addResourceBundle('ru', 'bonus', translationsDb.bonus); }
          if (translationsDb.admin) { i18n.addResourceBundle('ru', 'admin', translationsDb.admin); }
          if (translationsDb.rulesPage) { i18n.addResourceBundle('ru', 'rulesPage', translationsDb.rulesPage); }
          if (translationsDb.rules) { i18n.addResourceBundle('ru', 'rules', translationsDb.rules); }
          if (translationsDb.tournamentHelp) { i18n.addResourceBundle('ru', 'tournamentHelp', translationsDb.tournamentHelp); }

          languageMap.russian = translationsDb;

          return resolve2(dispatch({ type: 'REPLACE_TRASLATIONS_FILES', data: translationsDb, language: 'ru' }));
        });
      });
    }));

    Promise.all(promises).then(() => {
      console.log('languageMap', languageMap);
      if (languageMap && languageMap.english && languageMap.latvian && languageMap.russian) {
        return resolve(languageMap);
      }

      return resolve(null);
    }).catch(err => {
      console.log(err);
      return resolve(null);
    })

  });
}

export function updateTranslationsFile(translationsJson, language, changes, devMode) {
  console.log("updateTranslationsFile", translationsJson, language, changes, devMode);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());

  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  let translationReference = FirebaseStorage.ref(`/translations`).child(`${language}.json`);

  if (devMode) {
    translationReference = FirebaseStorage.ref(`/translationsDev`).child(`${language}.json`);
  }

  return dispatch => new Promise((resolve) => {
    const jsonString = JSON.stringify(translationsJson);
    const blob = new Blob([jsonString], { type: "application/json" });

    translationReference.put(blob).then(() => {
      console.log('Uploaded a blob!');

      // dispatch(getTranslationsFiles());

      console.log('test 7');
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'updateTranslations',
        adminId: UID,
        adminEmail,
        adminName,
        devMode,
        date: Firebase.database.ServerValue.TIMESTAMP,
        data: {...changes},
      });

      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch((err) => {
      console.log('test 8');
      console.log('err', err);
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}

export function getPossibleBotPlayers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const filterDate = Date.parse(new Date());

  return dispatch => new Promise(resolve => {
    dispatch({ type: 'ADMIN_POSSIBLE_BOT_PLAYERS_REPLACE', data: {}, possibleBotLoading: true });

    FirebaseRef.child('possibleBotUsers/latest')
    .orderByChild('date')
    .on('value', (snapshot) => {
      const possibleBotPlayers = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_POSSIBLE_BOT_PLAYERS_REPLACE', data: possibleBotPlayers, possibleBotLoading: false  }));
    });
  }).catch((err) => { console.log(err.message); });
}

export function getPossibleBotPlayerData(playerId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const filterDate = Date.parse(new Date());

  return dispatch => new Promise(resolve => FirebaseRef.child(`possibleBotUsers/history/${playerId}`)
    .on('value', (snapshot) => {
      const possibleBotPlayerData = snapshot.val() || {};
      
      return resolve(dispatch({ type: 'ADMIN_POSSIBLE_BOT_PLAYER_DATA_REPLACE', data: possibleBotPlayerData }));
    })).catch((err) => { console.log(err.message); });
}

export function getRoomOptions(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => AdminLogsRef().child(`adminLogs/roomIds/${roomId}`)
    .once('value', async (snapshot) => {
      const roomOptions = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_ROOM_OPTIONS_REPLACE', data: roomOptions }));
    }, (err) => { console.log(err); })).catch((err) => { console.log(err); });
}

export function updateHelpEditor(helpSection, lang) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  const convertString = JSON.stringify(helpSection);

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`helpEditor/${lang}`).update({
      helpSection: convertString,
    }).then((res) => {

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'updateHelpSection',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });
      
      return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}

export function updateHelpEditorDevMode(devMode) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`helpEditor`).update({
      devMode,
    }).then((res) => {

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'updateHelpSection',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });
      
      return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}

export function getHelpEditor() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    dispatch({ type: 'ADMIN_HELP_EDITOR_REPLACE', data: [], helpEditorLoading: true });
    FirebaseRef.child('helpEditor').on('value', (snapshot) => {
      const helpSections = snapshot.val();

      console.log(helpSections,  "parsedData=============")

      return resolve(dispatch({ type: 'ADMIN_HELP_EDITOR_REPLACE', data: helpSections, helpEditorLoading: false }));

    });
  });
}
