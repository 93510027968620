import React from "react";

import Label from 'reactstrap/lib/Label';
import FormGroup from 'reactstrap/lib/FormGroup';
import Media from 'reactstrap/lib/Media';
import Input from 'reactstrap/lib/Input';

import pencilImg from '../../../../images/icons/pencil.svg';
import checkImg from '../../../../images/icons/check.png';
import cancelImg from '../../../../images/icons/close_old.png';
import closeImg from '../../../../images/icons/close.png';
import { TOURNAMENT_OPTION_IMAGES } from "../../../../constants/constants";

const FileUploadImage = React.memo(({
    preview,
    imageUrl,
    showCrop,
    hiddenFileInput,
    handleImageClick,
    handleImageChange,
    handleEditPhoto,
    uploadEventFile,
    imageType,
    loading,
    handleReSetImage,
    handleReSetPreviewImage,
    disabled,
}) => {
    const itemStyle = disabled ? {opacity: 0.5, pointerEvents: 'none'} : {};

    return (
        <FormGroup className={`${imageType}-section`}>
            <Label style={itemStyle}>
                {/* {t('bannerImage')} */}
                {`${imageType.toUpperCase()} Image`}
            </Label>
            <div className="banner-image">
                {
                !preview && !imageUrl ? (
                    <Label style={itemStyle} className="banner-image-label">Please upload image.</Label>
                    // <Label className="banner-image-label">{t('uploadImage')}</Label>
                ) : (
                    <img className="event-banner-image" src={preview || imageUrl} alt="Preview" />
                )
                }
            </div>
            {!showCrop && (
                <div className="event-banner-edit" style={itemStyle}>
                <Media onClick={() => { handleEditPhoto(showCrop); }} src={pencilImg} className="menu-player-edit-image" disabled={loading} />
                </div>
            )}
            {!showCrop && (
                <div className="event-banner-cancel" style={itemStyle}>
                <Media onClick={() => { handleReSetImage(imageType); }} src={cancelImg} className="menu-player-edit-image" disabled={loading} />
                </div>
            )}
            {showCrop && (
                <div className="event-banner-edit" style={itemStyle}>
                <Media onClick={() => { handleEditPhoto(showCrop); uploadEventFile(imageType); }} src={checkImg} className="menu-player-edit-image" />
                </div>
            )}
            {showCrop && (
                <div className="event-banner-cancel" style={itemStyle}>
                <Media onClick={() => { handleEditPhoto(showCrop); handleReSetPreviewImage(imageType); }} src={cancelImg} className="menu-player-edit-image" />
                </div>
            )}
            {
                showCrop && (
                <div>
                    <div className="event-banner-button" onClick={handleImageClick}>
                    {/* {t('uploadImageButton')} */}
                    Upload a Image
                    </div>
                    <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={(e) => handleImageChange(e, imageType)}
                    style={{ display: 'none' }}
                    />
                </div>
                )
            }
            <Input
                type="text"
                name="imageUrl"
                id="imageUrl"
                placeholder=""
                hidden
                readOnly
                value={imageUrl}
            />
        </FormGroup>
    );
});

FileUploadImage.defaultProps = {
    disabled: false,
};

export default FileUploadImage;